import { createContext, useState, memo, useEffect } from "react";
import PropTypes from 'prop-types';
import axios from 'axios';

const GamesContext = createContext();

// eslint-disable-next-line react/display-name
const GamesProvider=memo(({children}) => {
    const [witchRLoading, setWitchRLoading] = useState(true)
    const [cansLoading, setCansLoading] = useState(true)
    const [playersforWitch, setPlayersforWitch] = useState([])
    const [playersforCans, setPlayersforCans] = useState([])
    const [userforCans, setUserforCans] = useState({})
    const [userforWitch, setUserforWitch] = useState({})
    const user_name = ((JSON.parse(localStorage.getItem('userData')))?.user.name) || "NA"
    
    const getUserforWitch = async () => {
        try {
            const response = await axios.get(`https://sf-games-apis.onrender.com/player?user_name=${user_name}`)
            if (response.data.code === 0) {
                setUserforWitch(response.data.player[0])
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getUserforCans = async () => {
        try {
            const response = await axios.get(`https://sf-games-apis.onrender.com/cans/player?user_name=${user_name}`)
            if (response.data.code === 0) {
                setUserforCans(response.data.player[0])
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getPlayersforWitch = async () => {
        try {
            const response = await axios.get('https://sf-games-apis.onrender.com/players')
            if (response.data.code === 0) {
                setWitchRLoading(false)
                setPlayersforWitch(response.data.players)
            }
            else {
                setWitchRLoading(false)
                console.error(response.data.message)
            }
        }
        catch (error) {
            console.error(error)
        }
    }

    const getPlayersforCans = async () => {
        try {
            const response = await axios.get('https://sf-games-apis.onrender.com/cans/players')
            if (response.data.code === 0) {
                setCansLoading(false)
                setPlayersforCans(response.data.players)
            }
            else {
                setCansLoading(false)
                console.error(response.data.message)
            }
        }
        catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getUserforWitch()
        getUserforCans()
        getPlayersforWitch()
        getPlayersforCans()
    }, [])

    return(
        <GamesContext.Provider value={{witchRLoading, setWitchRLoading, cansLoading, setCansLoading, playersforCans, playersforWitch, userforCans, userforWitch, }}>
            {children}
        </GamesContext.Provider>
    );
});
GamesProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default GamesProvider;
export {GamesContext};