import axios from "axios";
import { ProfileContext } from "../usecontext/profile_provider";
import { useContext, useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import ReactModal from "react-modal";
import { Formik,Form,Field,ErrorMessage } from "formik";
import * as Yup from 'yup';
import crown from './crown.png'
import ReactGA from 'react-ga';
import Barcode from 'react-barcode';

const validationSchema = Yup.object().shape({
    check_in: Yup.string()
      .required('Check-in date is required'),
    check_out: Yup.string()
      .required('Check-out date is required')
      .test('is-after-check-in', 'Check-out date must be after check-in date', function(value) {
        const { check_in } = this.parent;
        return check_in && value >= check_in;
      }),
    emergency_number: Yup.string()
      .required('Emergency number is required')
      .matches(/^\d+$/, 'Emergency number must be only digits')
      .min(10, 'Emergency number must be of 10 digits')
      .max(10, 'Emergency number can be of 10 digits'),
  });

const MemberContingent=({setContingentMember,contingentdetails,setLeadercontingent,getMembers,leaderID})=>{
    const {userData} =useContext(ProfileContext);
    const[paymentmodal,setPaymentmodal]=useState(false);
    const[loading,setLoading]=useState(false);
    const [barcode,setBarcode]= useState(false);

    
    ReactGA.initialize("G-23Q6ZTSW0Q");
    ReactGA.send({ hitType: "pageview", page: "/contingent", title: "Enter the contingent Subheads" });

    // //function to leave contingent
    const handleLeaveContingent=async()=>{
        setLoading(true);
        try{
            const response = await axios.post("https://masterapi.springfest.in/api/contingent/leave",{token:userData.token})
            if(response.data.code===0){

                toast.success(response.data.message);
                setContingentMember(false);
                setLeadercontingent(false);
            }
            else{
                toast.error(response.data.message);
            }
        }catch(error){
            toast.error("network Issue");
            
        }finally{
            setLoading(false);
        }
    }
     const member = contingentdetails?.updatedMembersInfo?.find((user)=>user.sfId===userData.user.sfId)

    //Payment function
    const handlePayment =async(values)=>{
        const payload = {
            token:userData.token,
            check_in:values.check_in,
            check_out:values.check_out,
            emergency_number:values.emergency_number,
            type_of_acco: "Common",
            type_of_payment: "Individual"
        }
        setLoading(true);
        try{
            const url = `https://springfest.in/?token=${userData.token}&phoneNumber=${values.emergency_number}&type=Individual&origin=1`;
            window.open(url, '_blank');
            // const response = await axios.post('https://masterapi.springfest.in/api/payment/initiate',payload)
            // if(response.data.code===0){
            //     getMembers()
            //     setPaymentmodal(false);
            //     window.open( response.data.data.redirectUrl,'_blank');
                
            // }
            // else{
            //     toast.error(response.data.message);
            // }
        }catch(error){
            console.log(error); 
            
        }finally{
            setLoading(false);
        }
    }
    return(
        <div>
            <ReactModal
                isOpen={paymentmodal}
                onRequestClose={() => {
                  setPaymentmodal(false);
                }}
                className="contingent_payment"
            >
                <button
                        className="add-mem-closebutton"
                        onClick={() => {
                            setPaymentmodal(false);
                        }}
                        >
                        &times;
                </button>
                <h1 className="contingent-head">PAYMENT</h1>
                <Formik
                    initialValues={{check_in : "", check_out: "" ,emergency_number:"" }}
                    onSubmit={handlePayment}
                    validationSchema={validationSchema}
                >

                    {({ isSubmitting ,values,setFieldValue}) => (
                        <Form>
                        <div className="cont-leader-modal-cont-details cont-pay">

                            <div className="input-field">
                                <label>Check-In</label>
                                <div className="contingent-payment-input-error">
                                    <Field as="select" name="check_in">
                                        <option >Check-in</option>
                                        <option >22-01-2025</option>
                                            <option >23-01-2025</option>
                                            <option >24-01-2025</option>
                                            <option >25-01-2025</option>
                                            <option >26-01-2025</option>
                                            <option >27-01-2025</option>
                                            <option >28-01-2025</option>
                                    </Field> 
                                    <ErrorMessage name="check_in" component="div" className="contingent-payment-error" />               
                                </div>
                            </div>
                            <div className="input-field">
                                <label>Check-Out</label>
                                <div className="contingent-payment-input-error">
                                    <Field as="select" name="check_out">
                                        <option >Check-out</option>
                                        <option >22-01-2025</option>
                                            <option >23-01-2025</option>
                                            <option >24-01-2025</option>
                                            <option >25-01-2025</option>
                                            <option >26-01-2025</option>
                                            <option >27-01-2025</option>
                                            <option >28-01-2025</option>
                                    </Field>    
                                    <ErrorMessage name="check_out" component="div" className="contingent-payment-error" />
                                </div>
                            </div>
                            <div className="input-field">
                                <label>Emergency Number</label>
                                <div className="contingent-payment-input-error">
                                    <Field type="tel" name="emergency_number" placeholder=" emergency number" maxLength='10' />
                                    <ErrorMessage name="emergency_number" component="div" className="contingent-payment-error" />
                                </div>
                            </div>
                        </div>
                        <div className="cont-submit-button cont-payment-button">
                        {loading?(<button type="submit" className="contingent-leader-modal-paymentbutton" disabled={isSubmitting}>
                                Initiating...
                            </button>):(    <button type="submit" className="contingent-leader-modal-paymentbutton" disabled={isSubmitting}>
                                Make Your Payment
                            </button>)}
                        </div>
                        
                    
                        </Form>
                    )}
                    
                </Formik>
            </ReactModal>
            <ReactModal
                isOpen={barcode}
                onRequestClose={() => {
                    setBarcode(false);
                }}
                className="barcode-1"
            >
                <button
                    className="add-mem-closebutton"
                    onClick={() => {
                        setBarcode(false);
                    }}
                >
                    &times;
                </button>
                <div className="barcode"><Barcode value={contingentdetails?.id} /></div>
            </ReactModal>
            <div className="contingent-leader-modal">

                
                <h2 className="contingent-leader-modal-contname">{contingentdetails?.contingent_name}</h2>
                <div className="contingent-leader-modal-content">
                   <div className="cont-details"> <h3>Contingent-Id : {contingentdetails?.id}</h3> </div>
                   <div>
                        <button  className="cont-leader-modal-addmem-button"
                            onClick={() => {setBarcode(true)}}
                        >
                            Barcode
                        </button>
                    </div>
                    <div className="contingent-leader-modal-main-payment-buttons">{member?.paymentStatus?(<p> </p>):(<button style={{marginTop:'10px',marginBottom:'10px'}}  className="contingent-leader-modal-paymentbutton"onClick={()=>setPaymentmodal(true)}>Make Payment</button>)}</div> 
                    <div className="cont-leader-modal-member-details">
                    <p>Number of Members : {contingentdetails?.updatedMembersInfo?.length}</p><br></br>
                            {
                                contingentdetails?.updatedMembersInfo?.map((member)=>(
                                    <div className='cont-leader-modal-member-payment-status'>
                                        <div style={{display:'flex'}}>
                                       <div style={{display:'flex',flexDirection:'column'}}> <p> {member?.name}</p>
                                        <p>{member?.sfId}</p></div>
                                        {(member?.id===leaderID)&&<div className="contingent-crown" style={{marginLeft:'10px'}}><img src={crown} /></div>}
                                        </div>
                                        
                                      <div>  {member?.paymentStatus?(<p className="cont-leader-paid">Paid</p>):(<p className="cont-leader-unpaid">Unpaid</p>)}</div>
                                    </div>
                                ))
                            }
                        
                    <div className="cont-leader-modal-adddel-buttons">{loading?(<button className='cont-leader-modal-deletemem-button'onClick={handleLeaveContingent}>Leaving...</button>):(<button className='cont-leader-modal-deletemem-button'onClick={handleLeaveContingent}>Leave Contingent</button>)}
                    
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default MemberContingent;



