import { useNavigate } from "react-router-dom";
import './Backbutton.css'
import arrowBackIcon from './arrow_back_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.png'

export default function BackButton() {
  let navigate = useNavigate();
  return (
    <div className="back-navigation-button-container">
      <button className="back-navigation-button" onClick={() => navigate(-1)}> <img className="back-navigation-button-img" src={`${arrowBackIcon}`} alt="Back" /> </button>
      
    </div>    
  );
};