import { React, useEffect, useState } from "react";
import ProfileProvider from "./Components/usecontext/profile_provider";
import LogoutProvider from "./Components/usecontext/logout_provider";
import Signin from "./Components/Authentication/signin";
import Signup from "./Components/Authentication/signup";
import ForgetPassword from "./Components/Authentication/forget-password"
import Dashboard from "./Components/Dashboard/dashboard";
import Navbar from "./Components/Navbar/Navbar";
import Homepage from "./Components/Homepage/Homepage";
import Events from "./Components/Events/Events";
import SubEvents from "./Components/Sub_Events/SubEvents";
import Faqs from "./Components/Faqs/Faqs";
import AccInfo from "./Components/Accommodation/Acc-info";
import AccRules from "./Components/Accommodation/Acc-Rules";
import Accfaq from "./Components/Accommodation/acc-FAQ";
import AccMap from "./Components/Accommodation/Acc-Map";
import Schedule from "./Components/Schedule/Schedule";
import Gallery from "./Components/Gallery/Gallery";
import GalleryPhone from "./Components/Gallery/GalleryPhone";
import Aftermovie from "./Components/Aftermovie/Aftermovie";
import Games from "./Components/Games/Games";
import Merch from "./Components/Merch/Merch";
import { useMediaQuery } from "react-responsive";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Navigate
} from "react-router-dom";
import { PropTypes } from "prop-types";
import { Toaster, toast } from "react-hot-toast";
import BackButton from "./Components/Back-Navigation-Full/Back-button"
import Contingent from "./Components/Contingent/Contingent";
import EventsProvider from "./Components/usecontext/events_provider";
import SubmitProvider from "./Components/usecontext/submit_provider";
import GameProvider from "./Components/usecontext/Game_provider";
import Cart from "./Components/Merch/Cart";
import Preloader from "./Components/PreLoader/Preloader";
import Leaderboard from "./Components/Games/Leaderboard";
import LeaderboardCans from "./Components/Games/Leaderboard_cans";
import WitchRunner from "./Components/Games/WitchRunner.jsx";
import SmashtheCans from "./Components/Games/SmashtheCans.jsx";

import img1 from './Components/Homepage/Assests/ilu_white.webp';
import img2 from "./Components/Homepage/Assests/bg.webp";
import img3 from "./Components/Dashboard/webp_images/dashboard_background.webp";
import img4 from "./Components/Dashboard/webp_images/femaleicon.webp";
import img5 from "./Components/Dashboard/webp_images/Dashboard_dp_Male.webp";
import img6 from "./Components/Dashboard/modal-background-cropped.png";
import img7 from "./Components/Accommodation/Assets/House.webp";
import img8 from "./Components/Accommodation/Assets/PhoneHouse.webp";
import img9 from "./Components/Accommodation/Assets/Map.webp";
import img10 from "./Components/Authentication/AlbedoBase_XL_create_an_image_of_dark_night_theme_of_a_carniva_0.webp";
import img11 from "./Components/Aftermovie/theatre.webp";
import img12 from "./Components/Authentication/scroll 2.webp";
import img14 from "./Components/Authentication/scroll3.webp";
import img15 from "./Components/Contingent/Accommodation-bg-2.c2f874d3ccdb19352d83.png";
import img16 from "./Components/Events/assets/events_bg.jpg";
import img17 from "./Components/Events/assets/faqs_bg.jpg";
import img18 from "./Components/Gallery/background_2.webp";
import img19 from "./Components/Gallery/background_3.webp";
import img21 from "./Components/Navbar/Assets/accicon.webp";
import img22 from "./Components/Navbar/Assets/aftermovieicon.png";
import img23 from "./Components/Navbar/Assets/eventsicon.webp";
import img24 from "./Components/Navbar/Assets/galleryicon.png";
import img25 from "./Components/Navbar/Assets/gamesicon.png";
import img26 from "./Components/Navbar/Assets/homeicon.webp";
import img27 from "./Components/Navbar/Assets/merchicon.webp";
import img28 from "./Components/Navbar/Assets/moreicon.webp";
import img29 from "./Components/Navbar/Assets/prev.webp";
import img30 from "./Components/Navbar/Assets/sponsicon.png";
import img31 from "./Components/Sub_Events/subeventscard.jpeg";
import img32 from './Components/Homepage/Assests/ilu_black.webp';
import img33 from './Components/Navbar/Assets/magical_balll.webp';
import img34 from './Components/Homepage/Assests/phonehome.webp';
import img35 from './Components/Games/Assests/bcg.webp';
import img36 from './Components/Games/Assests/game1.webp';
import img37 from './Components/Games/Assests/image.webp';
import img38 from './Components/Games/Assests/game2.1.webp';

const App = () => {
  const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img14, img15, img16, img17, img18, img19, img21, img22, img23, img24, img25, img26, img27, img28, img29, img30, img31, img32, img33, img34, img35, img36, img37, img38];
  const [showPreloader, setShowPreloader] = useState(true)

  useEffect(() => {
    preloadImages(images);
  }, []);

  const preloadImages = (images) => {
    let loadedCount = 0;
    images.forEach((url) => {
      const img = new Image();

      img.onload = () => {
        loadedCount++;
        if (loadedCount === images.length) {
          setShowPreloader(false)
        }
      };

      img.onerror = () => {
        console.error(`Failed to load image: ${url}`);
        loadedCount++;
        if (loadedCount === images.length) {
          setShowPreloader(false)
        }
      };
      img.src = url;
    });
  };
  const ProtectedRoute = ({ children }) => {
    const userData = localStorage.getItem("userData");

    if (!userData) {
      toast.error("Please Sign-in to continue")
      return <Navigate to="/signin" replace />;
    }
    return children;
  };

  const PostLoggedRoute = ({ children }) => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      return <Navigate to="/dashboard" replace />;
    }
    return children;
  };

  const is600 = useMediaQuery({ query: "(min-width: 600px)" });

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<> <Navbar is600={is600} preloader={showPreloader} /></>}>
          <Route path="/" element={<>{showPreloader ? <Preloader /> : <Homepage />}</>} />
          <Route path="/dashboard" element={<> <ProtectedRoute> <BackButton /> <Dashboard /> </ProtectedRoute> </>} />
          <Route path="/contingent" element={<><BackButton /> <ProtectedRoute> <Contingent /> </ProtectedRoute> </>} />
          <Route path="/signin" element={<><BackButton /> <PostLoggedRoute> {" "} <Signin />{" "} </PostLoggedRoute> </>} />
          <Route path="/signup" element={<><BackButton /> <PostLoggedRoute> {" "} <Signup />{" "} </PostLoggedRoute> </>} />
          <Route path="/forgot-password" element={<><BackButton /><PostLoggedRoute> {" "} <ForgetPassword />{" "} </PostLoggedRoute> </>} />
          <Route path="/events" element={<><BackButton /> <Events />  </>} />
          <Route path="/events/subevents/:eventname" element={<><BackButton /><SubEvents /></>} />
          <Route path="/faqs" element={<><BackButton /> <Faqs /> </>} />
          <Route path="/accommodation" element={<><BackButton /> <AccInfo /> </>} />
          <Route path="/accommodation-rules" element={<><BackButton /> <AccRules /> </>} />
          <Route path="/accommodation-faq" element={<><BackButton /> <Accfaq /> </>} />
          <Route path="/accommodation-map" element={<><BackButton /> <AccMap /> </>} />
          <Route path="/gallery" element={is600 ? <><BackButton /><Gallery /></> : <><BackButton /><GalleryPhone /></>} />
          <Route path="/aftermovie" element={<><BackButton /> <Aftermovie /> </>} />
          <Route path="/games" element={<><BackButton /> <Games /> </>} />
          <Route path="/schedule" element={<><BackButton /> <Schedule /> </>} />
          {/* <Route path="/merchandise" element={<><BackButton /><Games /></>} /> */}
          <Route path="/merchandise" element={<><BackButton /><Merch/></>} />
          <Route path="/cart" element={<><BackButton /><Cart /></>} />
          <Route path="/leaderboard/witch" element={<><BackButton /><Leaderboard /></>} />
          <Route path="/leaderboard/cans" element={<><BackButton /><LeaderboardCans /></>} />
        </Route>
        <Route path="/games/witch" element={<><WitchRunner /></>} />
        <Route path="/games/cans" element={<><SmashtheCans /></>} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </>
    ),
    {
      future: {
        v7_startTransition: true,
        v7_relativeSplatPath: true,
        v7_fetcherPersist: true,
        v7_normalizeFormMethod: true,
        v7_partialHydration: true,
        v7_skipActionErrorRevalidation: true,
      },
    }
  );

  return (
    <>
      <Toaster toastOptions={{
        removeDelay: 500,
        duration: 1500
      }} />

      <LogoutProvider>
        <ProfileProvider>
          <EventsProvider>
            <SubmitProvider>
              <GameProvider>
                <RouterProvider router={router} />
              </GameProvider>
            </SubmitProvider>
          </EventsProvider>
        </ProfileProvider>
      </LogoutProvider>

    </>
  );
};

export default App;

App.propTypes = {
  children: PropTypes.isRequired,
};