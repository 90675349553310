import Navbar from "./AcNavbar"
import { useEffect } from "react"
import VanillaTilt from "vanilla-tilt";
import "./acc-cards.css"
import AccCards from "./acccardlist";

const Info=[
    {
        heading:"",
        content: "Kharagpur is about 140 kms west of Kolkata and is well connected to the city by road and rail.\nKharagpur can be reached in about 2 hours by train or 3 hours by car from Kolkata's Howrah railway station. Kharagpur is also linked by direct train services to the majority of the country's major cities.\nThe Institute is about a 10-minute drive (5 km) from the Kharagpur railway station and a 5 min walk from Hijli station. To get to the Institute, you can hire a private taxi, autorickshaw, or cycle rickshaw.\n**Buses will be provided by SPRING FEST at Kharagpur railway station for the guests to reach IIT Kharagpur campus." 
    },
    {
        heading:"You can reach IIT Kharagpur in two ways:",
        content:"By Air: The nearest airport to Kharagpur is Kolkata's Netaji Subhas Chandra Bose International Airport (CCU). From Kolkata Airport, take a taxi to Kharagpur from the airport taxi stand. The distance is close to 140 Kilometres. Take a taxi to Howrah railway station. Regular express and local trains run to Kharagpur. The Institute is located 5 Kilometres from Kharagpur Railway Station.\nBy train: Kharagpur is well connected to most major cities of India by rail. The nearest railway station to IIT Kharagpur is Hijli Station. There are frequent trains to Kharagpur. Alternatively, you can reach the Howrah Railway station and take a local or express train to Kharagpur or Book a cab to reach Kharagpur via road. The distance is almost 140KM."
    },
    {
        heading:"Some Important Things To Remember",
        content:"1. A Team ID will be allocated to the team on registration which shall be used for future references.\n2. No responsibility will be held by Spring Fest, IIT Kharagpur for any late, lost, or misdirected entries.\n3. All modes of official communication will be through the Spring fest e-mail. Participants are advised to keep track of all folders in their email accounts.\n4. Note that at any point of time the latest information will be the one mentioned on the website. However, registered participants will be informed through mail about any changes.\n5. The decision of the organizers or judges shall be treated as final and binding on all."
    },
    
]
const AccMap = () => {
    useEffect(() => {
        VanillaTilt.init(document.querySelectorAll(".tiltcard"),{
          max:5,
          speed:10,
          glare: false,
          'max-glare':0.5,
        })
      })
    return (
        <> 
        <div className="acc-bg"></div>
        <section className="acc-info">
            <Navbar />
            <div className="acctitdiv"><h1 className='acc-title acmaptit'>REACHING IIT KGP</h1></div>
            <AccCards info={Info}/>
            <div className="acmapcont">
            {/* <div className="acmapicont"> */}
                {/* <img src={mapscroll} alt="Scroll Map" /> */}
            <div className="acgmap">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d59056.51728954376!2d87.309895!3d22.314617!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1d440255555547%3A0x6f2f20dd0c0d6793!2sIndian%20Institute%20of%20Technology%2C%20Kharagpur!5e0!3m2!1sen!2sin!4v1735059649390!5m2!1sen!2sin" width="600" height="450"
                style={{border:"0"}} allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="kgpmap"></iframe>
            </div>
            {/* </div> */}
            </div>
        </section>
        </>
     );
}
 
export default AccMap;