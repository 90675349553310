import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import './dashboard.css'
import React, { useState } from "react"
import Reportissue from './dashboard_report_issue';
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
import { ProfileContext } from "../usecontext/profile_provider";
import { LogoutContext } from "../usecontext/logout_provider";
import scrollimg from "./webp_images/dashboardscroll1.webp"
import profileimg from "./webp_images/dashboardprofile2.webp"
import complaintimg from "./webp_images/dashboardcomp1.webp"
import crown from './crown.png'
import hourglass from './webp_images/hourglass.png'
import ReactGA from 'react-ga';
import Barcode from 'react-barcode';
import ReactModal from "react-modal";
import { Link } from "react-router-dom";

const Dashboard = () => {
    const { userData, setUserData, isLoggedIn, setIsLoggedIn,homepay,setHomepay } = useContext(ProfileContext);
    const { setCheckLogout } = useContext(LogoutContext);
    

    const [temp_profile, setTemp_Profile] = useState()

    const [solo_events, setSoloEvents] = useState([]);
    const [group_events, setGroupEvents] = useState([]);
    const [showAddMember, setShowAddMember] = useState(false)
    const [memberSfId, setMemberSfId] = useState("");
    const [memberEmail, setMemberEmail] = useState("");
    const [ATMeventid, setATMeventid] = useState(0)
    const [ModalOn, setModalOn] = useState(false)
    const [loadingforAddMem, setLoadingforAddMem] = useState(false)
    const [loadingforDeregMem, setLoadingforDeregMem] = useState(false)
    const [loadingforDeregTeam, setLoadingforDeregTeam] = useState(false)
    const [loadingforDeregSolo, setLoadingforDeregSolo] = useState(false)
    const [loadinKeyforSolo, setLoadinKeyforSolo] = useState(-1)
    const [loadingKeyforDeregMem, setLoadingKeyforDeregMem] = useState(-1)
    const [getUser,setGetUser]=useState();
    const [currentComplaints, setCurrentComplaints] = useState([]);
    const [closedComplaints, setClosedComplaints] = useState([]);
    const [resolvedComplaints, setresolvedComplaints] = useState([]);
    const [barcode,setBarcode]= useState(false);
    const [tempUser, setTempUser] = useState(null);

    ReactGA.initialize("G-23Q6ZTSW0Q");
    ReactGA.send({ hitType: "pageview", page: "/dashboard", title: "Dashboard Visited Subheads" });
    useEffect(() => {
        const fx = async () => {
            await getUsers();
            await fetchRegisteredEvents();
        };
        fx();
    }, []);

    useEffect(() => {
        const fx = async () => {
            if (ModalOn) {
                await fetchRegisteredEvents()
            }
        }
        fx()
    }, [userData])

    const token = userData.token
    const navigate = useNavigate();
    const getUsers = async () => {
        const toSend = { token: userData.token };
    
        try {
            const response = await axios.post('https://masterapi.springfest.in/api/user/getUser', toSend);
            if (response.data.code === 0) {
                setGetUser(response.data);
                setTempUser(response.data);
                
                setTemp_Profile({
                    "name": response.data?.data?.name,
                    "email": response.data?.data?.email,
                    "mobile": response.data?.data?.mobile,
                    "sfId": response.data?.data?.sfId,
                    "city": response.data?.data?.city,
                    "college": response.data?.data?.college,
                    "payment_status": response.data?.data?.payment_status,
                    "gender": response.data?.data?.gender,
                    "hall_allotted": response.data?.hallAlloted,
                    "user_id": response.data?.data?.id
                });
                setHomepay(response.data?.data?.payment_status);
            } else {
                console.log(response.data);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleLogout = async () => {
        if (localStorage.getItem("userData") && (isLoggedIn === true)) {
            localStorage.removeItem("userData");
            setUserData('');
            setIsLoggedIn(false)
            setSoloEvents([])
            setGroupEvents([])
            setCheckLogout(true)
            navigate("/");
        }
        else {
        }
    }

    const logoutifTokenExp = async () => {
        localStorage.removeItem("userData")
        setUserData('')
        setIsLoggedIn(false)
        setSoloEvents([])
        setGroupEvents([])
        setCheckLogout(true)
        navigate("/signin")
    }

    const setBackgroundBlur = () => {
        const BackgroundBlur = document.getElementsByClassName("dashboard-background-blur")[0]
        if (BackgroundBlur) {
            BackgroundBlur.style.height = '100vh';
            BackgroundBlur.style.width = '100vw';
        }
        const LogOut = document.getElementsByClassName("dashboard-logout")[0]
        const phoneTop = document.getElementsByClassName("dashboard-phone-top")[0]
        if (LogOut && phoneTop) {
            LogOut.style.display = "none";
            phoneTop.style.display = "none";
        }
    }

    const setBackgroundUnBlur = () => {
        const BackgroundBlur = document.getElementsByClassName("dashboard-background-blur")[0]
        if (BackgroundBlur) {
            BackgroundBlur.style.height = '0vh';
            BackgroundBlur.style.width = '0vw';
        }
        const LogOut = document.getElementsByClassName("dashboard-logout")[0]
        const phoneTop = document.getElementsByClassName("dashboard-phone-top")[0]
        if (LogOut && phoneTop) {
            LogOut.style.display = "flex";
            phoneTop.style.display = "flex";
        }
    }

    const setModalOpen = (modal) => {
        setBackgroundBlur();
        modal.style.display = "flex";
        const ModalContainer = document.getElementsByClassName("dashboard-modal-container")[0]
        if (ModalContainer) {
            ModalContainer.style.display = "flex";
            setModalOn(true)
        }
    }

    const setModalClose = () => {
        let modals = document.getElementsByClassName("dashboard-modals");
        for (let i = 0; i < 3; i++) {
            modals[i].style.display = "none";
        }
        setBackgroundUnBlur()
        const ModalContainer = document.getElementsByClassName("dashboard-modal-container")[0]
        if (ModalContainer) {
            ModalContainer.style.display = "none"
            setModalOn(false)
        }
    }

    const setEventName = (element, i) => {
        return (
            <div className='dashboard-register-modal-field' key={element.event.id} >
                <div className="dashboard-register-modal-event">{element.event.name}</div>
                <button className="dashboard-register-modal-unregister dashboard-deregister-button"
                    onClick={() => {
                        setLoadingforDeregSolo(true)
                        setLoadinKeyforSolo(element.event.id)
                        deregisterUserFx(element.event.name, element.event.id)
                    }} >{(loadingforDeregSolo && element.event.id === loadinKeyforSolo) ? 'Deregistering...' : 'Deregister'}</button>
            </div>
        )
    }

    //Fx to fetch the id on the basis of event name 
    const fetchEventId = async (event_name) => {
        let event_id
        try {
            const response = await axios.get(
                "https://masterapi.springfest.in/api/event"
            );
            if (response.data.code === 0) {
                Array.from(response.data.data).forEach(element => {
                    Array.from(element.events).forEach(item => {
                        if (item.name === event_name) {
                            event_id = item.id
                        }
                    })
                });
                return event_id
            }
            else if (response.data.code === -5) {
                logoutifTokenExp()
            }
            else {
                toast.error(response.data.message)
            }
        }
        catch (err) {
            console.log("Cannot get the event id ", err)
        }
    }

    //Fx to check if the event is a solo event or a team event
    const fetchEventType = async (event_name) => {
        let event_type
        try {
            const response = await axios.get(
                "https://masterapi.springfest.in/api/event"
            );
            if (response.data.code === 0) {
                Array.from(response.data.data).forEach(element => {
                    Array.from(element.events).forEach(item => {
                        if (item.name === event_name) {
                            event_type = item.max_participation
                        }
                    })
                });
                return event_type
            }
            else if (response.data.code === -5) {
                logoutifTokenExp()
            }
            else {
                toast.error(response.data.message)
            }
        }
        catch (err) {
            console.log("Cannot get the event type ", err)
        }
    }

    const fetchRegisteredEvents = async () => {
        const toSend = {
            token: token
        }
        try {
            const response = await axios.post(
                "https://masterapi.springfest.in/api/user/registered_events",
                toSend
            )
            if (response.data.code === 0) {
                setSoloEvents(response.data.soloEventData)
                setGroupEvents(response.data.groupEventData)
            }
            else if (response.data.code === -5) {
                logoutifTokenExp()
            }
            else {
                toast.error(response.data.message)
            }
        }
        catch (err) {
            console.log("Cannot fetch the registered events ", err)
        }
    }

    //Deregister the user from the event or the whole team
    const deregisterUserFx = async (event_name, event_id) => {
        let event_type = await fetchEventType(event_name) //checking if the event is a team event or a solo event
        if (event_type === 1) {
            const userData = {
                token: token,
                eventId: event_id,
                memberToDeregister: [
                    {
                        email: temp_profile.email,
                        sfId: temp_profile.sfId
                    }
                ]
            }
            try {
                const response = await axios.post(
                    "https://masterapi.springfest.in/api/event/deregister/member",
                    userData
                );
                if (response.data.code === 0) {
                    await fetchRegisteredEvents()
                    setLoadingforDeregSolo(false)
                    setLoadinKeyforSolo(-1)
                    toast.success(response.data.message)
                }
                else if (response.data.code === -5) {
                    logoutifTokenExp()
                }
                else {
                    setLoadingforDeregSolo(false)
                    setLoadinKeyforSolo(-1)
                    toast.error(response.data.message)
                }
            } catch (err) {
                console.log("Entered catch block of dereg solo event ", err);
            }
        }
        else {
            const userData = {
                token: token,
                eventId: event_id,
            }
            try {
                const response = await axios.post(
                    "https://masterapi.springfest.in/api/event/deregister/team",
                    userData
                );
                if (response.data.code === 0) {
                    toast.success(response.data.message)
                    await fetchRegisteredEvents()
                    setLoadingforDeregTeam(false)
                }
                else if (response.data.code === -5) {
                    logoutifTokenExp()
                }
                else {
                    toast.error(response.data.message)
                    setLoadingforDeregTeam(false)
                }
            } catch (err) {
                console.log("Entered catch block of dereg team event ", err);
            }
        }
    }

    //Deregister the member of your team 
    const deregisterTeamMember = async (event_name, mem_mail, mem_sfId) => {
        let id = await fetchEventId(event_name)
        const userData = {
            token: token,
            eventId: id,
            memberToDeregister: [
                {
                    email: mem_mail,
                    sfId: mem_sfId
                }
            ]
        }
        try {
            const response = await axios.post(
                "https://masterapi.springfest.in/api/event/deregister/member",
                userData
            );
            if (response.data.code === 0) {
                await fetchRegisteredEvents()
                setLoadingforDeregMem(false)
                setLoadingKeyforDeregMem(-1)
                toast.success(response.data.message)
            }
            else if (response.data.code === -5) {
                logoutifTokenExp()
            }
            else {
                setLoadingforDeregMem(false)
                setLoadingKeyforDeregMem(-1)
                toast.error(response.data.message)
            }
        } catch (err) {
            console.log("Entered catch block of dereg member from team ", err);
        }
    }

    //Fx to add a member to the team 
    const addTeamMember = async (event_id, mem_mail, mem_sfId) => {
        const toSend = {
            token: token,
            eventId: event_id,
            teamMembers: [
                {
                    email: mem_mail,
                    sfId: mem_sfId
                }
            ]
        }
        try {
            const response = await axios.post(
                "https://masterapi.springfest.in/api/event/addMember",
                toSend
            )
            if (response.data.code === 0) {
                await fetchRegisteredEvents()
                setLoadingforAddMem(false)
                toast.success(response.data.message)
            }
            else if (response.data.code === -5) {
                logoutifTokenExp()
            }
            else {
                setLoadingforAddMem(false)
                toast.error(response.data.message)
            }
        }
        catch (err) {
            console.log("Entered catch block of add member to team ", err)
        }
    }

    //Fx to fetch the user complaints 
    const fetchUserComplaints = async () => {
        const userData = {
            token: token
        }
        try {
            const response = await axios.post(
                "https://masterapi.springfest.in/api/user/complaints/fetchComplaints",
                userData
            )
            if (response.data.code === 0) {
                let allComplaints = response.data.data
                return allComplaints
            }
            else if (response.data.code === -5) {
                logoutifTokenExp()
            }
            else {
                toast.error(response.data.message)
                // console.log("fetchuser fail")
            }
        }
        catch (err) {
            console.log("Enterd the catch block from the fetch user complaints ", err)
        }
    }

    const handleFetchComplaints = async () => {
        try {
            const complaints = await fetchUserComplaints()
            const current = complaints.filter((c) => c.status === "In Progress");
            const closed = complaints.filter((c) => c.status === "Closed");
            const resolved = complaints.filter((c) => c.status === "Resolved");

            setCurrentComplaints(current);
            setClosedComplaints(closed);
            setresolvedComplaints(resolved);
        } catch (err) {
            console.error("Error fetching complaints: ", err);
        }
    };

    useEffect(() => {
        handleFetchComplaints();
    }, [])

    const handleCancel = (id) => {
        const complaintToClose = currentComplaints.find((c) => c.id === id);
        if (complaintToClose) {
            const fx = async () => {
                await cancelUserComplaint(id)
                complaintToClose.status = "Closed"
                setClosedComplaints((prev) => [...prev, complaintToClose]);
                setCurrentComplaints((prev) =>
                    prev.filter((complaint) => complaint.id !== id)
                )
            }
            fx()
        }
    };

    const [loadingforCancelComp, setLoadingforCancelComp] = useState(false)
    const [loadingKeyforCancelComp, setLoadingKeyforCancelComp] = useState(-1)

    const showComplaints = (currComp, cloComp, resComp) => {
        return (
            <div className="dashboard-complaint-status-modal">
                <div className="dashboard-modal-form-subhead" style={{ margin: 'auto', marginBottom: '10px', marginTop: '10px' }}>Current Complaints</div>
                {currComp.length > 0 ? (
                    <div className="dashboard-complaint-field">
                        {currComp.map(comp => (
                            <div className="dashboard-complaint-subfield" key={comp.id}>
                                <div className="dashboard-complaint-id-cancel">
                                    <p className="dashboard-compliant-id">Comp_no : {comp.id}</p>
                                    <button className="dashboard-complaint-cancel"
                                        onClick={() => {
                                            setLoadingKeyforCancelComp(comp.id)
                                            setLoadingforCancelComp(true)
                                            handleCancel(comp.id)
                                        }}>
                                        {(loadingforCancelComp && comp.id === loadingKeyforCancelComp) ? 'Cancelling...' : 'Cancel'}</button>
                                </div>
                                <div className="dashboard-complaint-issue-status">
                                    <div style={{ wordWrap: 'break-word' }} className="dashboard-complaint-issuetype"><strong >Issue : </strong>{comp.issue}</div>
                                    <p className="dashboard-complaint-status"><strong >Status : </strong>{comp.status}</p>
                                </div>
                            </div>
                        ))
                        }
                    </div>
                ) : (
                    <p style={{ padding: "10px 20px", }}>No current Complaints</p>
                )}
                <div className="dashboard-modal-form-subhead" style={{ margin: 'auto', marginBottom: '10px', marginTop: '10px' }} >Closed Complaints</div>
                {cloComp.length > 0 ? (
                    <div className="dashboard-complaint-field">
                        {cloComp.map(comp => (
                            <div className="dashboard-complaint-subfield">

                                <p className="dashboard-compliant-id">Comp_No : {comp.id}</p>
                                <div className="dashboard-complaint-issue-status" >
                                    <p className="dashboard-complaint-issuetype"><strong >Issue : </strong><p id="dashboard-complaint-issuetype">{comp.issue}</p></p>
                                    <p className="dashboard-complaint-status"><strong >Status : </strong>{comp.status}</p>
                                </div>
                            </div>
                        ))
                        }
                    </div>
                ) : (
                    <p style={{ padding: "10px 20px", }}>No Closed Complaints</p>
                )}
                <div className="dashboard-modal-form-subhead" style={{ margin: 'auto', marginBottom: '10px', marginTop: '10px' }} >Resolved Complaints</div>
                {resComp.length > 0 ? (
                    <div className="dashboard-complaint-field">
                        {resComp.map(comp => (
                            <div className="dashboard-complaint-subfield">
                                <p className="dashboard-compliant-id">Comp_No : {comp.id}</p>
                                <div className="dashboard-complaint-issue-status">
                                    <p style={{ wordWrap: 'break-word' }} className="dashboard-complaint-issuetype"><strong >Issue : </strong>{comp.issue}</p>
                                    <p className="dashboard-complaint-status"><strong >Status : </strong>{comp.status}</p>
                                </div>
                            </div>
                        ))
                        }
                    </div>
                ) : (
                    <p style={{ padding: "10px 20px", }}>No Resolved Complaints</p>
                )}
            </div>
        )
    }

    //Fx to cancel the user complaint
    const cancelUserComplaint = async (complaint_id) => {
        const toSend = {
            complaint_id: complaint_id,
            token: token
        }
        try {
            const response = await axios.post(
                "https://masterapi.springfest.in/api/user/complaints/cancelComplaints", toSend)
            if (response.data.code === 0) {
                setLoadingforCancelComp(false)
                setLoadingKeyforCancelComp(-1)
                toast.success(response.data.message)
            }
            else {
                setLoadingforCancelComp(false)
                setLoadingKeyforCancelComp(-1)
                toast.error(response.data.message)
            }
        }
        catch (err) {
            console.log("Entered the catch block of cancel user complaint ", err)
        }
    }

    function Complaintsform() {
        const [selected, setSelected] = useState('Select Issue')
        const handleChange = (e) => {
            setSelected(e.target.value)
        }

        const handleSubmitAPI = async (type, issue) => {
            const resetForm = () => {
                setSelected('Select Issue');
                document.getElementById('Issue').value = '';
            };

            const func = async () => {
                await handleFetchComplaints();
                console.log("complaint fetched")
                console.log(currentComplaints)
            }
            try {
                const response = await axios.post("https://masterapi.springfest.in/api/user/complaints/submitComplaints", {
                    "token": token,
                    "type": type,
                    "issue": issue
                })
                if (response.data.code === 0) {
                    toast.success(response.data.message);
                    func()
                    resetForm()
                } else {
                    toast.warning(response.data.code)
                }
            }
            catch {
                console.log("Submit API error")
            }
        }

        const resetForm = () => {
            setSelected('Select Issue');
            document.getElementById('Issue').value = '';
        };

        const handleOtherSubmit = () => {
            let type = "Others";
            let issue = document.getElementById("Issue").value
            if (issue.match(/[a-zA-Z]/g)) {
                handleSubmitAPI(type, issue)
                const func = async () => {
                    await handleFetchComplaints();
                    console.log("complaint fetched")
                    console.log(currentComplaints)
                }
                func()
                setModalClose()
            }
            else {
                toast.error("Please Fill in the Issue")
            }
        }

        return (
            <div className="dashboard-modal-comlaint-form-group">
                <label htmlFor="nature" >Issue Where : </label>
                <select className="dashboard-modal-compliant-form-control" value={selected} onChange={(e) => handleChange(e)}>
                    <option>Select Issue</option>
                    <option>Registered Events</option>
                    <option>Other's</option>
                </select>
                {selected === "Other's" ?
                    <div className="dashboard-modal-comlaint-form-group">
                        <label htmlFor="Issue">Issue : </label>
                        <input type="Issue" id="Issue" name="Issue" className="dashboard-modal-compliant-form-control"></input>
                        <button type="submit" id='dashboard-modal-complaint-submit-button' className='dashboard-modal-complaint-submit-button' onClick={handleOtherSubmit}>Submit</button>
                    </div>
                    : ""}
                {selected === "Registered Events" ? <Reportissue resetParentForm={resetForm} handleFetchComplaints={handleFetchComplaints} /> : ""}
            </div>
        );
    }

    const setRegisteredGroupMembers = (users, event, leader_id) => {
        return users.map(subele => (
            <div className='dashboard-register-modal-field' key={subele.user.sfId} >
                <div id="dashboard-register-modal-groupmember" className='dashboard-register-modal-field'>
                    <div className="dashboard-register-modal-event" style={{ flexDirection: 'row' }}>{subele.user.sfId}-{subele.user.name} {subele.user.id === leader_id && <div className="dashboard-crown" style={{ marginLeft: '10px' }}><img src={crown} style={{ marginBottom: '10px' }} alt="(Leader)" /></div>}</div>
                    {tempUser?.data?.id === leader_id && subele.user.id !== leader_id && <button className="dashboard-register-modal-unregister dashboard-deregister-member" onClick={() => {
                        setLoadingforDeregMem(true)
                        setLoadingKeyforDeregMem(subele.user.sfId)
                        deregisterTeamMember(event.event.name, subele.user.email, subele.user.sfId)
                    }}>{(loadingforDeregMem && subele.user.sfId === loadingKeyforDeregMem) ? "Deregistering..." : 'Deregister Member '}</button>}
                </div>
            </div>
        ))
    }

    const setRegisteredEvents = (events) => {
        if (!events || events.length === 0) {
            return <div className='dashboard-register-modal-field' style={{ padding: "10px 0px", }}>No events registered</div>;
        }
        return events.map((event, index) => (
            <div key={index} className='dashboard-register-modal-field'>
                {setEventName(event, index)}
            </div>
        ));
    };

    const setRegisteredGroupEvents = (events) => {
        if (!events || events.length === 0) {
            return <div className='dashboard-register-modal-field' style={{ padding: "10px 0px", }}>No events registered</div>;
        }
        return events.map((ele, i) => (
            <div className="dashboard-register-submodal" key={i++}>
                <div className='dashboard-register-modal-field'>
                    <div id="dashboard-register-modal-unregister" className='dashboard-register-modal-field ' >
                        <div id="dashboard-register-modal-event" className="dashboard-register-modal-event dashboard-register-modal-field-title" >{ele.event.name}</div>
                        {tempUser?.data?.id === ele.leader_id && <button className="dashboard-register-modal-unregister dashboard-deregister-team"
                            onClick={() => {
                                setLoadingforDeregTeam(true)
                                deregisterUserFx(ele.event.name, ele.event.id)
                            }}>{loadingforDeregTeam ? 'Deregistering...' : 'Deregister Team'}</button>}
                    </div>
                </div>
                {setRegisteredGroupMembers(ele.GroupMembers, ele, ele.leader_id)}
                {ele.GroupMembers.length < ele.event.max_participation && tempUser?.data?.id === ele.leader_id && <div className="dashboard-add-team-member-form">
                    <div style={{ display: "flex", justifyContent: "center" }}><button className="dashboard-add-team-member-button" onClick={() => {
                        if (ele.leader_id === tempUser?.data?.id) {
                            setShowAddMember(true)
                            setATMeventid(ele.event.id)
                        }
                        else {
                            toast.error("You are not the leader so you can't add a member")
                        }
                    }}>{loadingforAddMem ? 'Adding...' : 'Add member'}</button></div>

                    {showAddMember && ATMeventid === ele.event.id && <div className="dashboard-add-team-member-modal" key={i}>
                        <button className="close-icon-img" onClick={() => {
                            setShowAddMember(false)
                        }}></button>
                        <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}><p>Member SF-ID</p>
                            <input className="dashboard-add-team-member-input" type="text" placeholder="SF-ID" onChange={(e) => {
                                setMemberSfId(e.target.value)
                            }} /></div><br />
                        <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', fontSize: '15px' }}><p>Member Email-id</p>
                            <input className="dashboard-add-team-member-input" type="text" placeholder="email-id" onChange={(e) => {
                                setMemberEmail(e.target.value)
                            }} /></div><br />
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <button className="dashboard-add-team-member-submit-button"
                                onClick={() => {
                                    setMemberEmail("")
                                    setMemberSfId("")
                                    addTeamMember(ele.event.id, memberEmail, memberSfId)
                                    setShowAddMember(false)
                                    setLoadingforAddMem(true)
                                }}>Submit</button>
                        </div>
                    </div>}
                </div>}
            </div>
        ))
    }

    const profileicon = (usergender) => {
        if (usergender === "M") {
            return "dashboard-dp-male"
        }
        else {
            return "dashboard-dp-female"
        }
    } 

    return (
        <div className="dashboard">
            <Toaster />
            <div className="dashboard-phone-top">
                <div className="dashboard-back"></div>
                <div className="dashboard-logout" onClick={handleLogout}>Logout</div>
            </div>
            <div className="dashboard-mid">
                <div className={`dashboard-dp bluebtnfix ${profileicon(temp_profile?.gender)}`} onClick={() => { setModalOpen(document.getElementsByClassName("dashboard-profile-modal")[0]) }} ></div>
                <div className="dashboard-username">{temp_profile?.name}</div>
                <div className="dashboard-id">SF-ID: {temp_profile?.sfId}</div>
            </div>
            <ul className="dashboard-small-icons">
                <li className="dashboard-register bluebtnfix" onClick={() => { setModalOpen(document.getElementsByClassName("dashboard-register-modal")[0]) }}>
                    <div className="dashboard-register-icon"><img className="dashboard-register-image" alt="" src={scrollimg} /></div>
                    <div className="dashboard-register-text" >Registered Events</div>
                </li>
                <li className="dashboard-profile bluebtnfix" onClick={() => { setModalOpen(document.getElementsByClassName("dashboard-profile-modal")[0]) }} >
                    <div className="dashboard-profile-icon"><img className="dashboard-profile-image" alt="" src={profileimg} /></div>
                    <div className="dashboard-profile-text" >Profile</div>
                </li>
                <li className="dashboard-complaint bluebtnfix" onClick={() => { setModalOpen(document.getElementsByClassName("dashboard-complaint-modal")[0]) }}>
                    <div className="dashboard-complaint-icon"><img className="dashboard-complaint-image" alt="" src={complaintimg} /></div>
                    <div className="dashboard-complain-text">Report Issue</div>
                </li>
            </ul>
            <ul className="dashboard-large-icons">
                <Link to={`/schedule`}>
                <li className="dashboard-pay">                                           
                    <div className="dashboard-pay-icon dashboard-blur-element"><img className="dashboard-schedule-hourglass" alt="schedule" src={hourglass}></img></div>
                    <div className="dashboard-pay-text dashboard-blur-elemnt">Schedule</div>
                </li>
                </Link>
                
                <li className="dashboard-scan">
                    <button  onClick={() => {setBarcode(true)}} className="dashboard-scan-icon dashboard-blur-element">
                        <div className="scan">
                            <div className="qrcode"></div>
                            <div className="border"></div>
                        </div>
                    </button>
                    <div className="dashboard-scan-text dashboard-blur-element">Your BarCode</div>
                </li>
            </ul>
                <ReactModal
                    isOpen={barcode}
                    onRequestClose={() => {
                        setBarcode(false);
                    }}
                    className="barcode-1"
                >
                    <button
                        className="add-mem-closebutton"
                        onClick={() => {
                            setBarcode(false);
                        }}
                    >
                        &times;
                    </button>
                    <div className="barcode"><Barcode value={temp_profile?.sfId} /></div>
                </ReactModal>
            <div className="dashboard-background-blur" onClick={() => { setModalClose() }}></div>
            <div className="dashboard-modal-container" >
                <div className="dashboard-register-modal dashboard-modals">
                    <div className="dashboard-modal-head bluebtnfix">Registered Events
                        <svg xmlns="http://www.w3.org/2000/svg" height="35px" onClick={() => { setModalClose(document.getElementsByClassName("dashboard-register-modal")[0]) }} className='dashboard-modal-closingicon' style={{ color: "white" }} viewBox="0 0 384 512"><path fill="#fff" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                    </div>
                    <div className="dashboard-modal-form">
                        <div className="dashboard-modal-form-subhead">Solo Events</div>
                        {setRegisteredEvents(solo_events)}
                    </div>
                    <div className="dashboard-modal-form">
                        <div className="dashboard-modal-form-subhead">Group Events</div>
                        {setRegisteredGroupEvents(group_events)}
                    </div>
                </div>
                <div className="dashboard-profile-modal dashboard-modals">
                    <div className="dashboard-modal-head bluebtnfix">Profile
                        <svg xmlns="http://www.w3.org/2000/svg" height="35px" onClick={() => { setModalClose(document.getElementsByClassName("dashboard-profile-modal")[0]) }} className='dashboard-modal-closingicon' style={{ color: "white" }} viewBox="0 0 384 512"><path fill="#fff" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                    </div>
                    <div className="dashboard-modal-form dashboard-profile-data">
                        <div className="dashboard-profile-modal-field">
                            <div className="dashboard-profile-modal-default">NAME</div>
                            <div className="dashboard-profile-modal-value">{temp_profile?.name}</div>
                        </div>
                        <div className="dashboard-profile-modal-field">
                            <div className="dashboard-profile-modal-default">EMAIL</div>
                            <div className="dashboard-profile-modal-value">{temp_profile?.email}</div>
                        </div>
                        <div className="dashboard-profile-modal-field">
                            <div className="dashboard-profile-modal-default">SF-ID</div>
                            <div className="dashboard-profile-modal-value">{temp_profile?.sfId}</div>
                        </div>
                        <div className="dashboard-profile-modal-field">
                            <div className="dashboard-profile-modal-default">MOBILE</div>
                            <div className="dashboard-profile-modal-value">{temp_profile?.mobile}</div>
                        </div>
                        <div className="dashboard-profile-modal-field">
                            <div className="dashboard-profile-modal-default">CITY</div>
                            <div className="dashboard-profile-modal-value">{temp_profile?.city}</div>
                        </div>
                        <div className="dashboard-profile-modal-field">
                            <div className="dashboard-profile-modal-default">COLLEGE</div>
                            <div className="dashboard-profile-modal-value">{temp_profile?.college}</div>
                        </div>
                        <div className="dashboard-profile-modal-field">
                            <div className="dashboard-profile-modal-default">GENDER</div>
                            <div className="dashboard-profile-modal-value">{temp_profile?.gender}</div>
                        </div>
                        <div className="dashboard-profile-modal-field">
                            <div className="dashboard-profile-modal-default">PAYMENT STATUS</div>
                            <div className="dashboard-profile-modal-value">{temp_profile?.payment_status ? "Paid" : 'Not Paid'}</div>
                        </div>
                        <div className="dashboard-profile-modal-field">
                        <div className="dashboard-profile-modal-default">HALL ALLOTTED</div>
                        <div className="dashboard-profile-modal-value">{temp_profile?.hall_allotted ? temp_profile.hall_allotted : "NA"}</div>
                    </div>
                    </div>
                </div>
                <div className="dashboard-complaint-modal dashboard-modals">
                    <div className="dashboard-modal-head bluebtnfix">Report Issue
                        <svg xmlns="http://www.w3.org/2000/svg" height="35px" onClick={() => { setModalClose(document.getElementsByClassName("dashboard-complaint-modal")[0]) }} className='dashboard-modal-closingicon' style={{ color: "white" }} viewBox="0 0 384 512"><path fill="#fff" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                    </div>
                    <div id="dashboard-complaint-form" className="dashboard-modal-form">
                        <div className="dashboard-modal-formconatiner">
                            <div id="dashboard-modal-form-subhead-submit-complaint" className="dashboard-modal-form-subhead">Submit Complaint</div>
                            {Complaintsform()}
                        </div>
                        {showComplaints(currentComplaints, closedComplaints, resolvedComplaints)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;


