import "./games.css"
import { useContext, useEffect } from 'react';
import { GamesContext } from '../usecontext/Game_provider'
import { NavLink } from "react-router-dom";

const Home = () => {
    const { witchRLoading, cansLoading } = useContext(GamesContext);
    const WitchPlay = () => {
        if (witchRLoading) {
            return "Loading..."
        }
        else {
            return "Play"
        }
    }
    const CansPlay = () => {
        if (cansLoading) {
            return "Loading..."
        }
        else {
            return "Play"
        }
    }
    useEffect(() => {
        CansPlay()
        WitchPlay()
    }, [witchRLoading, cansLoading])

    return (
        <div className="home-page-bg">
            <div className="game-content">
                <h1 className="game-heading">SPRING FEST Games</h1>
                <div className="games-div">
                    <div className="game-box game1">
                        <NavLink to="/leaderboard/witch">
                            <button disabled={witchRLoading}>{WitchPlay()}</button>
                        </NavLink>
                    </div>
                    <h2 className="game-title">WITCH RUNNER</h2>
                    <div className="game-box game2">
                        <NavLink to="/leaderboard/cans">
                            <button disabled={cansLoading}>{CansPlay()}</button>
                        </NavLink>
                    </div>
                    <h2 className="game-title">KNOCK THE CANS</h2>
                </div>
            </div>
        </div>
    );
}

export default Home;