import { React, useContext } from 'react'
import { Link } from "react-router-dom";
import { ProfileContext } from "../usecontext/profile_provider";
import './Homepage.css'
import { useState } from 'react';
import toast from 'react-hot-toast';
import ilu_blackimg from "./Assests/ilu_black.webp"
import ilu_whiteimg from "./Assests/ilu_white.webp"
import axios from 'axios';
import ReactModal from "react-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ReactGA from 'react-ga';
import * as Yup from 'yup';
import { useMediaQuery } from "react-responsive";

const validationSchema = Yup.object().shape({
  check_in: Yup.string()
    .required('Check-in date is required'),
  check_out: Yup.string()
    .required('Check-out date is required')
    .test('is-after-check-in', 'Check-out date must be after check-in date', function (value) {
      const { check_in } = this.parent;
      return check_in && value >= check_in;
    }),
  emergency_number: Yup.string()
    .required('Emergency number is required')
    .matches(/^\d+$/, 'Emergency number must be only digits')
    .min(10, 'Emergency number must be of 10 digits')
    .max(10, 'Emergency number can be of 10 digits'),
});

export default function Homepage() {
  const { userData, isLoggedIn ,homepay} = useContext(ProfileContext);
  const [loading, setLoading] = useState(false);
  const [singlepaymentmodal, setSinglepaymentmodal] = useState(false);
  const is600 = useMediaQuery({ query: "(min-width: 600px)" });

  const singlePayment = async (values) => {
    const payload = {
      token: userData.token,
      check_in: Date(values.check_in),
      check_out: Date(values.check_out),
      emergency_number: values.emergency_number,
      type_of_acco: "Common",
      type_of_payment: "Individual"
    }
    setLoading(true);
    try {
      const url = `https://springfest.in/?token=${userData.token}&phoneNumber=${values.emergency_number}&type=Individual&origin=1`;
      window.open(url, '_blank');
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  ReactGA.initialize("G-23Q6ZTSW0Q");
  ReactGA.send({ hitType: "pageview", page: "/", title: "Welcome to SF 2025 Subheads" });

  return (
    <>
      <div className='homepage-container' style={{ overflow: 'hidden' }}>
        <a href='https://springfest.in/' rel="noreferrer" className="sflogo">.</a>
        <a href='https://www.iitkgp.ac.in/' target='_blank' rel="noreferrer" className="iitkgplogo">.</a>
        <div className="homepage-ilu-container">
          <img src={is600? ilu_whiteimg : ilu_blackimg} alt="" className="homepage-ilu-img" />
          {!isLoggedIn ?
            <Link to='/signin'><button className="sparkles"><span className='text_button'>LOG IN</span></button> </Link>
            :
            <div className='dash-logout'><Link to='/dashboard'><button className="sparkles homepage-buttons"><span className='text_button'>DASHBOARD</span></button></Link>
            </div>}
          {
           (isLoggedIn)&&<button className="sparkles2 homepage-buttons" id='pay-now-button' onClick={() => setSinglepaymentmodal(true)} disabled={((homepay === 1))}><span className='text_button'>  {((homepay === 0)) ?<div>PAY NOW</div>:<div>PAID</div>}</span></button>
          }
        </div>
        <div className="homepage-socials-container">
          <a href='https://x.com/springfest_kgp' target="_blank" rel="noopener noreferrer" className="homepage-socials" id='homepage-twitter-icon'>.</a>
          <a href='https://www.facebook.com/springfest.iitkgp' target="_blank" rel="noopener noreferrer" className="homepage-socials" id='homepage-fb-icon'>.</a>
          <a href='https://www.instagram.com/iitkgp.springfest' target="_blank" rel="noopener noreferrer" className="homepage-socials" id='homepage-insta-icon'>.</a>
          <a href='https://www.youtube.com/c/SpringFestForever' target="_blank" rel="noopener noreferrer" className="homepage-socials" id='homepage-yt-icon'>.</a>
          <a href='https://www.linkedin.com/company/spring-fest/posts/?feedView=all' target="_blank" rel="noopener noreferrer" className="homepage-socials" id='homepage-linkedin-icon'>.</a>
        </div>
      </div>
      <ReactModal
        isOpen={singlepaymentmodal}
        onRequestClose={() => {
          setSinglepaymentmodal(false);
        }}
        className="contingent_payment"
      >
        <button
          className="add-mem-closebutton"
          onClick={() => {
            setSinglepaymentmodal(false);
          }}
        >
          &times;
        </button>
        <h1 className="contingent-head">PAYMENT</h1>

        <Formik
          initialValues={{ check_in: "", check_out: "", emergency_number: "" }}
          onSubmit={singlePayment}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <div className="cont-leader-modal-cont-details cont-pay">
                <div className="input-field">
                  <div className="contingent-payment-input-error">
                    <Field as="select" name="check_in">
                      <option >Check-in</option>
                      <option >22-01-2025</option>
                      <option >23-01-2025</option>
                      <option >24-01-2025</option>
                      <option >25-01-2025</option>
                      <option >26-01-2025</option>
                      <option >27-01-2025</option>
                      <option >28-01-2025</option>
                    </Field>
                    <ErrorMessage name="check_in" component="div" className="contingent-payment-error" />
                  </div>
                </div>
                <div className="input-field">
                  <div className="contingent-payment-input-error">
                    <Field as="select" name="check_out">
                      <option >Check-out</option>
                      <option >22-01-2025</option>
                      <option >23-01-2025</option>
                      <option >24-01-2025</option>
                      <option >25-01-2025</option>
                      <option >26-01-2025</option>
                      <option >27-01-2025</option>
                      <option >28-01-2025</option>
                    </Field>
                    <ErrorMessage name="check_out" component="div" className="contingent-payment-error" />
                  </div>
                </div>
                <div className="input-field">
                  <div className="contingent-payment-input-error">
                    <Field type="tel" name="emergency_number" placeholder="Mobile No." className="mobile-cont" maxLength='10' />
                    <ErrorMessage name="emergency_number" component="div" className="contingent-payment-error" />
                  </div>
                </div>
              </div>
              <div className="cont-submit-button cont-payment-button">
                {loading ? (<button type="submit" className="contingent-leader-modal-paymentbutton" disabled={isSubmitting}>
                  Initiating....
                </button>) : (<button type="submit" className="contingent-leader-modal-paymentbutton" disabled={isSubmitting}>
                  Make Payment
                </button>)}
              </div>
            </Form>
          )}
        </Formik>
      </ReactModal>
    </>
  )
}