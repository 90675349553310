import { motion } from "framer-motion";
import Backdrop from "./AcBackdrop";
import ScrollBox from "./acscrollbox";
import scroll from "./Assets/Scroll3.webp"


const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};


const Modal = ({ handleClose, heading, content }) => {


  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="modal orange-gradient"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {/* <div className="acctop"></div> */}
        <div className="accmid acmodtilt">
          <img className="acbigscroll" src={scroll} alt="" />
          <div className="accontcontainer accont-2">
            <ScrollBox>
              {heading && <div><h2 className="acard-head">{heading}</h2></div>}
              <p className="acard-des">
                {content.split('\n').map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
                {/* {content} */}
              </p>
            </ScrollBox>
          </div>
        </div>
        {/* <div className="accbot"></div> */}

        {/* <button onClick={handleClose}>Close</button> */}
      </motion.div>
    </Backdrop>
  );
};


export default Modal;