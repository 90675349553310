import { React, useContext } from 'react'
import './phonenav.css'
import accicon from '../Assets/accicon.webp'
import acciconact from '../Assets/newcimages/accicon-act.png'
import eventsicon from '../Assets/eventsicon.webp'
import eventsiconact from '../Assets/newcimages/eventsicon-act.png'
import homeicon from '../Assets/homeicon.webp'
import homeiconact from '../Assets/newcimages/homeicon-act.png' //
import merchicon from '../Assets/merchicon.webp'
import merchiconact from '../Assets/newcimages/merchicon-act.png'
import sponsicon from '../Assets/sponsicon.png'
import faqicon from '../Assets/faqicon.png'
import faqiconact from '../Assets/newcimages/faqicon-act.png'
import teamsicon from '../Assets/teamsicon.png'
import aftermovieicon from '../Assets/aftermovieicon.png'
import aftermovieiconact from '../Assets/newcimages/aftermovieicon-act.png'
import gamesicon from '../Assets/gamesicon.png'
import gamesiconact from '../Assets/newcimages/gamesicon-act.png'
import galleryicon from '../Assets/galleryicon.png'
import galleryiconact from '../Assets/newcimages/galleryicon-act.png'
import moreicon from '../Assets/moreicon.webp'
import orbicon from '../Assets/magical_balll.webp'
import previcon from '../Assets/prev.webp'
import { NavLink, useLocation } from 'react-router-dom'
import { useState } from 'react'
import { useMediaQuery } from "react-responsive";
import { ProfileContext } from "../../usecontext/profile_provider";
import ReactGA from 'react-ga';

export default function PhoneNav() {
  const location = useLocation();
  const { isLoggedIn } = useContext(ProfileContext);
  const [backCheckforTop, setbackCheckforTop] = useState(true);
  const [backCheckforBottom, setbackCheckforBottom] = useState(false);
  const [pressedMore, setPressedMore] = useState(false)
  const [pressedPrev, setPressedPrev] = useState(false)
  const [checkOverlayState, setCheckOverlayState] = useState(false)
  const [disableBtn, setDisableBtn] = useState(false)
  const [showtextOverlay, setShowtextOverlay] = useState(true)
  const firstHalfIconsArr = ['/', '/accommodation', '/accommodation-rules', '/accommodation-faq', '/accommodation-map', '/events', '/merchandise', '/signin', '/signup', '/dashboard', '/events/subevents/Music', '/events/subevents/Dance', '/events/subevents/Dramatics', '/events/subevents/Literary', '/events/subevents/Film%20Fest', '/events/subevents/Quiz', '/events/subevents/Fine%20Arts', '/events/subevents/Humor%20Fest', '/events/subevents/Fashion', '/events/subevents/Fine%20Arts', '/events/subevents/Culinary%20Arts', '/contingent', '/cart', '/forgot-password','/schedule']
  const secondHalfIconsArr = ['/faqs', '/aftermovie', '/games', '/gallery', '/leaderboard/witch', '/leaderboard/cans']
  const ismin405 = useMediaQuery({ query: "(min-width: 405px)" });
  const ismax425 = useMediaQuery({ query: "(max-width: 425px)" });
  const ishmax700 = useMediaQuery({ query: "(max-height: 750px)" });
  const ishmin720 = useMediaQuery({ query: "(min-height: 720px)" });

  ReactGA.initialize("G-23Q6ZTSW0Q");
  ReactGA.send({ hitType: "pageview", page: "*", title: "Best part of the website Subheads" });

  const showNavbarIcons = () => {
    if (backCheckforTop) {

      let ItemContainer = document.getElementsByClassName('phone-nav-items-container')[0]
      ItemContainer.style.transition = 'transform 0.6s linear'
      if (ismin405 && ishmax700 && ishmin720 && ismax425) {
        ItemContainer.style.transform = `rotate(180deg) translateY(-9vh)`
      }
      else {
        ItemContainer.style.transform = `rotate(180deg) translateY(-6vh)`
      }
      // document.getElementsByClassName('phone-nav-orb')[0].classList.remove('phone-nav-orb-rotation')
      setTimeout(() => {
        document.getElementsByClassName('phone-nav-container')[0].style.background = 'linear-gradient(to top, #3C0D3B 25%,transparent 100%)'
      }, 400);
      document.getElementsByClassName('phone-nav-container')[0].style.zIndex = '100'
      let phoneNavOrbContainer = document.getElementsByClassName("phone-nav-orb-icon-container")[0]
      phoneNavOrbContainer.style.transition = 'transform 0.6s linear'
      phoneNavOrbContainer.style.transform = `rotate(180deg)`

      // translateY(${translateTopOrb()})

      let firstHalfNavbarItems = document.getElementsByClassName('phone-nav-items-firsthalf')
      Array.from(firstHalfNavbarItems).forEach((item) => {
        item.style.display = 'flex'
      })

      let moreNavbarItems = document.getElementsByClassName('phone-nav-items-secondhalf')
      Array.from(moreNavbarItems).forEach((item) => {
        setTimeout(() => {
          item.style.display = 'none'
        }, 575);
      })

      setbackCheckforTop(false)
    }
    else if (backCheckforBottom) {
      let ItemContainer = document.getElementsByClassName('phone-nav-items-container')[0]
      ItemContainer.style.transition = 'transform 0.6s linear'
      if (ismin405 && ishmax700 && ishmin720 && ismax425) {
        ItemContainer.style.transform = `rotate(0deg) translateY(9vh)`
      }
      else {
        ItemContainer.style.transform = `rotate(0deg) translateY(6vh)`
      }
      // document.getElementsByClassName('phone-nav-orb')[0].classList.remove('phone-nav-orb-rotation')
      setTimeout(() => {
        document.getElementsByClassName('phone-nav-container')[0].style.background = 'linear-gradient(to top, #3C0D3B 25%, transparent 100%)'
      }, 350);
      document.getElementsByClassName('phone-nav-container')[0].style.zIndex = '100'
      let phoneNavOrbContainer = document.getElementsByClassName("phone-nav-orb-icon-container")[0]
      phoneNavOrbContainer.style.transition = 'transform 0.6s linear'
      phoneNavOrbContainer.style.transform = `rotate(0deg)`

      // translateY(-${translateTopOrb()})

      let firstHalfNavbarItems = document.getElementsByClassName('phone-nav-items-firsthalf')
      Array.from(firstHalfNavbarItems).forEach((item) => {
        item.style.display = 'none'
      })

      let moreNavbarItems = document.getElementsByClassName('phone-nav-items-secondhalf')
      Array.from(moreNavbarItems).forEach((item) => {
        item.style.display = 'flex'
      })
      setbackCheckforBottom(false)
    }
    else if ((!backCheckforBottom && secondHalfIconsArr.includes(location.pathname)) || (pressedMore)) {
      closeNavbarforBottom()
      setPressedMore(false)
    }
    else if ((!backCheckforTop && firstHalfIconsArr.includes(location.pathname)) || (pressedPrev)) {
      closeNavbarforTop()
      setPressedPrev(false)
    }
  }

  const showNavOtherIcons = () => {
    let ItemContainer = document.getElementsByClassName('phone-nav-items-container')[0]
    let moreNavbarItems = document.getElementsByClassName('phone-nav-items')
    Array.from(moreNavbarItems).forEach((item) => {
      item.style.display = 'flex'
    })
    ItemContainer.style.transition = 'transform 0.6s linear'
    if (ismin405 && ishmax700 && ishmin720 && ismax425) {
      ItemContainer.style.transform = 'rotate(0deg) translateY(9vh)'
    }
    else {
      ItemContainer.style.transform = 'rotate(0deg) translateY(6vh)'
    }
    let phoneNavOrbContainer = document.getElementsByClassName("phone-nav-orb-icon-container")[0]
    phoneNavOrbContainer.style.transition = 'transform 0.8s linear'
    phoneNavOrbContainer.style.transform = `rotate(0deg)`

    // translateY(-${translateTopOrb()})

    let firsthalfNavbarItems = document.getElementsByClassName('phone-nav-items-firsthalf')
    Array.from(firsthalfNavbarItems).forEach((item) => {
      setTimeout(() => {
        item.style.display = 'none'
      }, 500);
    })
    setTimeout(() => {
      setPressedMore(false)
    }, 3000);
  }

  const showNavbarFirstHalfIcons = () => {
    let ItemContainer = document.getElementsByClassName('phone-nav-items-container')[0]
    ItemContainer.style.transition = 'transform 0.6s linear'
    if (ismin405 && ishmax700 && ishmin720 && ismax425) {
      ItemContainer.style.transform = 'rotate(180deg) translateY(-9vh)'
    }
    else {
      ItemContainer.style.transform = 'rotate(180deg) translateY(-6vh)'
    }
    let phoneNavOrbContainer = document.getElementsByClassName("phone-nav-orb-icon-container")[0]
    phoneNavOrbContainer.style.transition = 'transform 0.8s linear'
    phoneNavOrbContainer.style.transform = `rotate(180deg)`

    // translateY(${translateTopOrb()})

    let firstHalfNavbarItems = document.getElementsByClassName('phone-nav-items-firsthalf')
    Array.from(firstHalfNavbarItems).forEach((item) => {
      item.style.display = 'flex'
    })

    let moreNavbarItems = document.getElementsByClassName('phone-nav-items-secondhalf')
    Array.from(moreNavbarItems).forEach((item) => {
      setTimeout(() => {
        item.style.display = 'none'
      }, 500);
    })
    setTimeout(() => {
      setPressedPrev(false)
    }, 3000);
  }

  const closeNavbarforBottom = () => {
    let ItemContainer = document.getElementsByClassName('phone-nav-items-container')[0]
    ItemContainer.style.transition = 'transform 0.6s linear'
    ItemContainer.style.transform = `rotate(180deg)`
    setbackCheckforBottom(true)
    // document.getElementsByClassName('phone-nav-orb')[0].classList.add('phone-nav-orb-rotation')
    // document.getElementsByClassName("phone-nav-orb-icon-container")[0].style.bottom = '-16vb'
    document.getElementsByClassName('phone-nav-container')[0].style.background = ''
    let phoneNavOrbContainer = document.getElementsByClassName("phone-nav-orb-icon-container")[0]
    phoneNavOrbContainer.style.transition = 'transform 0.6s linear'
    phoneNavOrbContainer.style.transform = 'rotate(180deg)'

    // translateY(-2vh)

    let moreNavbarItems = document.getElementsByClassName('phone-nav-items-secondhalf')
    setCheckOverlayState(false)
    setTimeout(() => {
      Array.from(moreNavbarItems).forEach((item) => {
        item.style.display = 'none'
      })
      document.getElementsByClassName('phone-nav-container')[0].style.zIndex = '-1'
    }, 575);
  }

  const closeNavbarforTop = () => {
    let ItemContainer = document.getElementsByClassName('phone-nav-items-container')[0]
    ItemContainer.style.transition = 'transform 0.6s linear'
    ItemContainer.style.transform = `rotate(0deg)`
    setbackCheckforTop(true)
    // document.getElementsByClassName('phone-nav-orb')[0].classList.add('phone-nav-orb-rotation')
    // document.getElementsByClassName("phone-nav-orb-icon-container")[0].style.bottom = '-16vb'
    document.getElementsByClassName('phone-nav-container')[0].style.background = ''
    let phoneNavOrbContainer = document.getElementsByClassName("phone-nav-orb-icon-container")[0]
    phoneNavOrbContainer.style.transition = 'transform 0.6s linear'
    phoneNavOrbContainer.style.transform = 'rotate(0deg)'

    //  translateY(2vh)

    let firstHalfNavbarItems = document.getElementsByClassName('phone-nav-items-firsthalf')
    setCheckOverlayState(false)
    setTimeout(() => {
      Array.from(firstHalfNavbarItems).forEach((item) => {
        item.style.display = 'none'
      })
      document.getElementsByClassName('phone-nav-container')[0].style.zIndex = '-1'
    }, 575);
  }

  const setOverlay = () => {
    setCheckOverlayState(true)
  }

  const overlayClassName = () => {
    return checkOverlayState ? "phone-nav-overlay-on" : "phone-nav-overlay-off"
  }

  return (
    <>
      <div className={overlayClassName()} onClick={() => {
        showNavbarIcons()
      }}>
      </div>
      {showtextOverlay && <div className="initial-circular-overlay">
        <svg width="300" height="150" viewBox="0 0 300 150" className='navbar-svg'>
          <path id="semi-circle" d="M 50,150 A 100,100 0 0,1 250,150" fill="none" stroke="none"></path>
          <text fontSize="18" fill="black" textAnchor="start" dy='-3vb'>
            <textPath href="#semi-circle" startOffset="6.5%">Tap Here to Unmask the Magic</textPath>
          </text>
        </svg>
      </div>}
      <div className='phone-nav-orb-icon-container'>
        <button className="phone-nav-orb phone-nav-orb-rotation" onClick={() => {
          setDisableBtn(true)
          showNavbarIcons()
          // setArrowUp(false)
          setTimeout(() => {
            setShowtextOverlay(false)
          }, 100);
          setTimeout(() => {
            setDisableBtn(false)
            setOverlay()
          }, 700);
        }} disabled={disableBtn}>
          <img src={orbicon} alt="" />
        </button>
      </div>
      <div className="phone-nav-container" style={{ overflow: 'hidden' }}>
        <div className="phone-nav-items-container">
          <ul>
            <NavLink to='/' className="phone-nav-items phone-nav-items-firsthalf" id='phone-nav-homebutton' onClick={closeNavbarforTop}>
              <img src={location.pathname === '/' ? homeiconact : homeicon} alt="" id={location.pathname === '/' ? "active-img-phone" : ""} />
              <p>Home</p>
            </NavLink>
            <NavLink to={isLoggedIn ? "/contingent" : "/accommodation"} className="phone-nav-items phone-nav-items-firsthalf" id='phone-nav-accbutton' onClick={closeNavbarforTop}>
              <img src={['/accommodation', '/accommodation-rules', '/accommodation-faq', '/accommodation-map', "/contingent"].includes(location.pathname) ? acciconact : accicon} alt="" id={['/accommodation', '/accommodation-rules', '/accommodation-faq', '/accommodation-map', "/contingent"].includes(location.pathname) ? 'active-img-phone' : ""} />
              <p>Accommodation</p>
            </NavLink>
            <NavLink to='/events' className="phone-nav-items phone-nav-items-firsthalf" id='phone-nav-eventsbutton' onClick={closeNavbarforTop}>
              <img src={(location.pathname).split("/").slice(0, 3).join("/") === '/events/subevents' || location.pathname === '/events' ? eventsiconact : eventsicon} alt="" id={(location.pathname).split("/").slice(0, 3).join("/") === '/events/subevents' || location.pathname === '/events' ? 'active-img-phone' : ""} />
              <p>Events</p>
            </NavLink>
            <NavLink to='/merchandise' className="phone-nav-items phone-nav-items-firsthalf" id='phone-nav-merchbutton' onClick={closeNavbarforTop}>
              <img src={location.pathname === '/merchandise' ? merchiconact : merchicon} alt="" id={location.pathname === '/merchandise' ? "active-img-phone" : ''} />
              <p>Merch</p>
            </NavLink>
            <NavLink to='https://sponsors.springfest.in/' className="phone-nav-items phone-nav-items-firsthalf" id='phone-nav-sponsbutton' onClick={closeNavbarforTop}>
              <img src={sponsicon} alt="" />
              <p>Sponsors</p>
            </NavLink>
            <button className="phone-nav-items phone-nav-items-firsthalf" id='phone-nav-morebutton1'
              onClick={() => {
                setPressedMore(true)
                showNavOtherIcons()
                setCheckOverlayState(false)
                setTimeout(() => {
                  setOverlay()
                }, 600);
              }}>
              <img src={moreicon} alt="" />
              <p>More</p>
            </button>
            <NavLink to='/faqs' className="phone-nav-items phone-nav-items-secondhalf" id='phone-nav-faqsbutton' onClick={closeNavbarforBottom}>
              <img src={location.pathname === '/faqs' ? faqiconact : faqicon} alt="" id={location.pathname === '/faqs' ? 'active-img-phone' : ""} />
              <p className='phone-nav-some-ptags'>FAQs</p>
            </NavLink>
            <NavLink to='https://teams.springfest.in/' className="phone-nav-items phone-nav-items-secondhalf" id='phone-nav-teamsbutton' onClick={closeNavbarforBottom}>
              <img src={teamsicon} alt="" />
              <p id='our-team-p-tag'>Our Team</p>
            </NavLink>
            <NavLink to='/aftermovie' className="phone-nav-items phone-nav-items-secondhalf" id='phone-nav-aftermoviebutton' onClick={closeNavbarforBottom}>
              <img src={location.pathname === '/aftermovie' ? aftermovieiconact : aftermovieicon} alt="" id={location.pathname === '/aftermovie' ? 'active-img-phone' : ''} />
              <p className='phone-nav-some-ptags' id='phone-nav-aftermovie-icon'>After Movie</p>
            </NavLink>
            <NavLink to='/games' className="phone-nav-items phone-nav-items-secondhalf" id='phone-nav-gamesbutton' onClick={closeNavbarforBottom}>
              <img src={location.pathname === '/games' ? gamesiconact : gamesicon} alt="" id={location.pathname === '/games' ? 'active-img-phone' : ""} />
              <p>Games</p>
            </NavLink>
            <NavLink to='/gallery' className="phone-nav-items phone-nav-items-secondhalf" id='phone-nav-gallerybutton' onClick={closeNavbarforBottom}>
              <img src={location.pathname === '/gallery' ? galleryiconact : galleryicon} alt="" id={location.pathname === '/gallery' ? 'active-img-phone' : ""} />
              <p className='phone-nav-some-ptags'>Gallery</p>
            </NavLink>
            <button className="phone-nav-items phone-nav-items-secondhalf" id='phone-nav-morebutton2'
              onClick={() => {
                setPressedPrev(true)
                showNavbarFirstHalfIcons()
                setCheckOverlayState(false)
                setTimeout(() => {
                  setOverlay()
                }, 600);
              }}>
              <img src={previcon} alt="" />
              <p>Previous</p>
            </button>
          </ul>
        </div>
      </div>
    </>
  )
}


// const translateTopOrb = () => {
//   if (ishmin850) {
//     return '5.5vb'
//   }
//   else if (ishmin800) {
//     return '5vb'
//   }
//   else if (ishmin750) {
//     return '3.5vb'
//   }
//   else if (ishmin700) {
//     return '3.5vb'
//   }
//   else if (ishmin650) {
//     return '3vb'
//   }
//   else {
//     return '3vb'
//   }
// }


// const translateIcons = () => {
//   if (ishmin850) {
//     return '5.5vb'
//   }
//   else if (ishmin800) {
//     return '5vb'
//   }
//   else if (ishmin750) {
//     return '3.5vb'
//   }
//   else if (ishmin700) {
//     return '3.5vb'
//   }
//   else if (ishmin650) {
//     return '3vb'
//   }
//   else {
//     return '3vb'
//   }
// }