import React from 'react'
import './Preloader.css'
import ilu from '../Homepage/Assests/ilu_white.webp';

export default function Preloader(pressEnter, showPreloader) {



    return (
        <div className="new-preloader-container">
            <img src={ilu} alt="" className='preloading-ilu-img' />
            <div className="press-enter-text">
                <h1 className='preloader-heading'>LOADING
                    <span className="loading-dots">.</span>
                    <span className="loading-dots">.</span>
                    <span className="loading-dots">.</span></h1>
            </div>
            {/* <div className="progress">
                <div className="progress-value"></div>
            </div> */}
            {/* {pressEnter && <>
                <div className="press-enter-text">
                    <h1>Press Enter to continue</h1>
                </div>
                <div>
                    <button className="enter-button" onClick={()=>{
                        document.getElementsByClassName('new-preloader-container')[0].style.display = 'none';
                        document.getElementsByClassName('new-preloader-container')[0].style.zIndex = '-1';
                    }}>ENTER</button>
                </div>
            </>} */}
        </div>
    )
}

// <div className="preloader-container">
//     <div className='preloader-body'>
//         <div className="circle1">
//             <div className="circle2">
//                 <div className="circle3">
//                     <div className="circle4"></div>
//                 </div>
//             </div>
//         </div>
//     </div>
// </div>