import axios from "axios";
import { createContext, useState,useEffect, memo } from "react";

const ProfileContext = createContext();

const ProfileProvider=memo(({children}) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState( JSON.parse(localStorage.getItem("userData")));
    const [quantity, setQuantity] = useState(1);
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem("cart")) || []);
    const [events,setEvents]= useState()
    const [homepay,setHomepay]=useState();
    // Fetching all event details
    const getUsers = async () => {
      const toSend = { token: userData?.token };
  
      try {
          const response = await axios.post('https://masterapi.springfest.in/api/user/getUser', toSend);
          if (response.data.code === 0) {
              setHomepay(response.data?.data?.payment_status);
          } else {
              console.log(response.data);
          }
      } catch (err) {
          console.error(err);
      }
  };
    const fetchEventDetails = async () => {
      try {
        const response = await axios.get("https://masterapi.springfest.in/api/event");
        if (response?.data?.data) {
          setEvents(response?.data?.data);
        }
      } catch (error) {
        console.error("Error fetching event details:", error);
      }
    }; 
    
    useEffect(()=>{
      getUsers();
    },[isLoggedIn])

    useEffect(() => {
     
      fetchEventDetails();
    }, []);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart,quantity]);

  const [selectedSize, setSelectedSize] = useState(null);
    useEffect(() => {
        if(userData){
            localStorage.setItem("userData", JSON.stringify(userData));
            setIsLoggedIn(true);
        }
        else{
            setIsLoggedIn(false);
        }
    }, [userData]);

    return(
        <ProfileContext.Provider value={{userData, setUserData, isLoggedIn, setIsLoggedIn,cart,
            setCart,
            selectedSize,
            setSelectedSize,
            quantity,
            setQuantity,events,setEvents,homepay,setHomepay}}>
            {children}
        </ProfileContext.Provider>
    );
});

export default ProfileProvider;
export {ProfileContext};
