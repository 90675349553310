import { createContext, useState,useEffect, memo } from "react";


const EventsContext = createContext();

const EventsProvider=memo(({children}) => {
    // const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [eventsContext, setEventsContext] = useState([]);

    return(
        <EventsContext.Provider value={{eventsContext, setEventsContext}}>
            {children}
        </EventsContext.Provider>
    );
});

export default EventsProvider;
export {EventsContext};