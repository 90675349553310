import React, { useState } from 'react';
import './Aftermovie.css';
import TVImage from './theatre.webp';
import ReactGA from 'react-ga';

const TVScreen = () => {

  const [loading, setLoading] = useState(true)
  const handleLoad = () => {
    setLoading(false);
  };

  ReactGA.initialize("G-23Q6ZTSW0Q");
  ReactGA.send({ hitType: "pageview", page: "/aftermovie", title: "You watched the SF-2023 Aftermovie Subheads" });

  return (
    <div className="aftermovie-container">
      <div className="tv-container">
        <img src={TVImage} alt="After Movie" className="tv-image" />
        <div className='vidcont'>
          {loading && <div className="loader"><p>The show will start soon...</p></div>}
          <iframe
            className="tv-video"
            src="https://www.youtube.com/embed/QfM0xUC0Xng"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="SF 23 Aftermovie"
            onLoad={handleLoad}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default TVScreen;