import React, { useEffect, useState, useRef } from "react";
import scroll from "./Assets/scrollbody.webp";
import ScrollBox from "./acscrollbox";
import { motion, AnimatePresence } from "framer-motion";
import Modal from "./AcModal";
import { useMediaQuery } from "react-responsive";
import leftscroll from "./Assets/scrolltop.webp";
import rightscroll from "./Assets/scrollbottom.webp";

const AccCards = (props) => {
    const is600 = useMediaQuery({ query: "(min-width: 600px)" });
    const is480 = useMediaQuery({ query: "(min-width: 480px)" });
    const [heading, setHeading] = useState("");
    const [des, setDes] = useState("");
    const [modalOpen, setModalOpen] = useState(false);

    const close = () => setModalOpen(false);
    const open = () => setModalOpen(true);

    function handleCardClick(accin) {
        setHeading(accin.heading);
        setDes(accin.content);
        console.log(`Hi ${accin.heading}!`);
        modalOpen ? close() : open()
    }

    const Info = props.info;

    // Store refs and visibility state for each card
    const cardRefs = useRef([]);
    const [visibilityStates, setVisibilityStates] = useState(
        Array(Info.length).fill(false)
    );

    useEffect(() => {
        // Initialize an IntersectionObserver for each card
        const observers = cardRefs.current.map((ref, index) => {
            if (!ref) return null;

            return new IntersectionObserver(
                ([entry]) => {
                    setVisibilityStates((prev) =>   
                        prev.map((visible, i) =>
                            i === index ? entry.isIntersecting : visible
                        )
                    );
                },
                { threshold: 0.5 } // Trigger when 10% of the element is visible
            );
        });

        // Start observing each card
        observers.forEach((observer, index) => {
            if (observer && cardRefs.current[index]) {
                observer.observe(cardRefs.current[index]);
            }
        });

        // Cleanup observers on unmount
        return () => {
            observers.forEach((observer, index) => {
                if (observer && cardRefs.current[index]) {
                    observer.unobserve(cardRefs.current[index]);
                }
            });
        };
    }, []);

    return (
        <div className="accontainer">
            {Info.map((accin, index) => (
                <div
                    key={index}
                    className={`tiltcard`}
                    ref={(el) => (cardRefs.current[index] = el)} // Assign ref for each card
                >
                    <motion.div
                    whileHover={{scale: 1.05}}
                        onClick={() => handleCardClick(accin)}
                        className="accmid"
                    >
                        <motion.img
                            initial={{
                                y:"19.85%" ,
                                scaleY: 0,
                                opacity: 1,
                                transformOrigin: "top",
                            }}
                            animate={
                                visibilityStates[index] && {
                                    scaleY: 1,
                                    opacity: 1,
                                }
                            }
                            transition={{
                                duration: 0.5,
                                ease: "linear",
                            }}
                            className="acfullscroll"
                            src={scroll}
                            alt=""
                        />
                                <motion.img
                                    initial={{
                                        y:0,
                                    }}
                                    animate={
                                        visibilityStates[index] && {
                                            y: 0,
                                        }
                                    }
                                    transition={{
                                        duration: 0.5,
                                        ease: "linear",
                                    }}
                                    className="acfirst"
                                    src={leftscroll}
                                />
                                <motion.img
                                    initial={{
                                        y:"100%",
                                    }}
                                    animate={
                                        visibilityStates[index] && {
                                            y: "603.73%",
                                        }
                                    }
                                    transition={{
                                        duration: 0.5,
                                        ease: "linear",
                                    }}
                                    className="acsec"
                                    src={rightscroll}
                                />
                        <motion.div
                            initial={{
                                opacity: 0,
                            }}
                            animate={
                                visibilityStates[index] && {
                                    opacity: 1,
                                }
                            }
                            transition={{
                                duration: 0.4,
                                delay: 0.6,
                            }}
                            className="accontcontainer"
                        >
                            <ScrollBox>
                                {accin.heading && (
                                    <div>
                                        <h2 className="acard-head">{accin.heading}</h2>
                                    </div>
                                )}
                                <p className="acard-des">
                                    {accin.content.split("\n").map((line, index) => (
                                        <span key={index}>
                                            {line}
                                            <br />
                                        </span>
                                    ))}
                                </p>
                            </ScrollBox>
                        </motion.div>
                    </motion.div>
                </div>
            ))}
            {is600 && (
                <AnimatePresence
                    initial={false}
                    mode="wait"
                    onExitComplete={() => null}
                >
                    {modalOpen && (
                        <Modal
                            modalOpen={modalOpen}
                            handleClose={close}
                            heading={heading}
                            content={des}
                        />
                    )}
                </AnimatePresence>
            )}
        </div>
    );
};

export default AccCards;
