import { NavLink, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./signup.css";
import cityData from "./city.json";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { Field, Formik, Form, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import Flatpickr from "react-flatpickr";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import ReactGA from 'react-ga';

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().min(8, "At least 8 characters").required("Required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
  name: Yup.string().required("Required"),
  college: Yup.string().required("Required"),
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, "Must be a valid 10-digit number")
    .required("Required"),
  gender: Yup.string().required("Required"),
  dob: Yup.date()
    .max(
      new Date(new Date().setFullYear(new Date().getFullYear() - 16)),
      "Must be at least 16 years old"
    )
    .required("Required"),
  yop: Yup.number().required("Required"),
  state: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  security_question: Yup.string().required("Required"),
  security_answer: Yup.string().required("Required"),
  captcha: Yup.string().required("Required"),
});

// const DateChangeEffect = () => {
//   const { values } = useFormikContext();
//   return null;
// };

const Signup = () => {
  const history = useNavigate();
  const [cities, setCities] = useState([])
  const [birthdate, setBirthDate] = useState("");
  const [loadingSignUp, setLoadingSignup] = useState(false)
  useEffect(() => {
    flatpickr(".date-field", {
      altInput: true,
      altFormat: "F j, Y",
      dateFormat: "Y-m-d",
    });
  }, []);

  useEffect(() => {
    // console.log(birthdate)
  }, [birthdate]);

  const handleSubmit = async (values) => {
    setLoadingSignup(true)
    const toSend = {
      name: values.name,
      password: values.password,
      gender: values.gender,
      email: values.email,
      dob: values.dob,
      mobile: values.mobile,
      yop: Number(values.yop),
      college: values.college,
      city: values.city,
      state: values.state,
      addr: "none",
      security_question: values.security_question,
      security_answer: values.security_answer,
      captcha: values.captcha,
    };

    try {
      const response = await axios.post(
        "https://masterapi.springfest.in/api/user/register_user",
        toSend
      );

      if (response.data.code === 0) {
        toast.success(response.data.message);
        setTimeout(() => {
          history("/signin");
          setLoadingSignup(false)
        }, 1500);
      } 
      else {
        toast.error(response.data.message);
        setLoadingSignup(false)
      }
    } catch(error) {
      toast.error("Please try again");
      setLoadingSignup(false)
    }
  };

  const handleCities = (e, setFieldValue) => {
    const selectedState = e.target.value;
    setFieldValue("state", selectedState);
    setFieldValue("city", "");
    const stateObj = cityData.states.find(
      (item) => item.state === selectedState
    );
    setCities(stateObj ? stateObj.districts : []);
  };
  ReactGA.initialize("G-23Q6ZTSW0Q");
  ReactGA.send({ hitType: "pageview", page: "/signup", title: "Sign up to continue Subheads" });

  return (
    <div className="signup_content authentication">
      <div className="signup-body">
        <Toaster />
        <h1 className="signup-heading">SIGN UP</h1>
        <div className="signup-form-cont">
          <Formik
            initialValues={{
              email: "",
              password: "",
              confirm_password: "",
              dob: "",
              gender: "",
              name: "",
              college: "",
              yop: "",
              mobile: "",
              city: "",
              state: "",
              security_question: "",
              security_answer: "",
              captcha: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <div className="heading">
                  <p> Personal Information</p>
                </div>
                <div className="signup-inputfield">
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name : "
                    autoComplete="off"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="error-message-signup"
                  />
                </div>
                <div className="signup-inputfield">
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email : "
                    autoComplete="off"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message-signup"
                  />
                </div>
                <div className="signup-inputfield">

                  <Field
                    type="text"
                    id="mobile"
                    name="mobile"
                    placeholder="Phone Number :"
                    maxLength="10"
                    autoComplete="off"
                  />
                  <ErrorMessage
                    name="mobile"
                    component="div"
                    className="error-message-signup"
                  />
                </div>

                <div className="signup-inputfield emptyfield">
                  <Flatpickr
                    id="dob"
                    className="form-control"
                    options={{
                      disableMobile: true,
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d",
                    }}
                    value={values.dob || ""}
                    placeholder="Date of Birth"
                    onChange={(selectedDates) => {
                      if (selectedDates.length > 0) {
                        setFieldValue("dob", selectedDates[0])
                      }
                    }}
                  />
                  <ErrorMessage
                    name="dob"
                    component="div"
                    className="error-message-signup"
                  />
                </div>
                <div className="signup-inputfield">
                  <Field as="select" name="gender" id="gender">
                    <option value="initial">Select your Gender : </option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </Field>
                  <ErrorMessage
                    name="gender"
                    component="div"
                    className="error-message-signup"
                  />
                </div>
                <div className="heading">
                  <p>College Information</p>
                </div>
                <div className="signup-inputfield">
                  <Field
                    type="text"
                    id="college"
                    name="college"
                    placeholder="College : "
                    autoComplete="off"
                  />
                  <ErrorMessage
                    name="college"
                    component="div"
                    className="error-message-signup"
                  />
                </div>
                <div className="signup-inputfield">
                  <Field as="select" name="yop" id="yop">
                    <option value="select year of passing">
                      Select year of passing :
                    </option>
                    <option>2025</option>
                    <option>2026</option>
                    <option>2027</option>
                    <option>2028</option>
                    <option>2029</option>
                  </Field>
                  <ErrorMessage
                    name="yop"
                    component="div"
                    className="error-message-signup"
                  />
                </div>
                <div className="signup-inputfield">
                  <Field
                    as="select"
                    name="state"
                    id="state"
                    onChange={(e) => handleCities(e, setFieldValue)}
                    required>
                    <option value="Select your State">Select your State</option>
                    {cityData?.states?.map((stateObj) => (
                      <option key={stateObj.state} value={stateObj.state}>
                        {stateObj.state}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="state"
                    component="div"
                    className="error-message-signup"
                  />
                </div>
                <div className="signup-inputfield">
                  <Field
                    as="select"
                    name="city"
                    id="city"
                    disabled={!cities.length}>
                    <option value="Select your City">Select your City</option>
                    {cities.map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="city"
                    component="div"
                    className="error-message-signup"
                  />
                </div>
                <div className="heading">
                  <p>Account Credentials</p>
                </div>
                <div className="signup-inputfield">
                  <Field
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password : "
                    minLength="8"
                    autoComplete="off"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error-message-signup"
                  />
                </div>
                <div className="signup-inputfield">
                  <Field
                    type="password"
                    id="confirm_password"
                    name="confirm_password"
                    placeholder="Confirm Password :"
                    autoComplete="off"
                  />
                  <ErrorMessage
                    name="confirm_password"
                    component="div"
                    className="error-message-signup"
                  />
                </div>
                <div className="signup-inputfield">
                  <Field
                    type="text"
                    id="security_question"
                    name="security_question"
                    placeholder="Security Question :"
                    autoComplete="off"
                  />
                  <ErrorMessage
                    name="security_question"
                    component="div"
                    className="error-message-signup"
                  />
                </div>
                <div className="signup-inputfield">
                  <Field
                    type="text"
                    id="security_answer"
                    name="security_answer"
                    placeholder="Security Answer :"
                    autoComplete="off"
                  />
                  <ErrorMessage
                    name="security_answer"
                    component="div"
                    className="error-message-signup"
                  />
                </div>
                <div className="signup-inputfield captcha">
                  <ReCAPTCHA
                    sitekey="6Lcz4VQqAAAAAEUF81y6kH03077Uq2dxB13xgOUT"
                    theme="dark"
                    size="normal"
                    onChange={(value) => setFieldValue("captcha", value)}
                  />
                  <ErrorMessage
                    name="captcha"
                    component="div"
                    className="error-message-signup"
                  />
                </div>
                <div className="sup-btn-div"><button
                  className="signup-button"
                  type="submit"
                  onSubmit={() =>
                    handleSubmit(values)}
                  disabled={loadingSignUp}>
                  {loadingSignUp ? "Signing Up..." : "Sign Up"}
                </button></div>
              </Form>
            )}
          </Formik>
          <p className="new-user">
            Already have an account?
            <NavLink style={{ color: "blue" }} to="/signin">
              Sign In
            </NavLink>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
