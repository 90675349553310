import { createContext, useState, useEffect, memo } from "react";
import { toast, Toaster} from "react-hot-toast";

const LogoutContext = createContext();

const LogoutProvider = memo(({ children }) => {
    const [checkLogout, setCheckLogout] = useState(false);
    useEffect(() => {
        if (checkLogout) {
            setTimeout(() => {
                toast.success("Logged out successfully");
            }, 1000);
            setTimeout(() => {
                setCheckLogout(false);
            }, 10000);
        }
    }, [checkLogout]);

    return (
        <LogoutContext.Provider value={{ checkLogout, setCheckLogout}}>
            <Toaster />
            {children}
        </LogoutContext.Provider>
    );
});

export default LogoutProvider;
export { LogoutContext };
