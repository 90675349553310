import Navbar from "./AcNavbar"
import { useState, useEffect } from "react"
import VanillaTilt from "vanilla-tilt";
import "./acc-cards.css"
import AccCards from "./acccardlist";
// import board from "./NoticeBoard.jpg"

const Info=[
    {
        heading: "",
        content: "We care about our campus as much as you care about enjoying it to the fullest in Spring Fest. Let’s follow these simple rules to make this SF a memorable one and not a dreadful one just because of something you did wrong."
    },
    {
        heading: "",
        content: "At a time, the payment of maximum 1 participants can be done with the same transaction ID. The participant/team is requested to note down their transaction ID & accommodation ID and keep it with them for their convenience."
    },
    {
        heading: "",
        content: "Participants from a college may have a maximum of 2 professors/faculty with their institute ID card. They will be charged and provided accommodation only on showing the ID card. The organising team will try their best to provide separate rooms to the faculty but it is not promised."
    },
    {
        heading: "",
        content: " The accommodation charges and other necessary details regarding the payment will be available under the tab Accommodation Charges."
    },
    {
        heading: "",
        content: "Accommodation tent will remain closed from 6 pm to 11 pm throughout the Starnights from 24th to 26th January 2025. Any call/request for accommodation at that time will not be entertained. But in case you do visit the campus at this time, you may sit at Old Badminton Court, Gymkhana"
    },
    {
        heading: "",
        content: " Accommodation will be provided to the participants from the check-in time to the check-out time as mentioned on the Accommodation Portal/Participant Slip. If the participant/team stays for more than the check-out time and refuses to vacate his allotted space, a fine of Rs. 500 per person will be levied on the participant/team."
    },
    {
        heading: "",
        content: "Students are not permitted to eat in the hall mess. However, they are welcome to dine at hall canteens, food stalls, and other eateries available on campus."
    },
    {
        heading: "",
        content: "Participants may be required to share mattresses as per the allotment guidelines."
    },
    {
        heading: "",
        content: "Blankets can be obtained from the Accommodation tent with a security deposit of ₹100 per blanket. The deposit will be refunded upon return of the blanket in good condition."
    },
    {
        heading: "",
        content: "Blankets should be submitted at the Accommodation tent while leaving the campus else the security deposit will not be returned. Refundable amount would be refunded only when the Accommodation slip is produced at the Finance tent along with the blankets on 26th and 29th January from 4pm-6:30pm & 11pm onwards"
    },
    {
        heading: "",
        content: "Blankets should not be exchanged. Additionally, blankets and mattresses are not allowed to be taken outside the hall. If found misplaced or taken outside, the participant will incur a fine of ₹500 per mattress. Please adhere to these guidelines to avoid penalties."
    },
    {
        heading: "",
        content: "Participants found littering anywhere within the Hall Premises shall be penalised as accordingly by Hall Authorities."
    },
    {
        heading: "",
        content: "Smoking, alcoholism, or any form of substance abuse is strictly prohibited inside the hostel premises. The organizing committee reserves the right to fine or deport any participant or team found violating these rules. Such actions will be carried out at the full discretion of the Organizing Committee. Additionally, no refund will be issued in the case of deportation. Please ensure adherence to these rules to avoid any consequences."
    },
    {
        heading: "",
        content: "Frisking of your belongings for security reasons may be conducted at any time if there is suspicion of possession of alcohol, drugs, or any prohibited substances. This will be done by security officials in your presence, and you are kindly requested to cooperate with them during the process."
    },
    {
        heading: "",
        content: " Any form of molestation, theft, harm, or similar offenses will be treated as severe criminal offenses. The case will be immediately reported to the police station, and your college administration will also be informed. Such actions may result in suspension from your college or criminal charges."
    },
    {
        heading: "",
        content: "In case of any complaint made against the behaviour of the participant which causes any discomfort to the hostel boarders or other participants, the Organizing Committee’s decision would be final and binding."
    },
    {
        heading: "",
        content: " It is the responsibility of the participants to maintain the hostel property. In case of any damage to the hostel property, participants will be fined or deported based on the extent of the damage, as decided by the organizing committee."
    },
    {
        heading: "",
        content: "Additionally, please ensure that you carry your institute ID card, institute medical book, and library card with you at all times to avoid any inconvenience."
    },
    {
        heading: "",
        content: " All rooms have to be vacated by 28th January 2025 strictly by 2pm."
    },
    // {
    //     heading: "",
    //     content: "It shall be the responsibility of the participants to maintain hostel property. In case of any damage to the property of the hostel, the participants shall be fined/deported according to the extent of the damage by decision of the organising committee."
    // },
    // {
    //     heading: "",
    //     content: "Please carry your institute ID card/institute medical book/library card with you at all times to avoid discomfort."
    // },
    // {
    //     heading: "",
    //     content: "All rooms have to be vacated by 30th January 2025 strictly by 2pm."
    // },
    
]
const AccRules = () => {
    useEffect(() => {
        VanillaTilt.init(document.querySelectorAll(".tiltcard"),{
          max:5,
          speed:10,
          glare: false,
          'max-glare':0.5,
        })
      })
    return (
        <> 
        <div className="acc-bg"></div>
        <section className="acc-info">
            <Navbar />
            <div className="acctitdiv"><h1 className='acc-title acruletit'>RULES</h1></div>
            <AccCards info={Info}/>
        </section>
        </>
     );
}
 
export default AccRules;