import { createContext, useState,useEffect, memo } from "react";


const SubmitContext = createContext();

const SubmitProvider=memo(({children}) => {
    // const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [submitContext, setSubmitContext] = useState(true);

    const [currentComplaints, setCurrentComplaints] = useState([]);
    const [closedComplaints, setClosedComplaints] = useState([]);
    const [resolvedComplaints, setresolvedComplaints] = useState([]);

    return(
        <SubmitContext.Provider value={{currentComplaints, setCurrentComplaints,closedComplaints,setClosedComplaints,resolvedComplaints,setresolvedComplaints,submitContext,setSubmitContext}}>
            {children}
        </SubmitContext.Provider>
    );
});

export default SubmitProvider;
export {SubmitContext};