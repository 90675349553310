import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
import "./forgetpassword.css";
import scrollimg from "./scroll3.1.png";
import { useNavigate } from "react-router-dom";


const validationSchemaStep1 = Yup.object({
    email: Yup.string().email("Invalid email").required("Required"),
    // security_question: Yup.string().required("Required"),
    // security_answer: Yup.string().required("Required"),
});

const validationSchemaStep2 = Yup.object({
    otp: Yup.string()
        .matches(/^\d{6}$/, "OTP must be a 6-digit number")
        .required("OTP is required"),
    password: Yup.string().min(8, "At least 8 characters").required("Required"),
});

const ForgetPassword = () => {
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [email, setEmail] = useState("");
    const [timeLeft, setTimeLeft] = useState(300);
    const [isOtpExpired, setIsOtpExpired] = useState(false);
    const [loading,setLoading]=useState(false);

    const navigate = useNavigate();

    const clearForm = () => {
        console.log("clear started")
        setEmail("")
    }

    useEffect(() => {
        let timer;
        if (showOtpModal && timeLeft > 0) {
            timer = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
        }
        if (timeLeft === 0) {
            setIsOtpExpired(true);
            clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [showOtpModal, timeLeft]);

    // const handleRequestNewOtp = async () => {
    //     setTimeLeft(120);
    //     setIsOtpExpired(false);
    //     toast.success("New OTP requested. Please check your email.");
    // };


    const handleSubmitStep1 = async (values) => {
        const toSend = {
            email: values.email,
            // security_question: values.security_question,
            // security_answer: values.security_answer,
        };
        try {
            setLoading(true)
            const response = await axios.post(
                "https://masterapi.springfest.in/api/user/forgotPassword/otpRemoveQA",
                toSend
            );
            if (response.data.code === 0) {
                console.log('start');
                clearForm()
                toast.success(response.data.message);
                setEmail(values.email);
                setShowOtpModal(true);
                setTimeLeft(300);
                setIsOtpExpired(false);

            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.log(error)
            toast.error("Something went wrong. Please try again.");
        }finally{
            setLoading(false);
        }

    };

    const handleSubmitStep2 = async (values) => {
        if (isOtpExpired) {
            toast.error("OTP has expired. Please request a new OTP.");
            return;
        }
        const toSend = {
            email,
            otp: String(values.otp),
            password: values.password,
        };
        setLoading(true);
        try {
            const response = await axios.post(
                "https://masterapi.springfest.in/api/user/forgotPassword/verifyOtp",
                toSend
            );
            if (response.data.code === 0) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again.");
        }finally{
            setLoading(false);
        }
    };

    return (
        <div className="forget authentication">
            <div className="forget-password-content">
                <img className="forgetimg" src={scrollimg} />
                <Toaster />
                <div className="forget-password-body">
                    {!showOtpModal ? (
                        <>
                            <h1 className="forget-password-heading">Forgot Password</h1>
                            <div className="forget-password-form-cont">
                                <Formik
                                    initialValues={{
                                        email: "",
                                        // security_question: "",
                                        // security_answer: "",
                                    }}
                                    validationSchema={validationSchemaStep1}
                                    onSubmit={(values, { resetForm }) => {
                                        handleSubmitStep1(values)
                                        console.log("Form submitted:", values);

                                        
                                        resetForm();
                                    }}
                                >
                                    {({ isSubmitting }) => (
                                        <Form>
                                            <div className="forget-password-inputfield">
                                                <Field
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    placeholder="Email"
                                                    autoComplete="off"
                                                />
                                                <ErrorMessage
                                                    name="email"
                                                    component="div"
                                                    className="error-message"
                                                />
                                            </div>
                                            {/* <div className="forget-password-inputfield">
                                                <Field
                                                    type="text"
                                                    id="security_question"
                                                    name="security_question"
                                                    placeholder="Security Question"
                                                    autoComplete="off"
                                                />
                                                <ErrorMessage
                                                    name="security_question"
                                                    component="div"
                                                    className="error-message"
                                                />
                                            </div>
                                            <div className="forget-password-inputfield">
                                                <Field
                                                    type="text"
                                                    id="security_answer"
                                                    name="security_answer"
                                                    placeholder="Security Answer"
                                                    autoComplete="off"
                                                />
                                                <ErrorMessage
                                                    name="security_answer"
                                                    component="div"
                                                    className="error-message"
                                                />
                                            </div> */}
                                            <div className="forget-password-btn-div">
                                                <button
                                                    className="forget-password-button"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    {loading?("Sumbiting..."):("Submit")}
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </>
                    ) : (
                        <>
                            <h1 className="forget-password-heading">Verify OTP</h1>
                            <div className="forget-password-form-cont">

                               
                                <Formik
                                    initialValues={{
                                        otp: "",
                                        password: "",
                                    }}
                                    validationSchema={validationSchemaStep2}
                                    onSubmit={(values) => { handleSubmitStep2(values); navigate("/"); }}
                                >
                                    {({ isSubmitting }) => (
                                        <Form>
                                            <div className="forget-password-inputfield">
                                                <Field
                                                    type="text"
                                                    id="otp"
                                                    name="otp"
                                                    placeholder="Enter OTP"
                                                    autoComplete="off"
                                                />
                                                <ErrorMessage
                                                    name="otp"
                                                    component="div"
                                                    className="error-message"
                                                />
                                            </div>
                                            <div className="forget-password-inputfield">
                                                <Field
                                                    type="password"
                                                    name="password"
                                                    placeholder="Enter New Password"
                                                />
                                                <ErrorMessage
                                                    name="password"
                                                    component="div"
                                                    className="error-message"
                                                />
                                            </div>
                                            <p className="forgot-password-timer" style={{ zIndex: 10 }}>
                                                OTP expires in : {Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? "0" : ""}
                                                {timeLeft % 60}
                                            </p>
                                            <div className="forget-password-btn-div">
                                                <button
                                                    className="forget-password-button"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    {loading?("Submitting..."):("Submit")}
                                                </button>
                                            </div>

                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ForgetPassword;
