import { useContext } from 'react'
import './Leaderboard.css'
import { GamesContext } from '../usecontext/Game_provider'
import { NavLink } from "react-router-dom";

export default function Leaderboard() {
    const { playersforCans, userforCans } = useContext(GamesContext);
    const user_name = ((JSON.parse(localStorage.getItem('userData')))?.user.name) || "NA"
    let ctnLeaders = 0
    let userRank = 0

    return (
        <div className='leaderboard-main-container'>
            <div className="leaderboard-container">
                <h1>Leaderboard</h1>
                <div className="all-data-container">
                    <div className="all-players">
                        <div className="leader-cols">
                            <h3>Rank</h3>
                            <h3>Name</h3>
                            <h3>Points</h3>
                        </div>
                        <div className="allplayers-container">
                            {playersforCans.map((player) => {
                                if (player.user_name === user_name) {
                                    userRank = player.players_rank
                                }
                                ctnLeaders++;
                                if (ctnLeaders > 10) return null
                                return (
                                    <div key={player.id} className='player-card'>
                                        <div className='player-details'>{player.players_rank}</div>
                                        <div className='player-details'>{player.user_name}</div>
                                        <div className='player-details'>{player.points_m}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="leaderboard-user-container">
                <div className="play-game">
                    <NavLink to="/games/cans" >
                        <button className="play-btn">Play</button>
                    </NavLink>
                </div>
                {userforCans?.user_name && <div className="leaderboard-user">
                    <div className='player-details'>{userRank}</div>
                    <div className='player-details'>{userforCans?.user_name}</div>
                    <div className='player-details'>{userforCans?.points_m}</div>
                </div>}
            </div>
        </div>
    )
}