import "./Events.css";
import dance from "./assets/dance event.png";
import music from "./assets/Music.c91b700a4c5acdc4bb1c.png";
import drama from "./assets/Drama.6fbebe13b5a463eababb.png";
import literary from "./assets/Literary.ee3a4fdc591b333cb436.png";
import filmfest from "./assets/FilmFest.0aa5ff627365b4ef7d2f.png";
import quiz from "./assets/download.png";
import fineArts from "./assets/FineArts.6b8125d2615ce4bed692.png";
import foodFest from "./assets/FoodFest.5c3c946ebac4ee5c475d.png";
import fashion from "./assets/Fashion.2b3060fc31ebc2867069.png";
// import gameFest from "./assets/game.png";
import humourFest from "./assets/HumourFest.5fa51a02f088f0ed9c1f.png";
import { Link } from "react-router-dom";
import heximg from "./assets/card.png"
import ReactGA from 'react-ga';

const Events = () => {
  ReactGA.initialize("G-23Q6ZTSW0Q");
  ReactGA.send({ hitType: "pageview", page: "/events", title: "Register to take part in the events Subheads" });

  return (
    <>
      <div className="events">
        <h1 className="eventheading glow-heading-event">EVENTS</h1>
        <div className="Event-body">
          <Link to="/events/subevents/Dance">
            {" "}
            <div className="hexagon">
              <img src={heximg} className="hexagonBackground" alt= ''/>
              <img src={dance} className="event-img" alt="eventimg" />
              <div className="event-heading-name">
              <h2 className="eventName dance">Dance</h2>
              </div>
            </div>{" "}
          </Link>
          <Link to="/events/subevents/Music">
            {" "}
            <div className="hexagon">
            <img src={heximg} className="hexagonBackground" alt= ''/>
              <img src={music} className="event-img-2" alt="eventimg" />
              <div className="event-heading-name">
              <h2 className="eventName music">Music</h2>
              </div>
            </div>{" "}
          </Link>
          <Link to="/events/subevents/Dramatics">
            {" "}
            <div className="hexagon">
            <img src={heximg} className="hexagonBackground" alt= ''/>
              <img src={drama} className="event-img-3" alt="eventimg" />
              <div className="event-heading-name">
              <h2 className="eventName drama">Dramatics</h2>
              </div>
            </div>{" "}
          </Link>
          <Link to="/events/subevents/Literary">
            {" "}
            <div className="hexagon">
            <img src={heximg} className="hexagonBackground" alt= ''/>
              <img src={literary} className="event-img" alt="eventimg" />
              <div className="event-heading-name">
              <h2 className="eventName literary">Literary</h2>
              </div>
            </div>{" "}
          </Link>
          <Link to="/events/subevents/Film Fest">
            {" "}
            <div className="hexagon">
            <img src={heximg} className="hexagonBackground" alt= ''/>
              <img src={filmfest} className="event-img" alt="eventimg" />
              <div className="event-heading-name">
              <h2 className="eventName">Film Fest</h2>
              </div>
            </div>{" "}
          </Link>
          <Link to="/events/subevents/Quiz">
            {" "}
            <div className="hexagon">
            <img src={heximg} className="hexagonBackground" alt= ''/>
              <img src={quiz} className="event-img" alt="eventimg" />
              <div className="event-heading-name">
              <h2 className="eventName">Quiz</h2>
              </div>
            </div>{" "}
          </Link>
          <Link to="/events/subevents/Fine Arts">
            {" "}
            <div className="hexagon">
            <img src={heximg} className="hexagonBackground" alt= ''/>
              <img src={fineArts} className="event-img" alt="eventimg" />
              <div className="event-heading-name">
              <h2 className="eventName">Fine Arts</h2>
              </div>
            </div>{" "}
          </Link>
          <Link to="/events/subevents/Humor Fest">
            {" "}
            <div className="hexagon">
            <img src={heximg} className="hexagonBackground" alt= ''/>
              <img src={humourFest} className="event-img-3" alt="eventimg" />
              <div className="event-heading-name">
              <h2 className="eventName humourfest">Humour Fest</h2>
              </div>
            </div>{" "}
          </Link>
          
          <Link to="/events/subevents/Fashion">
            {" "}
            <div className="hexagon">
            <img src={heximg} className="hexagonBackground" alt= ''/>
              <img src={fashion} className="event-img-1" alt="eventimg" />
              <div className="event-heading-name">
              <h2 className="eventName fashion">Fashion</h2>
              </div>
            </div>{" "}
          </Link>
          <Link to="/events/subevents/Culinary Arts">
            {" "}
            <div className="hexagon">
            <img src={heximg} className="hexagonBackground" alt= ''/>
              <img src={foodFest} className="event-img" alt="eventimg" />
              <div className="event-heading-name">
              <h2 className="eventName">Culinary Arts</h2>
              </div>
            </div>{" "}
          </Link>
        </div>
      </div>
    </>
  );
};

export default Events;
