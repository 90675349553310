import './dashboard.css'
import React, { useState, useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
import { useContext } from "react";
import { ProfileContext } from "../usecontext/profile_provider";


function Reportissue({ resetParentForm, handleFetchComplaints }) {
    const { userData, setUserData, isLoggedIn, setIsLoggedIn } = useContext(ProfileContext);
    const token = userData.token

    const setBackgroundUnBlur = () => {
        const BackgroundBlur = document.getElementsByClassName("dashboard-background-blur")[0]
        if (BackgroundBlur) {
            BackgroundBlur.style.height = '0vh';
            BackgroundBlur.style.width = '0vw';
        }
    }

    const setModalClose = () => {
        // modal.style.display = "none"
        let modals = document.getElementsByClassName("dashboard-modals");
        for (let i = 0; i < 3; i++) {
            modals[i].style.display = "none";
        }
        const ModalContainer = document.getElementsByClassName("dashboard-modal-container")[0]
        if (ModalContainer) {
            ModalContainer.style.display = "none"
        }
        setBackgroundUnBlur()
    }

    const [selectIssue, setSelectIssue] = useState('Select Issue')

    const handleChangeIssue = (e) => {
        console.log(e.target.value)
        setSelectIssue(e.target.value)
    }
    const [selectEvent, setSelectEvent] = useState('Select Event')

    const handleChangeEvent = (ev) => {
        console.log(ev.target.value)
        setSelectEvent(ev.target.value)
    }

    const fetchEventList = async () => {
        let event_list = []
        try {
            const response = await axios.get(
                "https://masterapi.springfest.in/api/event"
            );
            if(response.data.code === 0){
                Array.from(response.data.data).forEach(element => {
                    Array.from(element.events).forEach(item => {
                        event_list.push(item)
                    })
                });
                return event_list
            }
            else{
                // console.log("Cannot get the event list ", response.data.message)
                return []
            }
        }
        catch (err) {
            console.log("Cannot get the event list ", err)
        }
    }
    const [EventsList, setEventsList] = useState([])

    useEffect(() => {
        const fetchAndSetEvents = async () => {
            const events = await fetchEventList();
            //   events.unshift({"name":"Select Event","id":0}) 
            setEventsList(events);
        };

        fetchAndSetEvents();
    }, []);

    const optionDiv = (event) => {
        return <option key={event.id}>{event.name}</option>;
    };

    const returnOptions = () => {
        return EventsList.map(optionDiv);
    };


    const checkSuccess = (json) => {
        if (json.code === 0) {
            toast.success(json.message)
            resetForm()
        }
        else {
            toast.error(json.message)
        }
    }
    const resetForm = () => {
        setSelectIssue('Select Issue');
        setSelectEvent('Select Event');
        document.getElementById('Issue').value = '';
        resetParentForm();
    };

    const handleSubmitAPI = async (type, issue) => {
        const func = async () => {
            await handleFetchComplaints();
            console.log("complaint fetched")
            // console.log(currentComplaints)
        }
        try {
            // fetch("https://masterapi.springfest.in/api/user/complaints/submitComplaints",
            //     {
            //         method: "POST",
            //         headers: { "Content-type": "application/json" },
            //         body: JSON.stringify({
            //             "token": token,
            //             "type": type,
            //             "issue": issue
            //         })
            //     })
            const response = await axios.post("https://masterapi.springfest.in/api/user/complaints/submitComplaints", {
                "token": token,
                "type": type,
                "issue": issue
            })

            if (response.data.code === 0) {
                toast.success(response.data.message);
                func()
                resetForm()

            } else {
                toast.warning(response.data.code)
            }


            // .then((response) => { func(); return response.json() })
            // .then((json) => checkSuccess(json))
            // .catch((error) => {
            //     console.error("Fetch error:", error)
            //     toast.error("Network error. Please try again later.")
            // });
        }
        catch {
            console.log("Submit API error")
        }
    }


    const handleSubmit = () => {
        let type;
        let issue = document.getElementById("Issue").value
        if (document.getElementById("Issue_type").value != "Select Issue") {
            type = document.getElementById("Issue_type").value
            if (issue.match(/[a-zA-Z]/g)) {
                handleSubmitAPI(type, issue)
                setModalClose()

            }
            else {
                toast.error("Please Fill in the Issue")
            }
            console.log("clicked")
        }

        else {
            toast.error("Please select Issue Type")
        }



    }


    return (
        <div className="dashboard-modal-comlaint-form-group">

            <label htmlFor="Event_type">Event Type : </label>
            <select value={selectEvent} onChange={(ev) => handleChangeEvent(ev)} className="dashboard-modal-compliant-form-control" name="Event_type" id="Event_type" placeholder="Select Event">
                <option>Select event</option>
                {/* <option>A Mighty Pen</option>
                <option>A Picture Tale</option> */}
                {returnOptions()}
            </select>

            <label htmlFor="Issue_type">Issue Type* : </label>
            <select value={selectIssue} onChange={(e) => handleChangeIssue(e)} className="dashboard-modal-compliant-form-control" name="Issue_type" id="Issue_type">
                <option>Select Issue</option>
                <option>Registration Issue</option>
                <option>De-registration or Other Issue's</option>
            </select>

            <label htmlFor="Issue">Issue* : </label>
            <input type="Issue" id="Issue" name="Issue" className="dashboard-modal-compliant-form-control"></input>
            <button type="submit" id='dashboard-modal-complaint-submit-button' className='dashboard-modal-complaint-submit-button' onClick={handleSubmit}>Submit</button>
        </div>
    )
}
export default Reportissue;