import React, { useEffect, useState, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import "./Gallery.css";
import ReactGA from 'react-ga';

const GalleryPhone = () => {
  const images = [
    { id: 1, src: "https://i.postimg.cc/vmMnNK7H/DSC01430.webp", alt: "img 1" },
    { id: 2, src: "https://i.postimg.cc/LsTPKVvk/DSC01455.webp", alt: "img 1" },
    { id: 3, src: "https://i.postimg.cc/269B38sV/DSC01462-1.webp", alt: "img 1" },
    { id: 4, src: "https://i.postimg.cc/Rhj6QpVm/DSC01547.webp", alt: "img 1" },
    { id: 5, src: "https://i.postimg.cc/nzrX8fXZ/DSC01572.webp", alt: "img 1" },
    { id: 6, src: "https://i.postimg.cc/3rPFZnqW/DSC01603.webp", alt: "img 1" },
    { id: 7, src: "https://i.postimg.cc/KYvPYV1p/IMG-20250116-WA0002.webp", alt: "img 1" },
    { id: 8, src: "https://i.postimg.cc/RFWw7HQc/IMG-20250116-WA0003.webp", alt: "img 1" },
    { id: 9, src: "https://i.postimg.cc/NFTTVvnF/IMG-20250116-WA0004.webp", alt: "img 1" },
    { id: 10, src: "https://i.postimg.cc/CLTb8dZq/IMG-20250116-WA0005.webp", alt: "img 1" },
    { id: 11, src: "https://i.postimg.cc/zD01bH2d/IMG-20250116-WA0006.webp", alt: "img 1" },
    { id: 12, src: "https://i.postimg.cc/8C5vG6Yq/IMG-20250116-WA0007.webp", alt: "img 1" },
    { id: 13, src: "https://i.postimg.cc/nr89qwLJ/IMG-20250116-WA0008.webp", alt: "img 1" },
    { id: 14, src: "https://i.postimg.cc/PJmpyzL8/IMG-20250116-WA0009.webp", alt: "img 1" },
    { id: 15, src: "https://i.postimg.cc/t4RZd69Y/IMG-20250116-WA0010.webp", alt: "img 1" },
    { id: 16, src: "https://i.postimg.cc/jSGPTXqL/1.webp", alt: "img 1" },
    { id: 17, src: "https://i.postimg.cc/rpZmcyNg/2.webp", alt: "img 2" },
    { id: 18, src: "https://i.postimg.cc/xCHS79xX/3.webp", alt: "img 3" },
    { id: 19, src: "https://i.postimg.cc/zvmWWJXj/4.webp", alt: "img 4" },
    { id: 20, src: "https://i.postimg.cc/FsVcdXty/5.webp", alt: "img 5" },
    { id: 21, src: "https://i.postimg.cc/g0n6Twxs/6.webp", alt: "img 6" },
    { id: 22, src: "https://i.postimg.cc/5tTFdxvV/7.webp", alt: "img 7" },
    { id: 23, src: "https://i.postimg.cc/9M9wcvtq/8.webp", alt: "img 8" },
    { id: 24, src: "https://i.postimg.cc/4yT7yBB2/9.webp", alt: "img 9" },
    { id: 25, src: "https://i.postimg.cc/fyj39mPz/10.webp", alt: "img 10" },
    { id: 26, src: "https://i.postimg.cc/SQWY3bxB/11.webp", alt: "img 11" },
    { id: 27, src: "https://i.postimg.cc/J4MHWYyG/12.webp", alt: "img 12" },
    { id: 28, src: "https://i.postimg.cc/6pQ787fK/13.webp", alt: "img 13" },
    { id: 29, src: "https://i.postimg.cc/sg0M7VTh/14.webp", alt: "img 14" },
    { id: 30, src: "https://i.postimg.cc/tgK7vBQR/15.webp", alt: "img 15" },
    { id: 31, src: "https://i.postimg.cc/qR2qP24s/16.webp", alt: "img 16" },
    { id: 32, src: "https://i.postimg.cc/qq0q4gv9/17.webp", alt: "img 17" },
    { id: 33, src: "https://i.postimg.cc/59qjp5P1/18.webp", alt: "img 18" },
    { id: 34, src: "https://i.postimg.cc/Hs6n1YDh/19.webp", alt: "img 19" },
    { id: 35, src: "https://i.postimg.cc/KvVDcLm3/20.webp", alt: "img 20" },
    { id: 36, src: "https://i.postimg.cc/pLZgyRLF/21.webp", alt: "img 21" },
    { id: 37, src: "https://i.postimg.cc/FKYDm4dm/22.webp", alt: "img 22" },
    { id: 38, src: "https://i.postimg.cc/yY7jx9gL/23.webp", alt: "img 23" },
    { id: 39, src: "https://i.postimg.cc/mDRzj3Gq/24.webp", alt: "img 24" },
    { id: 40, src: "https://i.postimg.cc/x8X03qqh/25.webp", alt: "img 25" },
    { id: 41, src: "https://i.postimg.cc/9Q96tbpY/26.webp", alt: "img 26" },
    { id: 42, src: "https://i.postimg.cc/nLt6dmSb/27.webp", alt: "img 27" },
    { id: 43, src: "https://i.postimg.cc/5tqDDtDH/28.webp", alt: "img 28" },
    { id: 44, src: "https://i.postimg.cc/rsbH5RRH/29.webp", alt: "img 29" },
    { id: 45, src: "https://i.postimg.cc/28bMrGPN/30.webp", alt: "img 30" },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [enlargedImage, setEnlargedImage] = useState(null); 
  const intervalRef = useRef(null);

    ReactGA.initialize("G-23Q6ZTSW0Q");
    ReactGA.send({ hitType: "pageview", page: "/gallery", title: "SF-2024 Memories Subheads" });

  const startSlideTimer = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 1500);
  };

  useEffect(() => {
    if (!enlargedImage) {
      startSlideTimer();
    }
    return () => clearInterval(intervalRef.current);
  }, [images.length, enlargedImage]);

  const swipeHandlers = useSwipeable({
    onSwipedDown: (eventData) => {
      eventData.event.preventDefault(); // Prevent default scroll
      if (!enlargedImage) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        startSlideTimer();
      }
    },
    onSwipedUp: (eventData) => {
      eventData.event.preventDefault(); // Prevent default scroll
      if (!enlargedImage) {
        setCurrentIndex((prevIndex) =>
          prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
        startSlideTimer();
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });
  

  const handleImageClick = (id) => {
    setEnlargedImage((prev) => (prev === id ? null : id)); 
  };
  
  return (
    <div className="galleryPhone-container" {...swipeHandlers}>
      <div className="galleryPhone-heading">GALLERY</div>
      <div className="galleryPhone-slider">
        {images.map((image, index) => {
          const offset = currentIndex - index; 
          const isCurrent = currentIndex === index; 
          const isEnlarged = isCurrent && enlargedImage === image.id;
  
          let style = {};
          let firstTranslateX = window.innerHeight > 600 ? 10.9 : 21;
  
          if (isEnlarged) {
            style = {
              transform: `scale(${window.innerWidth>450 ? 1.65 : 1.4}) `,
              zIndex: 100,
              opacity: 1,
              border: "2px solid wheat",
              borderRadius: "12px",
              transition: "all 0.5s ease-in-out", 
              boxShadow: "0px 0px 20px rgba(255, 193, 111, 0.9)"
            };
          } else if (isCurrent) {
            style = {
              transform: `scale(1.2) translateZ(${window.innerWidth > 520 ? 150 : 120}px)`,
              zIndex: 100,
              opacity: 1,
              border: "2px solid wheat",
              borderRadius: "12px",
              transition: "all 0.5s ease-in-out", 
            };
          } else if (offset > 0) {
            style = {
              transform: `translateY(${(firstTranslateX + 2.14) * offset}vh) scale(${
                offset > 0 ? 1.2 - 0.1 * offset : 1 + 0.1 * offset
              }) translateZ(-${offset > 0 ? 10 + offset : 10 - offset}px)`,
              zIndex: offset > 0 ? 100 - offset : 100 + offset,
              opacity: 1,
              border: "2px solid white",
              borderRadius: "12px",
              transition: "all 0.5s ease-in-out", 
            };
          }
           else if (offset < 0 && Math.abs(offset) < 3) {
            style = {
              transform: `translateY(${firstTranslateX * offset}vh) scale(${
                offset > 0 ? 1.2 - 0.1 * offset : 1 + 0.1 * offset
              }) translateZ(-${offset > 0 ? 10 + offset : 10 - offset}px)`,
              zIndex: offset > 0 ? 100 - offset : 100 + offset,
              opacity: 1,
              border: "2px solid white",
              borderRadius: "12px",
              transition: "all 0.5s ease-in-out", 
            };
          }
          else {
            style = {
              transform: `translateY(${firstTranslateX * offset}vh) scale(${
                offset > 0 ? 1.2 - 0.1 * offset : 1 + 0.1 * offset
              }) translateZ(-${offset > 0 ? 10 + offset : 10 - offset}px)`,
              zIndex: offset > 0 ? 100 - offset : 100 + offset,
              opacity: 1,
              border: "2px solid white",
              borderRadius: "12px",
              transition: "all 0.5s ease-in-out", 
            };
          }
  
          return (
            <div
              key={image.id}
              className="galleryPhone-card"
              style={style}
              onClick={() => handleImageClick(image.id)}
            >
              <img src={image.src} alt={image.alt} />
            </div>
          );
        })}
      </div>
    </div>
  );
  
  
};

export default GalleryPhone;
