import React, { useContext, useEffect, useState } from "react";
import "./signin.css";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, Toaster } from "react-hot-toast";
import { NavLink, useNavigate } from "react-router-dom";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { ProfileContext } from "../usecontext/profile_provider";
import ReactGA from 'react-ga';

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().min(8, "At least 8 characters").required("Required"),
});

const Signin = () => {
  const { userData, setUserData, isLoggedIn, setIsLoggedIn } =
    useContext(ProfileContext);
  const [loadingSignIn, setLoadingSignIn] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    const toSend = { email: values.email, password: values.password };
    setLoadingSignIn(true);
    try {
      const response = await axios.post(
        "https://masterapi.springfest.in/api/user/login",
        toSend
      );
      if (response.data.code === 0) {
        toast.success(response.data.message);
        setUserData(response.data.data);
      } else {
        toast.error(response.data.message);
        setLoadingSignIn(false);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.");
      setLoadingSignIn(false);
    }
  };

  const handleGoogleSuccess = async (response) => {
    setLoadingSignIn(true);
    const token = response.credential;
    try {
      const apiResponse = await axios.post(
        "https://masterapi.springfest.in/api/user/login/google",
        { token }
      );
      if (apiResponse.data.code === 0) {
        toast.success(apiResponse.data.message);
        setUserData(apiResponse.data.data);
        setLoadingSignIn(false);
      } else {
        toast.error(apiResponse.data.message);
        setLoadingSignIn(false);
      }
    } catch (error) {
      toast.error("Google Sign-In failed. Please try again later.");
      setLoadingSignIn(false);
    }
  };

  const handleGoogleFailure = (error) => {
    console.error("Google Sign-In failed:", error);
    toast.error("Google Sign-In was unsuccessful. Please try again.");
  };

  useEffect(() => {
    if (userData) {
      setTimeout(() => {
        navigate("/");
        setLoadingSignIn(false);
      }, 1500);
    }
  }, [userData, navigate]);

  ReactGA.initialize("G-23Q6ZTSW0Q");
  ReactGA.send({ hitType: "pageview", page: "/signin", title: "Sign in to enter the magical world Subheads" });

  return (
    <div className="signin_content authentication">
      <Toaster />
      <div className="wrapper">
        <h1>SIGN IN</h1>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="signin-content">
                <div className="input-box">
                  <Field type="email" name="email" placeholder="  E-Mail:" />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="input-box">
                  <Field
                    type="password"
                    name="password"
                    placeholder="  Password:"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="submit-button">
                  <button
                    type="submit"
                    className="sub"
                    disabled={loadingSignIn}
                  >
                    {loadingSignIn ? "Signing In..." : "Sign In"}
                  </button>
                </div>
                <div className="google-login">
                  <GoogleOAuthProvider clientId="286069699874-heuan4cocadfg02jdgsui29ltbel5fkl.apps.googleusercontent.com">
                    <GoogleLogin
                      onSuccess={handleGoogleSuccess}
                      onFailure={handleGoogleFailure}
                      useOneTap={false} 
                      prompt="select_account" 
                      render={(renderProps) => (
                        <button
                          type="button"
                          className="custom-google-login-button"
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 48 48"
                            className="google-logo-svg"
                          >
                            <path
                              fill="#EA4335"
                              d="M24 9.5c3.14 0 5.91 1.1 8.11 2.91l6.07-6.07C34.6 3.13 29.63 1 24 1 14.83 1 7.01 6.55 3.48 14.03l7.17 5.59C12.23 12.25 17.67 9.5 24 9.5z"
                            />
                            <path
                              fill="#4285F4"
                              d="M9.56 28.36c-.62-1.69-.98-3.51-.98-5.36s.36-3.67.98-5.36L2.39 13c-1.59 3.23-2.39 6.85-2.39 10.36s.8 7.13 2.39 10.36l7.17-5.59z"
                            />
                            <path
                              fill="#FBBC05"
                              d="M24 38.5c-5.44 0-10.32-3.16-12.79-7.83l-7.17 5.59C7.01 41.45 14.83 47 24 47c5.43 0 10.41-2.06 14.18-5.45l-6.49-5.41c-2.07 1.43-4.69 2.36-7.69 2.36z"
                            />
                            <path
                              fill="#34A853"
                              d="M46.13 20H24v8h12.8c-1.11 3.03-3.45 5.63-6.49 7.06l6.49 5.41C41.58 37.19 47 30.5 47 23c0-1.02-.08-2.02-.2-3z"
                            />
                          </svg>
                          <span>Log in with Google</span>
                        </button>
                      )}
                    />
                  </GoogleOAuthProvider>
                </div>
                <div className="register-link">
                  <p className="reg">
                    New User? <NavLink to="/signup">Sign Up</NavLink>
                  </p>
                </div>
                <div className="remember-forgot">
                  <NavLink to="/forgot-password">Forgot password?</NavLink>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Signin;
