import React, { useState } from "react";
import "./Schedule.css";
import eventsData from "./events.json";

const Schedule = () => {
  const [selectedDay, setSelectedDay] = useState("Day 0");

  return (
    <div className="schedule">
      <header className="schedule-header">
        <h1>Schedule</h1>
        <nav>
          {Object.keys(eventsData.days).map((day, index) => (
            <button
              key={index}
              className={`day-tab ${day === selectedDay ? "active" : ""}`}
              onClick={() => setSelectedDay(day)}
            >
              {day}
            </button>
          ))}
        </nav>
      </header>
      <div className="timeline">
        {eventsData.days[selectedDay]?.map((event, index) => (
          <div className="timeline-item" key={index}>
            <div
              className="circle bounce-in"
              style={{
                background: "var(--secondary-color,rgb(152, 92, 204))",
                color: "#fff",
              }}
            >
              <span className="time">{event.time}</span>
            </div>
            <div className="e-card">
              <h2>{event.title}</h2>
              <p>{event.location}</p>
              <button
                className="map-button"
                onClick={() => window.open(event.mapLink, "_blank")}
              >
                Open in Map
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Schedule;
