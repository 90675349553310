import React, { Component } from "react";

class ScrollBox extends Component {
  state = {
    shouldScroll: false,
    isContentCentered: false, // New state to track the condition
  };

  componentDidMount() {
    const { content, thumb, scrollbar } = this;
    const { viewportHeight, contentHeight, ratio } = this;

    // Check if content is overflowing
    if (contentHeight > viewportHeight) {
      const thumbHeight = viewportHeight * ratio;
      thumb.style.height = `${thumbHeight}px`;
      scrollbar.style.display = "block"; // Show scrollbar
      this.setState({ isContentCentered: false }); // Content is not centered
    } else {
      scrollbar.style.display = "none"; // Hide scrollbar
      this.setState({ isContentCentered: true }); // Content is centered
    }
  }

  get viewportHeight() {
    return this.content.offsetHeight;
  }

  get contentHeight() {
    return this.content.scrollHeight;
  }

  get ratio() {
    return this.viewportHeight / this.contentHeight;
  }

  handleScroll = (event) => {
    const { content, thumb } = this;
    const { viewportHeight, ratio } = this;
    const scrollTop = content.scrollTop;
    const thumbHeight = viewportHeight * ratio;
    const thumbTop = scrollTop * ratio;

    thumb.style.transform = `translateY(${thumbTop}px)`;
    thumb.style.height = `${thumbHeight}px`;
  };

  scroll = (event) => {
    const { scrollbar, content, thumb, ratio } = this;
    const clientY = event.clientY;
    const scrollbarRects = scrollbar.getBoundingClientRect();
    const scrollbarTop = scrollbarRects.top + window.pageYOffset;
    const thumbRects = thumb.getBoundingClientRect();
    const thumbTop = thumbRects.top + window.pageYOffset;
    const shiftY = event.pageY - thumbTop;
    const max = scrollbar.offsetHeight - thumb.offsetHeight + 1;

    document.onmousemove = (event) => {
      let top = event.pageY - shiftY - scrollbarTop;

      top = top < 0 ? 0 : top > max ? max : top;

      content.scrollTop = top / ratio;
    };

    document.onmouseup = () => {
      document.onmousemove = document.onmouseup = null;
    };
  };

  render() {
    const { shouldScroll, props, state } = this;
    const { isContentCentered } = state; // Destructure the new state
    const color = props.color || "#000";
    const children = props.children;

    return (
      <div className="scrollbox">
        <div
          onScroll={this.handleScroll}
          ref={(div) => (this.content = div)}
          className={`content ${isContentCentered ? "acmiddle" : ""}`} // Apply conditional class
        >
          {children}
        </div>
        <div className="scrollbar" ref={(div) => (this.scrollbar = div)}>
          <div
            className="thumb"
            ref={(div) => (this.thumb = div)}
            onMouseDown={this.scroll}
            onSelectStart={(event) => event.preventDefault()}
          />
        </div>
      </div>
    );
  }
}

export default ScrollBox;
