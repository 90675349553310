import React, { useState, useContext } from "react";
import ReactModal from "react-modal";
import './Merch.css';
import './Merchmodal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { ProfileContext } from "../usecontext/profile_provider";
import hoodie from "./hoodie.webp";
import Tshirt from "./white-short-sleeve-t-shirt.webp";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import ReactGA from 'react-ga';

const merchData = {
  tshirt: [
    {
      id: 1,
      name: "T-Shirt 1",
      title:"With The Waves",
      price: 499,
      imageFront: "/Merch/tshirt-front[1].webp",
      imageBack: "/Merch/tshirt-rolling[1].webp"
    },
    {
      id: 2,
      name: "T-Shirt 2",
      title:"Weaver of Spells",
      price: 499,
      imageFront: "/Merch/tshirt-front[1].webp",
      imageBack: "/Merch/tshirt-witch[1].webp"
    },
  ],
  hoodie: [
    {
      id: 3,
      name: "Hoodie 1",
      title: "Oldman Hoodie",
      price: 999,
      imageFront: "/Merch/hoodie-front[1].webp",
      imageBack: "/Merch/hoodie-oldman[1].webp"
    },
    {
      id: 4,
      name: "Hoodie 2",
      title: "Unmask The Mystery",
      price: 999,
      imageFront: "/Merch/hoodie-front[1].webp",
      imageBack: "/Merch/hoodie-mask[1].webp"
    },
  ],
};

const Merch = () => {
  const [category, setCategory] = useState("tshirt");
  const [merchModal, setMerchModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [currentImage, setCurrentImage] = useState("front");
  const { setCart, cart, selectedSize, setSelectedSize, quantity, setQuantity } = useContext(ProfileContext);
  const items = merchData[category];

  const handleSizeSelection = (size) => setSelectedSize(size);

  
  const handleAddToCart = () => {
    if (!selectedSize) {
      toast.error("Please select a size before adding to cart");
      return;
    }
  
    const itemIndex = cart.findIndex(
      item => item.id === currentItem.id && item.size === selectedSize
    );
  
    if (itemIndex !== -1) {
      // Update existing item's count
      const updatedCart = [...cart];
      updatedCart[itemIndex].count += quantity;
      updatedCart[itemIndex].total = updatedCart[itemIndex].count * updatedCart[itemIndex].price;
      setCart(updatedCart);
      toast.success("Updated cart");
    } else {
      // Add new item to cart
      const itemToAdd = {
        id: currentItem.id,
        category: category,
        count: quantity,
        price: currentItem.price,
        size: selectedSize,
        title: currentItem.title,
        total: quantity * currentItem.price
      };
      setCart([...cart, itemToAdd]);
      toast.success("Added to cart");
    }
  
    setQuantity(1);
    setSelectedSize(null);
    setMerchModal(false);
  };
  
  const switchImage = (direction) => {
    setCurrentImage((prev) =>
      prev === "front" && (direction === "next" || direction === "prev")  ? "back" : "front"
    );
  };

  ReactGA.initialize("G-23Q6ZTSW0Q");
  ReactGA.send({ hitType: "pageview", page: "/merchandise", title: "Merch page visited Subheads" });

  return (
    <div className="merch-body-container">
      <div className="cart-icon">
        <Link to="/cart" className="cart-icon">
          <FontAwesomeIcon icon={faCartShopping} className="crt" style={{color: "white"}}/>
          {cart.length > 0 && <span className="cart-count">{cart.length}</span>}
        </Link>
      </div>

      <ReactModal
        isOpen={merchModal}
        onRequestClose={() => setMerchModal(false)}
        className="merch-container"
      >
        <button className="Register_Modal__CloseButton Modal-closebuttun" onClick={() => {setMerchModal(false); setQuantity(1)
        setSelectedSize(null);}}>&times;</button>
        {currentItem && (
          <div className="merch-details">
            <h2 style={{fontSize: "larger"}}>{currentItem.title}</h2>
            <div className="merch-image-container">
              <button className="merch-image-nav left" onClick={() => switchImage("prev")}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <img
                src={currentImage === "front" ? currentItem.imageFront : currentItem.imageBack}
                alt={currentItem.name}
                className="merch-photo"
              />
              <button className="merch-image-nav right" onClick={() => switchImage("next")}>
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
            <div className="merch-image-dots">
              <span className={`merch-dot ${currentImage === "back" ? "active" : ""}`} onClick={() => setCurrentImage("back")}></span>
              <span className={`merch-dot ${currentImage === "front" ? "active" : ""}`} onClick={() => setCurrentImage("front")}></span>
            </div>
            <span className="merch-price">Price - &#8377;{currentItem.price}</span>

            <div className="size-selection">
              <h3>Select Size</h3>
              <div className="sizes">
                {["S", "M", "L", "XL", "XXL"].map(size => (
                  <button
                    key={size}
                    className={`size-btn ${selectedSize === size ? "selected" : ""}`}
                    onClick={() => handleSizeSelection(size)}
                  >
                    {size}
                  </button>
                ))}
              </div>

              <div className="merch-quan-checker">
                Quantity
              <select
                name="quantity-1"
                className="merch-quantity"
                value={quantity}
                onChange={(e) => setQuantity(Number(e.target.value))}
              >
                <option value="1">1</option>
  <option value="2">2</option>
  <option value="3">3</option>
  <option value="4">4</option>
  <option value="5">5</option>
  <option value="6">6</option>
  <option value="7">7</option>
  <option value="8">8</option>
  <option value="9">9</option>
  <option value="10">10</option>
  <option value="11">11</option>
  <option value="12">12</option>
  <option value="13">13</option>
  <option value="14">14</option>
  <option value="15">15</option>
              </select></div>
            </div>
            <button className="add-to-cart" onClick={handleAddToCart}>Add to Cart <FontAwesomeIcon 
                icon={faCartShopping} 
                style={{ color: "#ffffff", transform: "scale(1.2)" }} 
            /></button>
          </div>
        )}
      </ReactModal>

      <div className="merchbox">
        <div className="container">
          <h1 className="merch-heading">MERCHANDISE</h1>
          <div className="buttons">
            <button
              className={`switch-btn ${category === "tshirt" ? "active" : ""}`}
              onClick={() => setCategory("tshirt")}
            >
              <img src={Tshirt} alt="tshirt" style={{ width: "25px", height: "20px", padding: "0" }} />
            </button>
            <button
              className={`switch-btn ${category === "hoodie" ? "active" : ""}`}
              onClick={() => setCategory("hoodie")}
            >
              <img src={hoodie} alt="hoodie" style={{ width: "25px", height: "20px", padding: "0" }} />
            </button>
          </div>

          <div className="merch-items-container">
            {items.map(item => (
              <div key={item.id} className="merch-item">
                <h3 style={{fontSize: "medium", width: "140%"}}>{item.title}</h3>
                <img src={item.imageBack} alt={item.name} className="merch-photo" onClick={() => { setMerchModal(true); setCurrentItem(item); setCurrentImage("back"); }}/>
                <button className="merch-check-btn" onClick={() => { setMerchModal(true); setCurrentItem(item); setCurrentImage("back"); }}>Check It Out</button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Merch;
