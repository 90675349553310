import Navbar from "./AcNavbar"
import { useState, useEffect } from "react"
import VanillaTilt from "vanilla-tilt";
import "./acc-cards.css"
import AccCards from "./acccardlist";
import { useInView } from "react-hook-inview";
// import board from "./NoticeBoard.jpg"
import ReactGA from 'react-ga';

const Info = [
    {
        heading: "ABOUT US",
        content: "Spring Fest, since its inception in 1960, has stood as a shining example of cultural excellence, growing exponentially in scale, participation, and grandeur. Renowned for its world-class performances, diverse events, and a vibrant atmosphere, the fest attracts massive crowds, creating an unparalleled celebration of art and culture. Amidst this vastness, ensuring hospitality becomes a cornerstone, reflecting the event's commitment to creating a welcoming and memorable experience for all its guests."
    },
    {
        heading: "",
        content: "At SPRING FEST, IIT Kharagpur, we work hard to ensure the utmost satisfaction of every participant and guest. Our team will not leave a single stone unturned to provide secure and comfortable accommodation to you and make you feel at home even away from home. This year, hospitality management is one of the core focuses of TEAM SPRING FEST 2025, so we look forward to making your stay delightful and your experience truly unforgettable. Guests will be provided with accommodation from the date of their arrival to 27th of January , along with free access to all the events that make Spring Fest a celebration like no other."
    },
    {
        heading: "TIMING:",
        content: "Check-In Time - 6:00 A.M. to 10:00 P.M. on Your Check-In Date.\nCheck-Out Time - Anytime on or Before Your Check-Out Date.\nThe arena and registration desk will remain closed between 7 PM and 10 PM on the fest dates. All the services will resume after 10 PM during fest dates."
    },
    {
        heading: "REGISTRATION CHARGES:",
        content: "Registration Charges at Spring Fest, IIT Kharagpur, is charged @2389 INR only/-  per head for 3 Days and 4 Nights package with 3 pronites passes, complimentary accommodation and SF Kit. (From 23 January 2025 08:00 A.M. to 27 January 2025 10:00 A.M)"
    },
    {
        heading: "CANCELLATION POLICY:",
        content: "Any queries regarding Cancellation or Refund shall be emailed to accommodation@springfest.in . The subject of the email should be 'Cancellation of Accommodation'. For any cancellations before the deadline, 75% of the total amount shall be refunded within 10 working days after the festival; there shall be no refunds for cancellations after the deadline. Deadline of cancellation: 20th January 2025."
    },
    {
        heading: "What is Contingent?",
        content: "A Contingent refers to a group of participants representing a particular college at Spring Fest."
    },
    {
        heading: "Benefits of a Contingent:",
        content: `1.Unified Accommodation: As a part of a contingent, all members will be accommodated together in the same common room, ensuring a seamless and cohesive experience. Separate accommodations will be provided for boys and girls.\n2.Contingent Championship: The contingent with the highest cumulative score at the end of the Fest will be crowned the Contingent Champion, adding a sense of pride and achievement to your college's participation.`
    },
    {
        heading: "What is a Contingent Championship?",
        content: "In the Contingent Championship all the contingent will compete and the Contingent with the highest score in the end will win the trophy."
    },
    {
        heading: "How do I register my contingent?",
        content: "To discover this information, kindly navigate to our Accommodation Portal, where you'll find a section dedicated specifically to the contingent. Explore the various options in that section to get detailed insights into accommodation details tailored for contingents. If you have any further queries, the portal should provide comprehensive assistance."
    },
    {
        heading: "See you here at Spring Fest 2025.",
        content: `
        Regards,

Team Spring Fest`
    },
]
const AccInfo = () => {
    useEffect(() => {
        VanillaTilt.init(document.querySelectorAll(".tiltcard"), {
            max: 5,
            speed: 10,
            glare: false,
        })
    })

    ReactGA.initialize("G-23Q6ZTSW0Q");
    ReactGA.send({ hitType: "pageview", page: "/accommodation", title: "Accommodation Visited Subheads" });

    return (
        <>
            <div className="acc-bg"></div>
            <section className="acc-info">
                <Navbar />
                <div className="acctitdiv"><h1 className='acc-title acinfotit'>INFO</h1></div>
                <AccCards info={Info} />
            </section>
        </>
    );
}

export default AccInfo;