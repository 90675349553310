import { useContext, useState, useEffect } from "react";
import { ProfileContext } from "../usecontext/profile_provider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faTaxi } from "@fortawesome/free-solid-svg-icons";
import "./Cart.css";
import ReactModal from "react-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga';

const validationSchema = Yup.object().shape({
	street: Yup.string()
		.required("Street is required")
		.min(5, "Street must be at least 5 characters"),
	city: Yup.string()
		.required("City is required")
		.min(2, "City must be at least 2 characters"),
	state: Yup.string()
		.required("State is required")
		.min(2, "State must be at least 2 characters"),
	pincode: Yup.string()
		.required("Pincode is required")
		.matches(/^[0-9]{6}$/, "Pincode must be exactly 6 digits"),
	mobile: Yup.string()
		.required("Emergency number is required")
		.matches(/^\d+$/, "Emergency number must be only digits")
		.min(10, "Emergency number must be of 10 digits")
		.max(10, "Emergency number can be of 10 digits"),
});
const mobileSchema = Yup.object().shape({
	mobile: Yup.string()
		.required("Emergency number is required")
		.matches(/^\d+$/, "Emergency number must be only digits")
		.min(10, "Emergency number must be of 10 digits")
		.max(10, "Emergency number can be of 10 digits"),
});

export default function Cart() {
	const { setCart, cart, userData } = useContext(ProfileContext);
	const [paymentModal, setPaymentModal] = useState(false);
	const [addAddress, setAddAddress] = useState(false);
	const [storeAddress, setStoreAddress] = useState("");
	const [mobileNo, setMobileNo] = useState(null);
	const [total, setTotal] = useState(null);
	const [totalCount, setTotalCount] = useState(null);
	const [finalTotal, setFinalTotal] = useState(null);
	const [loading, setLoading] = useState(false);
	const [payoption, setPayoption] = useState(false);
	const [conditions,setConditions]= useState(false);

	const Images1 = [
		{ id: 1, imageBack: "/Merch/tshirt-rolling[1].webp" },
		{ id: 2, imageBack: "/Merch/tshirt-witch[1].webp" },
		{ id: 3, imageBack: "/Merch/hoodie-oldman[1].webp" },
		{ id: 4, imageBack: "/Merch/hoodie-mask[1].webp" },
	];
	const navigate = useNavigate();

	const removeFromCart = (id, size) => {
		setCart(
			cart.filter((e) => e.id !== id || (e.id === id && e.size !== size))
		);
	};

	const handleIncreaseQuantity = (id, size) => {
		const updatedQuantity = cart.map((item) => {
			if (item.id === id && item.size === size) {
				return {
					...item,
					count: item.count + 1,
					total: item.price * (item.count + 1),
				};
			}
			return item;
		});
		setCart(updatedQuantity);
	};

	const handleDecreaseQuantity = (id, size) => {
		const updatedQuantity = cart.map((item) => {
			if (item.id === id && item.size === size && item.count > 1) {
				return {
					...item,
					count: item.count - 1,
					total: item.price * (item.count - 1),
				};
			}
			return item;
		});
		setCart(updatedQuantity);
	};

	const AddAddress = (values) => {
		console.log("Received values:", values);
		const formattedAddress = `${values.street}, ${values.city}, ${values.state}, ${values.pincode}`;
		setStoreAddress(formattedAddress);
		console.log("working");
	};

	useEffect(() => {
		if (storeAddress) {
			console.log("Updated address:", storeAddress);
		}
	}, [storeAddress]);

	const calculateTotal = () => {
		return cart.reduce((total, item) => total + item.price * item.count, 0);
	};

	useEffect(() => {
		const total1 = calculateTotal();
		const total2 = cart.reduce((total, item) => total + item.count, 0);
		setTotalCount(total2);
		setTotal(total1);
	}, [cart]);

	const paymentFunction = async () => {
		if(!conditions && addAddress){
			toast.error("Accept terms and conditions")
		}
		
		else{let grandTotal;
		if (total < 2449 && storeAddress !== "collect") {
			grandTotal = total + totalCount * 60;
			setFinalTotal(grandTotal);
		} else {
			setFinalTotal(total);
			grandTotal = total;
		}
		const toSend = {
			token: userData?.token,
			emergency_number: mobileNo || userData?.user?.mobile,
			address: storeAddress,
			name: userData?.user?.name,
			email: userData?.user?.email,
			cart: cart,
			tax: 0,
			grandtotal: grandTotal,
			subtotal: total,
		};
		setLoading(true);
		
		try {
			setConditions(false);
			setPaymentModal(false);
			setAddAddress(false);
			setPayoption(false);
			const url = `https://springfest.in/?cart=${JSON.stringify(toSend)}&origin=1`;
			window.open(url, "_blank");
			// const response = await axios.post(
			// 	"https://masterapi.springfest.in/api/merch/payment/initiate",
			// 	toSend
			// );
			// if (response.data.code === 0) {
			// 	setPaymentModal(false);
			// 	window.open(response.data.data.redirectUrl, "_blank");
			// } else if (response.data.code === 1) {
			// 	toast.error("Please Sign-in to continue");
			// 	setTimeout(() => {
			// 		navigate("/signin");
			// 	}, 1000);
			// } else {
			// 	toast.error(response.data.message);
			// }
		} catch {
			toast.error("Network issue");
		} finally {
			setLoading(false);
			setMobileNo(null);
		}}
	};

	const closeModal = () => {
		setPaymentModal(false);
		setAddAddress(false);
		setPayoption(false);
		setStoreAddress("");
		setMobileNo("");
	};
	const checkDisableCheckout = () => {
		if (cart.length === 0) {
			return true;
		}
		return false;
	};

	ReactGA.initialize("G-23Q6ZTSW0Q");
	ReactGA.send({ hitType: "pageview", page: "/cart", title: "Items in the cart Subheads" });

	return (
		<>
			<div className="cartitems-container">
				<h1 className="cartitems-heading">Your Items</h1>
				<div className="cartitems-breaking-line"></div>
				<div className="cartitems">
					<div className="cartitems-all">
						{cart?.map((e) => (
							<div key={e.id} className="cartitems-single-item">
								<div className="cartitems-format cartitems-format-main">
									<img
										src={
											Images1.find(
												(img) => img.id === e.id
											)?.imageBack
										}
										alt=""
										className="carticon-product-icon"
									/>
								</div>
								<div className="cartitems-start">
									<div className="cartitems-all-data">
										<p>{e.title}</p>
										<p className="sizes">{e.size}</p>
										<p className="cartitems-total-price">
											&#8377;{e.price * e.count}
										</p>
										<div className="cartitems-quantity-container">
											<div
												onClick={() =>
													handleDecreaseQuantity(
														e.id,
														e.size
													)
												}
												className="cartitems-quantity-reduce"
											>
												-
											</div>
											<button className="cartitems-quantity">
												{e.count}
											</button>
											<div
												onClick={() =>
													handleIncreaseQuantity(
														e.id,
														e.size
													)
												}
												className="cartitems-quantity-increase"
											>
												+
											</div>
										</div>
									</div>
								</div>
								<div
									onClick={() => removeFromCart(e.id, e.size)}
									className="cartitems-quantity-remove"
								>
									<svg
										className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
										id="cart-remove-item"
										focusable="false"
										aria-hidden="true"
										viewBox="0 0 24 24"
										data-testid="DeleteOutlineOutlinedIcon"
										fill="red"
										style={{
											color: "red",
											height: "2rem",
											cursor: "pointer",
										}}
									>
										<path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM8 9h8v10H8zm7.5-5-1-1h-5l-1 1H5v2h14V4z"></path>
									</svg>
								</div>
							</div>
						))}
					</div>
					<div className="cartitems-down">
						{cart.length ? (
							<div className="cartitems-total">
								<h1>Cart Totals</h1>
								<div className="separator"></div>
								<div>
									<div className="cartitems-total-item">
										<h3>Total</h3>
										<h3>&#8377;{calculateTotal()}</h3>
									</div>
								</div>
								<div className="checkout-div">
									{cart.length ? (
										<button
											className="checkout"
											onClick={() =>
												setPaymentModal(true)
											}
										>
											PROCEED TO CHECKOUT{" "}
											<FontAwesomeIcon
												icon={faCartShopping}
												style={{
													color: "#ffffff",
													transform: "scale(1.4)",
												}}
											/>
										</button>
									) : (
										"Your Cart is Empty"
									)}

									<ReactModal
										isOpen={paymentModal}
										onRequestClose={closeModal}
										className="contingent_payment"
									>
										<button
											className="Register_Modal__CloseButton Modal-closebuttun"
											onClick={() => {
												setPaymentModal(false);
												setAddAddress(false);
												setPayoption(false);
											}}
										>
											&times;
										</button>
										<div>
											<div className="text-after-checkout">
												<div className="control-tent-cart">
													<input
														type="radio"
														id="control-tent"
														onChange={() => {
															setStoreAddress(
																"collect"
															);
															setPayoption(true);
															setAddAddress(
																false
															);
															document.getElementById(
																"home-delivery"
															).checked = false;
														}}
													/>
													<label htmlFor="control-tent">
														Collect from control
														tent, arena during fest
													</label>
													<p>
														Delivery charges : ₹ 0
													</p>
												</div>
												<div className="home-delivery-box">
													<input
														type="radio"
														id="home-delivery"
														onChange={() => {
															setAddAddress(true);
															setPayoption(false);
															document.getElementById(
																"control-tent"
															).checked = false;
														}}
													/>
													<label htmlFor="home-delivery">
														Home Delivery
													</label>
													<p>
														Delivery charges : ₹ 60
														per piece{" "}
													</p>
													<p>
														Free delivery for an
														order above ₹ 2449
													</p>
												</div>
											</div>
											<div>
												{addAddress && (
													<Formik
														initialValues={{
															street: "",
															city: "",
															state: "",
															pincode: "",
															mobile: "",
														}}
														validationSchema={
															validationSchema
														}
														onSubmit={(values) => {
															
														
															// Call AddAddress first to update the address
															AddAddress(values);

															// Then call paymentFunction
															if (
																storeAddress !==
																"collect"
															)
																paymentFunction();
														}}
													>
														{({
															isSubmitting,
															values,
															setFieldValue,
														}) => (
															<Form>
																<div className="cont-input-box">
																	<label className="cont-label">
																		Street
																	</label>
																	<Field
																		className="cont-field"
																		type="text"
																		name="street"
																		placeholder="House no./Street/Locality"
																	/>
																	<ErrorMessage
																		name="street"
																		component="div"
																		className="error"
																	/>
																</div>
																<div className="cont-input-box">
																	<label className="cont-label">
																		City
																	</label>
																	<Field
																		className="cont-field"
																		type="text"
																		name="city"
																		placeholder="City"
																	/>
																	<ErrorMessage
																		name="city"
																		component="div"
																		className="error"
																	/>
																</div>
																<div className="cont-input-box">
																	<label className="cont-label">
																		State
																	</label>
																	<Field
																		className="cont-field"
																		type="text"
																		name="state"
																		placeholder="State"
																	/>
																	<ErrorMessage
																		name="state"
																		component="div"
																		className="error"
																	/>
																</div>
																<div className="cont-input-box">
																	<label className="cont-label">
																		Pincode
																	</label>
																	<Field
																		className="cont-field"
																		type="text"
																		name="pincode"
																		placeholder="Pin-code"
																	/>
																	<ErrorMessage
																		name="pincode"
																		component="div"
																		className="error"
																	/>
																</div>
																<div className="cont-input-box">
																	<label className="cont-label">
																		Emergency
																		Number
																	</label>
																	<Field
																		className="cont-field"
																		type="text"
																		name="mobile"
																		placeholder="Emergency Number"
																		maxLength="10"
																		onChange={(
																			e
																		) => {
																			setFieldValue(
																				"mobile",
																				e
																					.target
																					.value
																			);
																			setMobileNo(
																				e
																					.target
																					.value
																			);
																		}}
																	/>
																	<ErrorMessage
																		name="mobile"
																		component="div"
																		className="error"
																	/>
																</div>
																<div className="terms">
																	<input
																		type="checkbox"
																		id="input-checkbox"
																		onChange={() => {
																			setConditions(true);
																			if(conditions){
																				setConditions(false);
																			}
																			document.getElementById(
																				"control-tent"
																			).checked = false;
																		}}
																	/>
																	<label htmlFor="home-delivery">
																		I understand and agree that :
																	</label>
																	<p>
																		The merchandise will be delivered after 1 month of Spring Fest
																		Standard shipping times will apply.
																	</p>
																	<p>	Delivery address provided must be complete and accurate</p>
																</div>
																<div className="cont-submit-button">
																	{!conditions?(<button
																		type="submit"
																		className="cont-sub"
																	>
																		Accept terms and conditions
																	</button>):(<button
																		type="submit"
																		className="cont-sub"
																	>
																		Pay Now
																	</button>)}
																</div>
															</Form>
														)}
													</Formik>
												)}
											</div>

											{payoption && (
												<>
													{" "}
													<Formik
														initialValues={{
														
															mobile: ""
														}}
														validationSchema={
															mobileSchema
														}
														onSubmit={(values) => {
															
														
																paymentFunction();
														}}
													>
														{({
															isSubmitting,
															values,
															setFieldValue,
														}) => (
															<Form>
																
																<div className="cont-input-box">
																	<label className="cont-label">
																		Emergency
																		Number
																	</label>
																	<Field
																		className="cont-field"
																		type="text"
																		name="mobile"
																		placeholder="Emergency Number"
																		maxLength="10"
																		onChange={(
																			e
																		) => {
																			setFieldValue(
																				"mobile",
																				e
																					.target
																					.value
																			);
																			setMobileNo(
																				e
																					.target
																					.value
																			);
																		}}
																	/>
																	<ErrorMessage
																		name="mobile"
																		component="div"
																		className="error"
																	/>
																</div>
																
																<div className="cont-submit-button">
																	<button
																		type="submit"
																		className="cont-sub"
																	>
																		Pay Now
																	</button>
																</div>
															</Form>
														)}
													</Formik>
													
												</>
											)}
										</div>
									</ReactModal>
								</div>
							</div>
						) : (
							<div className="your-cart-is-empty">
								Your Cart is Empty
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
