import React from 'react'
import './LapNavbar.css'
import accicon from '../Assets/accicon.webp'
import acciconact from '../Assets/newcimages/accicon-act.png'
import eventsicon from '../Assets/eventsicon.webp'
import eventsiconact from '../Assets/newcimages/eventsicon-act.png'
import homeicon from '../Assets/homeicon.webp'
import homeiconact from '../Assets/newcimages/homeicon-act.png' //
import merchicon from '../Assets/merchicon.webp'
import merchiconact from '../Assets/newcimages/merchicon-act.png'
import sponsicon from '../Assets/sponsicon.png'
import faqicon from '../Assets/faqicon.png'
import faqiconact from '../Assets/newcimages/faqicon-act.png'
import teamsicon from '../Assets/teamsicon.png'
import aftermovieicon from '../Assets/aftermovieicon.png'
import aftermovieiconact from '../Assets/newcimages/aftermovieicon-act.png'
import gamesicon from '../Assets/gamesicon.png'
import gamesiconact from '../Assets/newcimages/gamesicon-act.png'
import galleryicon from '../Assets/galleryicon.png'
import galleryiconact from '../Assets/newcimages/galleryicon-act.png'
import { NavLink, useLocation } from 'react-router-dom'
import { motion } from "framer-motion";
import { useContext } from 'react'
import { ProfileContext } from "../../usecontext/profile_provider";

export default function LapNavbar() {
  const location = useLocation();
  const {isLoggedIn} = useContext(ProfileContext);

  return (
    <motion.div initial={{ top: "-20vh" }} animate={{ top: '0' }} transition={{ type: "spring", damping: 3, stiffness: 10 }} className='lapnavbar-container'>
      <ul>
        <li className="lapnavbar-items" id={location.pathname === '/' ? "active" : ""}>
          <NavLink to="/">
            <img src={location.pathname === '/' ? homeiconact : homeicon} alt=""  id={location.pathname === '/' ? "active-img" : ""} />
            <p>Home</p>
          </NavLink>
        </li>
        <li className="lapnavbar-items" id={(location.pathname).split("/").slice(0, 3).join("/") === '/events/subevents' || location.pathname === '/events'? 'active' : ""}>
          <NavLink to="/events">
            <img src={(location.pathname).split("/").slice(0, 3).join("/") === '/events/subevents' || location.pathname === '/events' ? eventsiconact : eventsicon} alt=""  id={
            (location.pathname).split("/").slice(0, 3).join("/") === '/events/subevents' || location.pathname === '/events'?'active-img' :""} />
            <p>Events</p>
          </NavLink>
        </li>
        <li className="lapnavbar-items" id={['/accommodation', '/accommodation-rules', '/accommodation-faq', '/accommodation-map', "/contingent"].includes(location.pathname) ? 'active' : ""}>
          <NavLink to={isLoggedIn ? "/contingent": "/accommodation"}>
            <img src={['/accommodation', '/accommodation-rules', '/accommodation-faq', '/accommodation-map','/contingent'].includes(location.pathname) ? acciconact : accicon} alt=""  id={['/accommodation', '/accommodation-rules', '/accommodation-faq', '/accommodation-map','/contingent'].includes(location.pathname) ? 'active-img' : ""} />
            <p>Accommodation</p>
          </NavLink>
        </li>
        <li className="lapnavbar-items" id={location.pathname === '/merchandise' ? "active" : ''}>
          <NavLink to="/merchandise">
            <img src={location.pathname === '/merchandise' ? merchiconact : merchicon} alt=""  id={location.pathname === '/merchandise' ? "active-img" : ''} />
            <p>Merch</p>
          </NavLink>
        </li>
        <li className="lapnavbar-items"><a href="https://sponsors.springfest.in/" target="_blank" rel="noopener noreferrer">
          <img src={sponsicon} alt=""  />
          <p>Sponsors</p></a>
        </li>
        <li className="lapnavbar-items" id={location.pathname === '/faqs' ? 'active' : ""}>
          <NavLink to="/faqs">
            <img src={location.pathname === '/faqs' ? faqiconact : faqicon} alt=""  id={location.pathname === '/faqs' ? 'active-img' : ""} />
            <p>FAQs</p>
          </NavLink>
        </li>
        <li className="lapnavbar-items"><a href="https://teams.springfest.in/" target="_blank" rel="noopener noreferrer">
          <img src={teamsicon} alt=""  />
          <p>Our Team</p></a>
        </li>
        <li className="lapnavbar-items" id={location.pathname === '/aftermovie' ? 'active' : ''}>
          <NavLink to="/aftermovie">
            <img src={location.pathname === '/aftermovie' ? aftermovieiconact : aftermovieicon} alt=""  id={location.pathname === '/aftermovie' ? 'active-img' : ''} />
            <p>After Movie</p>
          </NavLink>
        </li>
        <li className="lapnavbar-items" id={location.pathname === '/games' ? 'active' : ""}>
          <NavLink to="/games">
            <img src={location.pathname === '/games' ? gamesiconact : gamesicon} alt=""  id={location.pathname === '/games' ? 'active-img' : ""} />
            <p>Games</p>
          </NavLink>
        </li>
        <li className="lapnavbar-items" id={location.pathname === '/gallery' ? 'active' : ""}>
          <NavLink to="/gallery">
            <img src={location.pathname === '/gallery' ? galleryiconact : galleryicon} alt=""  id={location.pathname === '/gallery' ? 'active-img' : ""} />
            <p>Gallery</p>
          </NavLink>
        </li>
      </ul>
    </motion.div>
  )
}
