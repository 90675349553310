import { useState, useContext } from "react";
import { ProfileContext } from "../usecontext/profile_provider";
import axios from "axios";
import { toast } from "react-hot-toast";
import ReactModal from "react-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import crown from './crown.png'
import ReactGA from 'react-ga';
import Barcode from 'react-barcode';

const validationSchema = Yup.object().shape({
    check_in: Yup.string()
        .required('Check-in date is required'),
    check_out: Yup.string()
        .required('Check-out date is required')
        .test('is-after-check-in', 'Check-out date must be after check-in date', function (value) {
            const { check_in } = this.parent;
            return check_in && value >= check_in;
        }),
    emergency_number: Yup.string()
        .required('Emergency number is required')
        .matches(/^\d+$/, 'Emergency number must be only digits')
        .min(10, 'Emergency number must be of 10 digits')
        .max(10, 'Emergency number can be of 10 digits'),
});

const LeaderContingent = ({ contingentdetails, setContingentMember, setLeadercontingent, getMembers, leaderID }) => {
    const { userData } = useContext(ProfileContext);
    const [paymentmodal, setPaymentmodal] = useState(false);
    const [singlepaymentmodal, setSinglepaymentmodal] = useState(false);
    const [addmembersmodal, setAddmembersmodal] = useState(false)
    const [memberCount, setMemberCount] = useState(1);
    const [loading, setLoading] = useState(false);
    const [barcode,setBarcode]= useState(false);

    // filtering members of the contingent
    const contingentPayment_status = contingentdetails?.contingent_payment_status;
    const contingentMembers = contingentdetails?.updatedMembersInfo;
    const leaderContingent = contingentdetails?.updatedMembersInfo?.find((user) => user.sfId === userData.user.sfId);
    // console.log(contingentdetails);
    //single payment
    const singlePayment = async (values) => {

        const payload = {
            token: userData.token,
            check_in: Date(values.check_in),
            check_out: Date(values.check_out),
            emergency_number: values.emergency_number,
            type_of_acco: "Common",
            type_of_payment: "Individual"
        }
        setLoading(true);
        try {
            const url = `https://springfest.in/?token=${userData.token}&phoneNumber=${values.emergency_number}&type=Individual&origin=1`;
            window.open(url, '_blank');
            // const response = await axios.post('https://masterapi.springfest.in/api/payment/initiate',payload)
            // if(response.data.code===0){
            //     getMembers()
            //     setSinglepaymentmodal(false);
            //     window.open( response.data.data.redirectUrl,'_blank');

            // }
            // else{
            //     toast.error(response.data.message)
            // }
        } catch (error) {
            console.log(error);

        } finally {
            setLoading(false);
        }
    }

    ReactGA.initialize("G-23Q6ZTSW0Q");
    ReactGA.send({ hitType: "pageview", page: "/contingent", title: "Enter the contingent Subheads" });

    //contingent payment
    const contingentPayment = async (values) => {
        const payload = {
            token: userData.token,
            check_in: Date(values.check_in),
            check_out: Date(values.check_out),
            emergency_number: values.emergency_number,
            type_of_acco: "Common",
            type_of_payment: "Contingent"
        }
        setLoading(true);
        try {
            const url = `https://springfest.in/?token=${userData.token}&phoneNumber=${values.emergency_number}&type=Contingent&origin=1`;
            window.open(url, '_blank');
            // const response =await axios.post('https://masterapi.springfest.in/api/payment/initiate',payload);

            // if(response.data.code===0){
            //     getMembers()
            //     setPaymentmodal(false);
            //     window.open( response.data.data.redirectUrl,'_blank');

            // }
            // else{
            //    toast.error(response.data.message)
            // }
        } catch (error) {
            toast.error("Network Issue");
        } finally {
            setLoading(false);
        }
    }

    //Add members function
    const addMember = async (values) => {
        const payload = {
            token: userData.token,
            members: [...values.members]
        }
        setLoading(true);
        try {
            const response = await axios.post('https://masterapi.springfest.in/api/contingent/add', payload)
            if (response.data.code === 0) {
                getMembers()
                console.log(response.data);
                toast.success(response.data.message);
                setAddmembersmodal(false);
            }
            else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Network issue");
        } finally {
            setLoading(false);
        }
    }

    const handleLeaveContingent = async () => {
        setLoading(true);
        try {
            const response = await axios.post("https://masterapi.springfest.in/api/contingent/leave", { token: userData.token })
            if (response.data.code === 0) {
                getMembers()
                toast.success(response.data.message);
                setLeadercontingent(false);
                setContingentMember(false);
            }
            else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("network Issue");
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            {/* add members modal */}
            <ReactModal
                isOpen={addmembersmodal}
                onRequestClose={() => {
                    setAddmembersmodal(false);
                    setMemberCount(1);
                }}
                className="contingent_payment"
            >
                <button
                    className="add-mem-closebutton"
                    onClick={() => {
                        setAddmembersmodal(false);
                    }}
                >
                    &times;
                </button>
                <h1 className="contingent-head">ADD MEMBERS</h1>
                <Formik
                    initialValues={{ members: Array.from({ length: memberCount }, () => ({ sfId: '', email: '' })) }}
                    onSubmit={addMember}
                    enableReinitialize
                >
                    {({ isSubmitting, values }) => (
                        <Form>
                            <div className="cont-add-members-select">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#ffff"} fill={"none"} >
                                    <path d="M20 12L4 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" onClick={(e) => {
                                        if (memberCount >= 1)
                                            setMemberCount(1);
                                        else setMemberCount(1);
                                    }} />
                                </svg>

                                <select
                                    value={memberCount}
                                    onChange={(e) => setMemberCount(Number(e.target.value))}
                                    className="add-member-select"
                                >
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((count) => (
                                        <option key={count} value={count}>
                                            {count}
                                        </option>
                                    ))}
                                </select>


                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#ffff"} fill={"none"}>
                                    <path d="M12 4V20M20 12H4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" onClick={() => setMemberCount(memberCount + 1)} />
                                </svg>
                            </div>
                            {values.members.map((_, index) => (
                                <div key={index} className="cont-member-fields">
                                    <h4 className="cont-addmem-head">Member {index + 1}</h4>
                                    <div className=" cont-addmem-sfid">
                                        <label>SF-Id</label>
                                        <Field type="text" name={`members[${index}].sfId`} placeholder="SFXXXXX" />

                                    </div>

                                    <div className=" cont-addmem-sfid">
                                        <label>Email</label>
                                        <Field type="email" name={`members[${index}].email`} placeholder="abc@gmail.com" />

                                    </div>
                                </div>
                            ))}
                            <div className="cont-submit-button cont-payment-button">
                                {loading ? (<button type="submit" className="contingent-leader-modal-paymentbutton" disabled={isSubmitting}>
                                    Adding...
                                </button>) : (<button type="submit" className="contingent-leader-modal-paymentbutton" disabled={isSubmitting}>
                                    Add Members
                                </button>)}
                            </div>


                        </Form>
                    )}
                </Formik>
            </ReactModal>

            {/* payment modal for single member */}
            <ReactModal
                isOpen={singlepaymentmodal}
                onRequestClose={() => {
                    setSinglepaymentmodal(false);
                }}
                className="contingent_payment"
            >
                <button
                    className="add-mem-closebutton"
                    onClick={() => {
                        setSinglepaymentmodal(false);
                    }}
                >
                    &times;
                </button>
                <h1 className="contingent-head">PAYMENT</h1>

                <Formik
                    initialValues={{ check_in: "", check_out: "", emergency_number: "" }}
                    onSubmit={singlePayment}
                    validationSchema={validationSchema}
                >
                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form>
                            <div className="cont-leader-modal-cont-details cont-pay">
                                <div className="input-field">
                                    <div className="contingent-payment-input-error">

                                        <Field as="select" name="check_in">
                                            <option >Check-in</option>
                                            <option >22-01-2025</option>
                                            <option >23-01-2025</option>
                                            <option >24-01-2025</option>
                                            <option >25-01-2025</option>
                                            <option >26-01-2025</option>
                                            <option >27-01-2025</option>
                                            <option >28-01-2025</option>
                                        </Field>
                                        <ErrorMessage name="check_in" component="div" className="contingent-payment-error" />
                                    </div>
                                </div>
                                <div className="input-field">
                                    <div className="contingent-payment-input-error">
                                        <Field as="select" name="check_out">
                                            <option >Check-out</option>
                                            <option >22-01-2025</option>
                                            <option >23-01-2025</option>
                                            <option >24-01-2025</option>
                                            <option >25-01-2025</option>
                                            <option >26-01-2025</option>
                                            <option >27-01-2025</option>
                                            <option >28-01-2025</option>
                                        </Field>
                                        <ErrorMessage name="check_out" component="div" className="contingent-payment-error" />
                                    </div>
                                </div>
                                <div className="input-field">
                                    <div className="contingent-payment-input-error">
                                        <Field type="tel" name="emergency_number" placeholder="Mobile No." className="mobile-cont" maxLength='10' />
                                        <ErrorMessage name="emergency_number" component="div" className="contingent-payment-error" />
                                    </div>
                                </div>
                            </div>
                            <div className="cont-submit-button cont-payment-button">
                                {loading ? (<button type="submit" className="contingent-leader-modal-paymentbutton" disabled={isSubmitting}>
                                    Initiating....
                                </button>) : (<button type="submit" className="contingent-leader-modal-paymentbutton" disabled={isSubmitting}>
                                    Make Your Payment
                                </button>)}
                            </div>
                        </Form>
                    )}
                </Formik>
            </ReactModal>

            {/* payment modal for whole contingent */}
            <ReactModal
                isOpen={paymentmodal}
                onRequestClose={() => {
                    setPaymentmodal(false);
                }}
                className="contingent_payment"
            >
                <button
                    className="add-mem-closebutton"
                    onClick={() => {
                        setPaymentmodal(false);
                    }}
                >
                    &times;
                </button>
                <h1 className="contingent-head">PAYMENT</h1>
                <Formik
                    initialValues={{ check_in: "", check_out: "", emergency_number: "" }}
                    onSubmit={contingentPayment}
                    validationSchema={validationSchema}
                    validateOnChange={true}
                    validateOnBlur={true}
                >

                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form>
                            <div className="cont-leader-modal-cont-details cont-pay">
                                <div className="input-field">
                                    <div className="contingent-payment-input-error" >
                                        <Field as="select" name="check_in">
                                            <option >Check-in</option>
                                            <option >22-01-2025</option>
                                            <option >23-01-2025</option>
                                            <option >24-01-2025</option>
                                            <option >25-01-2025</option>
                                            <option >26-01-2025</option>
                                            <option >27-01-2025</option>
                                            <option >28-01-2025</option>
                                        </Field>
                                        <ErrorMessage name="check_in" component="div" className="contingent-payment-error" />
                                    </div>
                                </div>
                                <div className="input-field">
                                    <div className="contingent-payment-input-error">
                                        <Field as="select" name="check_out">
                                            <option >Check-out</option>
                                            <option >22-01-2025</option>
                                            <option >23-01-2025</option>
                                            <option >24-01-2025</option>
                                            <option >25-01-2025</option>
                                            <option >26-01-2025</option>
                                            <option >27-01-2025</option>
                                            <option >28-01-2025</option>
                                        </Field>
                                        <ErrorMessage name="check_out" component="div" className="contingent-payment-error" />
                                    </div>
                                </div>
                                <div className="input-field">
                                    <div className="contingent-payment-input-error">
                                        <Field type="tel" name="emergency_number" placeholder="Mobile No." maxLength='10' />
                                        <ErrorMessage name="emergency_number" component="div" className="contingent-payment-error" />
                                    </div>
                                </div>
                            </div>
                            <div className="cont-submit-button cont-payment-button">
                                {loading ? (<button type="submit" className="contingent-leader-modal-paymentbutton" disabled={isSubmitting}>
                                    Initiating...
                                </button>) : (<button type="submit" className="contingent-leader-modal-paymentbutton" disabled={isSubmitting}>
                                    Make Payment
                                </button>)}
                            </div>


                        </Form>
                    )}
                </Formik>
            </ReactModal>

            <ReactModal
                isOpen={barcode}
                onRequestClose={() => {
                    setBarcode(false);
                }}
                className="barcode-1"
            >
                <button
                    className="add-mem-closebutton"
                    onClick={() => {
                        setBarcode(false);
                    }}
                >
                    &times;
                </button>
                <div className="barcode"><Barcode value={contingentdetails?.id} /></div>
            </ReactModal>
            <div style={{ height: '70vh' }}>
                <div className="contingent-leader-modal " >

                    <h2 className="contingent-leader-modal-contname">{contingentdetails?.contingent_name}</h2>
                    <div className="contingent-leader-modal-content">

                        <div className="contingent-leader-modal-main-payment-buttons">
                            {contingentPayment_status ? (<p></p>) : (<button className="cont-leader-modal-addmem-button" onClick={() => setPaymentmodal(true)}>Contingent Payment</button>)}
                            {leaderContingent?.paymentStatus ? (<p></p>) : (<button onClick={() => setSinglepaymentmodal(true)} className="cont-leader-modal-addmem-button">Individual payment</button>)}
                        </div>
                        <div className="cont-details">
                            <h3>Contingent-Id : {contingentdetails?.id}</h3>
                            <h3>Contingent code : {contingentdetails?.code} </h3>
                        </div>
                        <div>
                               <button  className="cont-leader-modal-addmem-button"
                                    onClick={() => {setBarcode(true)}}
                               >
                                    Barcode
                                </button>
                        </div>
                        <div className="cont-leader-modal-member-details">
                            <h3 className="cont-leader-modal-member-details-head">Members of Contingent</h3>
                            <p>Number of Members : {contingentMembers?.length}</p><br></br>
                            {!contingentMembers ? (<p>No members this contingent</p>) : (
                                contingentMembers.map((member) => (
                                    <div className='cont-leader-modal-member-payment-status' key={member.sfId}>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}> <p> {member?.name}</p>
                                                <p>{member?.sfId}</p></div>
                                            {(member?.id === leaderID) && <div className="contingent-crown" style={{ marginLeft: '10px' }}><img src={crown} alt="(Leader)" /></div>}
                                        </div>

                                        <div>{member?.paymentStatus ? (<p className="cont-leader-paid">Paid</p>) : (<p className="cont-leader-unpaid">Unpaid</p>)}</div>
                                    </div>
                                ))
                            )}
                        </div>
                        <div className="cont-leader-modal-adddel-buttons">
                            <button className="cont-leader-modal-deletemem-button" onClick={handleLeaveContingent}>Delete Contingent</button>
                           {!contingentPayment_status && <button className="cont-leader-modal-addmem-button" onClick={() => setAddmembersmodal(true)}>Add Members</button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default LeaderContingent;