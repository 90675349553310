import axios from "axios";
import { ProfileContext } from "../usecontext/profile_provider";
import { useContext, useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { toast } from "react-hot-toast";
import './Contingent.css'
import LeaderContingent from "./LeaderContingent";
import MemberContingent from "./MemberContingent";
import Navbar from "../Accommodation/AcNavbar";
import ReactModal from "react-modal";
import ReactGA from 'react-ga';

const Contingent = () => {
    const { userData } = useContext(ProfileContext);
    const [contingentmember, setContingentMember] = useState(false);
    const [leadercontingent, setLeadercontingent] = useState(false);
    const [contingentdetails, setContingentdetails] = useState(null);
    const [form, setForm] = useState(true);
    const [loading, setLoading] = useState(false);
    const [joinPage, setJoinPage] = useState("");
    const [color, setColor] = useState("")
    const [color2, setColor2] = useState("")
    const [leaderID, setLeaderID] = useState(0);
    const [helpModal,setHelpModal] = useState(false);

    // get members details
    const getMembers = async () => {
        try {
            const response = await axios.post("https://masterapi.springfest.in/api/contingent/getMembers", { token: userData.token })
            if (response.data.code === 0) {
                // const func= async ()=>{
                //   await setLeaderID(response.data.data.leaderId)
                // }
                setContingentdetails(response.data.data);
                setContingentMember(true);
                setLeaderID(response.data.data.leaderId);
                const checkcontingentLeader = userData.user.id === response.data.data.leaderId;

                if (checkcontingentLeader) {
                    setLeadercontingent(true);
                }
            }
            else if(response.data.code === -1){
                toast.error("Login Again to continue");
            }
            else {
                // console.log(response.data);
                toast.error(response.data.message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getMembers();
    }, [])

    //submit function for join contingent
    const handleJoinContingent = async (values) => {
        const toSend = { token: userData.token, contingent_id: values.contingent_id, contingent_code: values.contingent_code }
        setLoading(true);
        try {
            const response = await axios.post("https://masterapi.springfest.in/api/contingent/join", toSend)
            if (response.data.code === 0) {
                getMembers()
                toast.success(response.data.message);
                setContingentMember(true);
            }
            else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Network issue")
        } finally {
            setLoading(false);
        }
    }

    // submit function for create contingent
    const handleCreateContingent = async (values) => {
        const toPost = { token: userData.token, contingent_name: values.contingent_name };
        setLoading(true);
        try {
            const response = await axios.post("https://masterapi.springfest.in/api/contingent", toPost)
            if (response.data.code === 0) {
                getMembers()
                toast.success(response.data.message);
                setContingentMember(true);
                setLeadercontingent(true);
            }
            else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Network issue");
        } finally {
            setLoading(false);
        }

    }

    useEffect(() => {
        if (form) {
            setJoinPage("");
            setColor("cont-color");
            setColor2("")
        } else {
            setJoinPage("cont-move");
            setColor("")
            setColor2("cont-color")
        }
    }, [form]);
    
    ReactGA.initialize("G-23Q6ZTSW0Q");
    ReactGA.send({ hitType: "pageview", page: "/contingent", title: "Enter the contingent Subheads" });

    return (
        <>
            <Navbar />
            <div className="acc-bg"></div>
            <div className="contingent-body">

                <h1 className="contingent-head">CONTINGENT</h1>
                {contingentmember ?
                    (leadercontingent ? (<LeaderContingent contingentdetails={contingentdetails} setContingentMember={setContingentMember} setLeadercontingent={setLeadercontingent} getMembers={getMembers} leaderID={leaderID} />) : (<MemberContingent setContingentMember={setContingentMember} contingentdetails={contingentdetails} setLeadercontingent={setLeadercontingent} getMembers={getMembers} leaderID={leaderID} />))
                    :
                    (
                        <div className="contingent-form">
                            <div className="contingent-main-buttons">
                                <button className={`cont-opt-btn ${color}`} onClick={() => setForm(true)}>Join a contigent</button>
                                <button className={`cont-opt-btn ${color2}  `} onClick={() => setForm(false)}>Create a Contingent</button>
                                <div className={`cont-opt-btn cont-opt-bg ${joinPage}`}></div>
                            </div>
                            <div>
                                {form ?
                                    (<Formik
                                        initialValues={{ contingent_id: "", contingent_code: "" }}
                                        onSubmit={handleJoinContingent}>
                                        {({ isSubmitting }) => (
                                            <Form>
                                                <div className="contingent-form-subform">
                                                    <div className="cont-input-box">
                                                        <label className="cont-label">Contingent ID</label>
                                                        <Field className="cont-field" type="contingent_id" name="contingent_id" placeholder="Contingent Id" />
                                                    </div>
                                                    <div className="cont-input-box">
                                                        <label className="cont-label">Contingent code</label>
                                                        <Field className="cont-field" type="contingent_code" name="contingent_code" placeholder=" Contingent Code" />
                                                    </div>
                                                    <div className="cont-submit-button">
                                                        {loading ? (<button type="submit" className="cont-sub" disabled={isSubmitting}>
                                                            Joining....
                                                        </button>) : (<button type="submit" className="cont-sub" disabled={isSubmitting}>
                                                            Join Contingent
                                                        </button>)}
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>)
                                    :
                                    (<Formik
                                        initialValues={{ contingent_name: "" }}
                                        onSubmit={handleCreateContingent}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form>
                                                <div className="contingent-form-subform">
                                                    <div className="cont-input-box">
                                                        <label className="cont-label">Contingent Name</label>
                                                        <Field className="cont-field" type="contingent_name" name="contingent_name" placeholder="Contingent Name" />
                                                    </div>

                                                    <div className="cont-submit-button">
                                                        {loading ? (<button type="submit" className="cont-sub" disabled={isSubmitting}>Creating...</button>) : (<button type="submit" className="cont-sub" disabled={isSubmitting}>
                                                            Create Contingent
                                                        </button>)}
                                                    </div>

                                                </div>
                                            </Form>
                                        )}
                                    </Formik>)
                                }
                            </div>
                            {/* <button className="help-me" onClick={()=>{setHelpModal(true); console.log(helpModal);
            }}>Help</button> */}
                        </div>
                    )}
                        <button className="help-me" onClick={()=>{setHelpModal(true); console.log(helpModal);
            }}>Help</button>
            </div>
                <ReactModal
                        isOpen={helpModal}
                        onRequestClose={() => {
                            setHelpModal(false);
                        }}
                        className="help-container"
                      >
                 <button
                        className="Register_Modal__CloseButton Modal-closebuttun"
                        onClick={() => {
                            setHelpModal(false);
                        }}
                        >
                        &times;
                </button>
                        <div className="contact-infos">
                            {/* <div className="contact-info">Rohit Sahoo - 98490 15612</div> */}
                            <div>Parth Nigudkar - 70459 47717</div>
                            <div>Anoop Kumar Singh - 99092 22351</div>
                            <div>Astitva Singh - 95800 39544</div>
                        </div>

                </ReactModal>

        </>
    );
}

export default Contingent