import { useEffect, useState, useContext } from "react";
import "./SubEvents.css";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import ReactModal from "react-modal";
import { toast, Toaster } from "react-hot-toast";
import { ProfileContext } from "../usecontext/profile_provider";
import ReactGA from 'react-ga';

const SubEvents = () => {
  const { events } = useContext(ProfileContext);
  let navigate = useNavigate();
  const { eventname } = useParams();
  const [subevent, setSubevent] = useState(null);
  const [registermodal, setRegistermodal] = useState(false);
  const [paricipants, setParticipants] = useState(null);
  const [formData, setFormData] = useState([]);
  const { userData, isLoggedIn } = useContext(ProfileContext);
  const [subeventmodal, setSubeventmodal] = useState(false);
  const [loading] = useState(false);
  const danceLogos = [
    { name: "Centrifuge", src: "/AllLogos/Centrifuge.webp", alt: "img 1" },
    { name: "Nrityakala", src: "/AllLogos/Nrityakala.webp", alt: "img 2" },
    { name: "Shake A Leg", src: "/AllLogos/ShakeALeg.webp", alt: "img 3" },
    { name: "Shuffle - Team ", src: "/AllLogos/Shuffle.webp", alt: "img 4" },
    { name: "Shuffle - Solo", src: "/AllLogos/Shuffle.webp", alt: "img 4" },
    { name: "Two For A Tango", src: "/AllLogos/TwoForATango.webp", alt: "img 5" },
    { name: "Beat IT", src: "/AllLogos/BeatIT.webp" },
    { name: "Can You Duet", src: "/AllLogos/can you duet.webp" },
    { name: "Rapmania", src: "/AllLogos/Rapmania.png" },
    { name: "SF Idol", src: "/AllLogos/SFidol.png" },
    { name: "Notes Less Travelled", src: "/AllLogos/NotesLessTravelled.png" },
    { name: "War of The DJs", src: "/AllLogos/WarofTheDJs.png" },
    { name: "Sargam", src: "/AllLogos/Sargam.png" },
    { name: "Retrowave", src: "/AllLogos/Retrowave.png" },
    { name: "Lakeside Dreams", src: "/AllLogos/LakesideDreamsGroup.png" },
    { name: "Wildfire", src: "/AllLogos/Wildfire.png" },
    { name: "I Me Myself", src: "/AllLogos/iMeMyself.png" },
    { name: "Nukkad", src: "/AllLogos/Nukkad.png" },
    { name: "Dumbstruck", src: "/AllLogos/Dumbstruck.png" },
    { name: "Rangmanch", src: "/AllLogos/Rangmanch.png" },
    { name: "Poetry Slam", src: "/AllLogos/EnglishPoetrySlam.png" },
    { name: "A Mighty Pen", src: "/AllLogos/AMightyPen.webp" },
    { name: "Tell a Tale", src: "/AllLogos/TellaTale.png" },
    { name: "Dumb C", src: "/AllLogos/DumbC.png" },
    { name: "Hindi Poetry Slam", src: "/AllLogos/HindiPoetrySlam.png" },
    { name: "India Calling", src: "/AllLogos/IndiaCalling.png" },
    { name: "Impromptu", src: "/AllLogos/Impromptu.png" },
    { name: "Jumble The Good Word", src: "/AllLogos/JumbleTheGoodWord.png" },
    { name: "Just A Minute", src: "/AllLogos/.png" },
    { name: "National Level Debate", src: "/AllLogos/NationalLevelDebate.png" },
    { name: "Motion Tales", src: "/AllLogos/MotionTales.png" },
    { name: "A Picture Tale", src: "/AllLogos/APictureTale.webp" },
    { name: "Lights Camera SF", src: "/AllLogos/LightsCameraSF.png" },
    { name: "SFM", src: "/AllLogos/SFM.png" },
    { name: "Mary Bucknel Trophy(MBT)", src: "/AllLogos/MaryBucknelTrophy(MBT).png" },
    { name: "SpEnt", src: "/AllLogos/SpEnt.png" },
    { name: "BizTech", src: "/AllLogos/BIZTECH.webp" },
    { name: "Cinemania", src: "/AllLogos/Cinemania.png" },
    { name: "Otakon Quest", src: "/AllLogos/otkaun quiz.webp" },
    { name: "Finger Dab", src: "/AllLogos/FingerDab.png" },
    { name: "Junk Art", src: "/AllLogos/JunkArt.png" },
    { name: "Frenzy Fabric", src: "/AllLogos/frenzy fabric.webp" },
    { name: "Paint It", src: "/AllLogos/PaintIt.png" },
    { name: "Rangoli", src: "/AllLogos/Rangoli.png" },
    { name: "Bran-D", src: "/AllLogos/Bran-D.webp" },
    { name: "Digital Illustration", src: "/AllLogos/DigitalIllustration.png" },
    { name: "Strokes on Streets", src: "/AllLogos/strokes on street.webp" },
    { name: "Face Canvas", src: "/AllLogos/FaceCanvas.png" },
    { name: "Sketch It", src: "/AllLogos/SketchIt.png" },
    { name: "Soapaholic", src: "/AllLogos/Soapaholic.png" },
    { name: "Mixology", src: "/AllLogos/Mixology.png" },
    { name: "Food Carving", src: "/AllLogos/.png" },
    { name: "Chef's Corner", src: "/AllLogos/Chef'sCorner.png" },
    { name: "Banter Bout", src: "/AllLogos/BanterBout.webp" },
    { name: "Hilarity Ensues", src: "/AllLogos/HilarityEnsues.png" },
    { name: "Navyata", src: "/AllLogos/Navyata.png" },
    { name: "Mr and Ms SPRING FEST", src: "/AllLogos/MrandMsSPRINGFEST.png" },
    { name: "Panache", src: "/AllLogos/Panache.png" },
    { name: "Stash N Show", src: "/AllLogos/StashNShow.png" },
    { name: "Peek A Who", src: "/AllLogos/PeekAWho.png" },
    { name: "Top It to Win It", src: "/AllLogos/TopIttoWinIt-1.png" }
  ];


  const matchingEvent = events?.find(
    (event) => event.genre === eventname
  );
  const eventsData = matchingEvent?.events.filter((status) => status.event_status === true);

  const navigateForward = () => {
    if (events) {
      const index = events?.findIndex(data => data.genre === eventname);
      if (index === 8) {
        navigate(`/events/subevents/${events[10]?.genre}`)
      }
      else if (index === 10) {
        navigate(`/events/subevents/${events[0]?.genre}`)
      }
      else {
        navigate(`/events/subevents/${events[index + 1]?.genre}`)
      }
    }
  }

  const navigateBackward = () => {
    if (events) {
      const index = events?.findIndex(data => data.genre === eventname);
      if (index === 0) {
        navigate(`/events/subevents/${events[10]?.genre}`)
      }
      else if (index === 10) {
        navigate(`/events/subevents/${events[8]?.genre}`)
      }
      else {
        navigate(`/events/subevents/${events[index - 1]?.genre}`)
      }

    }
  }

  // To fix min no of participants for different subevents
  useEffect(() => {
    setParticipants(subevent?.min_participation);
  }, [subevent]);

  //open modal function
  const handleModal = () => {
    if (isLoggedIn) {
      setRegistermodal(true);
      setSubeventmodal(false);
    } else {
      toast.error("Please Sign in to Register");
    }
  };

  // Dynamically creating no . of options
  const createOptions = () => {
    const options = [];
    for (
      let i = subevent?.min_participation;
      i <= subevent?.max_participation;
      i++
    ) {
      options.push(<option>{i}</option>);
    }
    return options;
  };

  // Dynamically increasing inputfields
  const createInputfields = () => {
    const inputFields = [];
    for (let i = 1; i < paricipants; i++) {
      inputFields.push(
        <div className="event-single-member">
          <div className="input-label">
            <label htmlFor={i + 1}>Member {i + 1} SF-Id </label>
            <input
              type="text"
              placeholder="SFXXXXX"
              id={i + 1}
              onChange={(e) => {
                handleInputChange(i - 1, "sfId", e.target.value);
              }}
            />
          </div>
          <div className="input-label">
            <label htmlFor={i + 1}>Member {i + 1} Mail-Id </label>
            <input
              type="text"
              placeholder="abc@gmail.com"
              id={i + 1}
              onChange={(e) => {
                handleInputChange(i - 1, "email", e.target.value);
              }}
            />
          </div>
        </div>
      );
    }
    return inputFields;
  };

  // To take input values
  const handleInputChange = (index, field, value) => {
    setFormData((prevDetails) => {
      const updatedDetails = [...prevDetails];
      if (!updatedDetails[index]) {
        updatedDetails[index] = { sfId: "", email: "" };
      }
      updatedDetails[index][field] = value;
      return updatedDetails;
    });
  };

  // submit function
  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.unshift({
      sfId: userData?.user.sfId,
      email: userData?.user.email,
    });
    const toSend = {
      token: userData.token,
      eventCity: "KGP",
      eventId: subevent?.id,
      teamMembers: [...formData],
    };
    console.log(formData);
    console.log(toSend);
    try {
      const response = await axios.post(
        "https://masterapi.springfest.in/api/event/register",
        toSend
      );
      if (response.data.code === 0) {
        toast.success(response.data.message);
        setRegistermodal(false);

      } else {
        toast.error(response.data.message);
        setRegistermodal(false);
        setParticipants(subevent?.min_participation)
      }
    } catch {
      toast.error("network issue");
    }
    setFormData([]);

  };

  const FindingLogo = (a) => {
    const logo = danceLogos.find(
      (image) => image.name === a
    );
    return logo ? logo.src : 'Wildfire.png';
  }

  ReactGA.initialize("G-23Q6ZTSW0Q");
  ReactGA.send({ hitType: "pageview", page: "/events/subevents/:eventname", title: "Register to take part in events Subheads" });

  return (
    <div className="subevents-body-container">
      <Toaster />
      <ReactModal
        isOpen={registermodal}
        onRequestClose={() => {
          setRegistermodal(false);
          setParticipants(subevent?.min_participation);
        }}
        className="Register_Modal__Content"
      >
        <button
          className="Register_Modal__CloseButton"
          onClick={() => {
            setRegistermodal(false);
            setParticipants(subevent?.min_participation);
          }}
        >
          &times;
        </button>
        <div className="event-register-form">
          <h1> {subevent?.name}</h1>
          <div>
            <label htmlFor="participants">Select No. of Members  </label>
            <select
              name="participants"
              id="participants"
              onChange={(e) => {
                setParticipants(e.target.value);
              }}
            >
              {createOptions()}
            </select>
          </div>
          <form onSubmit={(e) => handleSubmit(e)} className="register-event-center">
            <div className="event-single-member">
              <div className="input-label">
                <label htmlFor="sfID">Your SF-Id </label>
                <input
                  type="text"
                  id="sfID"
                  value={userData?.user?.sfId}
                  readOnly
                />
              </div>

              <div className="input-label">
                <label htmlFor="email">Your Mail-Id </label>
                <input
                  type="email"
                  id="email"
                  value={userData?.user?.email}
                  readOnly
                />
              </div>

            </div>
            <div>{createInputfields()}</div>
            <button type="submit">Register</button>
          </form>
        </div>
      </ReactModal>


      <div className="headings-3">

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={40} height={40} color={"#ffff"} fill={"none"} onClick={navigateBackward} style={{ cursor: "pointer" }}>
          <path d="M11.5 18C11.5 18 5.50001 13.5811 5.5 12C5.49999 10.4188 11.5 6 11.5 6" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M18.5 18C18.5 18 12.5 13.5811 12.5 12C12.5 10.4188 18.5 6 18.5 6" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

        <h1 className="eventheading glow-heading-event">{eventname}</h1>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={40} height={40} color={"#ffff"} fill={"none"} onClick={navigateForward} style={{ cursor: "pointer" }}>
          <path d="M12.5 18C12.5 18 18.5 13.5811 18.5 12C18.5 10.4188 12.5 6 12.5 6" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5.50005 18C5.50005 18 11.5 13.5811 11.5 12C11.5 10.4188 5.5 6 5.5 6" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
      {loading ? (<><br /><br /><br /><br /><br /><p className="glow-heading-event">Loading....</p><br /></>) : (<div className="sub-events-buttons">
        {eventsData?.map((event) => (
          <div
            key={event.name}
            onClick={() => {
              setSubeventmodal(true);
              setSubevent(event);
            }}
            className="sub-events-button"
          >
            <h2>{event.name}</h2>
            <div className="subevent-image"><img src={FindingLogo(event.name)} alt="logo-1" className="subevents-logo" /> </div>
            <button className="RegisterButton" onClick={(e) => {
              setSubeventmodal(true);
              e.stopPropagation();
              setSubevent(event);
            }
            }>
              Register
            </button>
          </div>
        ))}
      </div>)}
      {subevent && (
        <ReactModal
          isOpen={subeventmodal}
          onRequestClose={() => {
            setSubeventmodal(false);
          }}
          className="subevents-container">
          <button
            className="Register_Modal__CloseButton Modal-closebuttun"
            onClick={() => {
              setSubeventmodal(false);
            }}
          >
            &times;
          </button>
          <div className="subevents-heading-container">
            <div className="subevents-tharun">
              <h2>{subevent.name}</h2>
              <p>{subevent.tagline}</p>
            </div>
            <button className="RegisterButton-1" onClick={handleModal}>
              Register
            </button>
          </div>
          <div className="event-about-rules">
            <div className="event-about">
              <h3 className="about-rules">ABOUT</h3>
              <p>{subevent.writeup}</p>
            </div>
            <div className="event-rules">
              <h3 className="about-rules">RULES</h3>
              {subevent.rules.map((rule, index) => (
                <div>
                  <p key={index}>{rule}</p>
                  <br />
                </div>
              ))}
            </div>
          </div>
        </ReactModal>

      )}
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Henny+Penny&display=swap');
      </style>
    </div>
  );
};

export default SubEvents;