import info from "./Assets/INFO.webp"
import faq from "./Assets/FAQ.webp"
import map from "./Assets/Map.webp"
import rules from "./Assets/rulebook.webp"
import contingent from "./Assets/contingen.png"
import './Accommodation.css'
import { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"

export default function Navbar() {
  const location = useLocation();
      const [acroot, setAcroot]= useState("")
      const [accCls,setAccCls]= useState('offAccCls');
      const [accNav, setAccNav] = useState(false);
    
      function aCls() {
        if (accNav) {
          setAccCls("offAccCls");
          setAccNav(false);
          console.log("closed")
          setAcroot("")
        }
        else {
          setAccCls("newAccCls");
          setAccNav(true);
          setAcroot("actm")
          console.log(acroot);
        }
      }
    
      return (
          <nav className='acc-side'>
            {/* <img src={tree} className={`actree ${acroot}}`} /> */}
            <ul className={`acc-nav ${accCls}`}>
              {/* <li className="acc-ind"><div onClick={aCls}><img className="acimg2 acham" src={ham} /></div></li> */}
              <Link to="/contingent"><li className="acc-ind"><div className={location.pathname==="/contingent" ? "acrot": ""}></div><div className="acimgbox"><img className="acimg acimg2" src={contingent} /></div><div className="acnavname">Contingent</div></li></Link>
              <Link to="/accommodation"><li className="acc-ind"><div className={location.pathname==="/accommodation" ? "acrot": ""}></div><div className="acimgbox"><img className="acimg acimg2" src={info} /></div><div className="acnavname">Info</div></li></Link>
              <Link to="/accommodation-faq"><li className="acc-ind"><div className={location.pathname==="/accommodation-faq" ? "acrot": ""}></div><div className="acimgbox"><img className="acimg acimg2" src={faq} /></div><div className="acnavname">FaQs</div></li></Link>
              <Link to="/accommodation-rules"><li className="acc-ind"><div className={location.pathname==="/accommodation-rules" ? "acrot": ""}></div><div className="acimgbox"><img className="acimg acimg2" src={rules} /></div><div className="acnavname">Rules</div></li></Link>
              <Link to="/accommodation-map"><li className="acc-ind"><div className={location.pathname==="/accommodation-map" ? "acrot": ""}></div><div className="acimgbox"><img className="acimg acimg2" src={map} /></div><div className="acnavname aclong">Map</div></li></Link>
            </ul>
          </nav>
        
      )
}