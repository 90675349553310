import Navbar from "./AcNavbar"
import { useState, useEffect } from "react"
import VanillaTilt from "vanilla-tilt";
import "./acc-cards.css"
import AccCards from "./acccardlist";
// import board from "./NoticeBoard.jpg"

const Info=[
    {
        heading:"What will be included in this price?",
        content:"The provided price includes the following:\n1.Pronites passes for access to all major performances.\n2.Registration fee for participation in the event.\n3.Complimentary accommodation to ensure a comfortable stay during the festival."
    },
    {
        heading:"What is the payment procedure?",
        content:"To pay for registration and accommodation, go to springfest.in and log in or register if you haven't done so already. After logging in, click on the Accommodation button from the dashboard to access the accommodation section where you will find the payment option. Follow the prompts and fill in the details required to complete the payment. If you have any problems, refer to the support or contact information on the website. Complete both the registration step and the payment step to ensure you have arranged for accommodation so that things go smoothly at the event."
    },
    {
        heading:"How do I know I have paid for accommodation?",
        content:"Once you complete the registration and payment process through the portal, you will receive a confirmation email containing your transaction ID. Additionally, your payment status will be updated on the dashboard, allowing real-time visibility of your transaction. This seamless system ensures transparency and helps you track your payment easily. Keep an eye on your email for the transaction details and monitor your payment status on the dashboard for a smooth experience."
    },
    {
        heading:"What payment procedure is accepted?",
        content:"We offer flexible payment options to enhance your convenience. Choose from a variety of methods, including UPI, net banking, and debit/credit card payments. This ensures a seamless and secure transaction experience for our valued customers. Your preferred payment mode is just a click away, making the process quick and hassle-free. Join us in simplifying your payment journey with these diverse and accessible options. If the payment is coming from your Institute and it is not possible to pay online,please contact us for more knowledge about the same directly to the Spring Fest Team 2025. For details you can visit https://teams.springfest.in/"
    },
    {
        heading:"What are the documents that guests have to carry?",
        content:"1. Any valid Govt photo ID.\n2. Print out/screenshot of Email confirmation.\n3. Valid College ID for participants."
    },
    {
        heading:"Will the Acco tent be open 24x7?",
        content:"Yes, the Acco tent will be open 24x7, providing continuous support throughout the event. However, please note there will be a temporary interruption from 7 PM to 10 PM between January 24th and January 26th. We appreciate your understanding during this brief downtime."
    },
    {
        heading:"What kind of accommodation is provided?",
        content:"Accommodation will be provided on a shared basis inside the campus halls, with space for keeping luggage and other essential items.Girls and boys will be accommodated separately. The number of guests in a room will be decided by Spring Fest and will be allotted by the Spring fest team."
    },
    {
        heading:"Do the accommodation charges include food facilities too?",
        content:"Breakfast,Lunch and dinner is not included in the accommodation fee but can be purchased from the food court in the arena, night canteens and other stalls present on the campus."
    },
    {
        heading:"What are the food facilities inside the campus?",
        content:"The campus will have several food facilities to cater to your needs. The arena will feature a food outlet for convenient dining, while the SF Cafe provides a cozy spot for refreshments. For more options, the night canteen will offer a variety of food choices, ensuring there's something for everyone to enjoy."
    },
    {
        heading:"What about the hospital facility?",
        content:"In case anyone falls ill, they are advised to report at the accomodation desk so that appropriate arrangements can be made for them. A first aid kit shall also be present at the accomodation desk which can be availed by the guests."
    },
    {
        heading:"What about the security facilities during the fest in the campus?",
        content:"IIT Kharagpur campus has a vigilant and round-the-clock security service. To ensure the safety of the participants, there will be additional security guards in hostels in order to avoid thefts and other mishaps. There will be a Strong room for highly valuable products. Although the Spring Fest team will not take responsibility for any theft or other mishaps that happened at the accommodation. So guests are requested to take care of their luggage and valuable items."
    },
    {
        heading:"Where will I get my accommodation?",
        content:"You will be getting accommodation inside the campus in various halls depending upon availability."
    },
    {
        heading:"Can I enter the IIT-KGP campus anytime?",
        content:"You can enter IIT Main gate anytime by showing valid photo ID proof from 6 am to 10 pm, however, you need to have accommodation inside the campus to stay in campus after 10pm."
    },
    {
        heading:"We are a group of people not participating in any of the competitions or workshops and just coming to Spring Fest for enjoyment. Would we be provided accommodation?",
        content: "Yes, You just have to pay the Accommodation fees."
    },
    {
        heading:"Where do I have to report during the fest?",
        content: "You need to come to the Accommodation tent at the Spring Fest arena near the Computer science building."
    },
]
const Accfaq = () => {
    useEffect(() => {
        VanillaTilt.init(document.querySelectorAll(".tiltcard"),{
          max:5,
          speed:10,
          glare: false,
          'max-glare':0.5,
        })
      })
    return (
        <> 
                <div className="acc-bg"></div>

        <section className="acc-info">
            <Navbar />
            <div className="acctitdiv"><h1 className='acc-title acfaqtit'>FAQ's</h1></div>
            <AccCards info={Info}/>
        </section>
        </>
     );
}
 
export default Accfaq;