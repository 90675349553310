import React, { useEffect, useState } from "react";
import './Gallery.css';
import ReactGA from 'react-ga';

const images = [
  { id: 1, src: "https://i.postimg.cc/vmMnNK7H/DSC01430.webp", alt: "img 1" },
  { id: 2, src: "https://i.postimg.cc/LsTPKVvk/DSC01455.webp", alt: "img 1" },
  { id: 3, src: "https://i.postimg.cc/269B38sV/DSC01462-1.webp", alt: "img 1" },
  { id: 4, src: "https://i.postimg.cc/Rhj6QpVm/DSC01547.webp", alt: "img 1" },
  { id: 5, src: "https://i.postimg.cc/nzrX8fXZ/DSC01572.webp", alt: "img 1" },
  { id: 6, src: "https://i.postimg.cc/3rPFZnqW/DSC01603.webp", alt: "img 1" },
  { id: 7, src: "https://i.postimg.cc/KYvPYV1p/IMG-20250116-WA0002.webp", alt: "img 1" },
  { id: 8, src: "https://i.postimg.cc/RFWw7HQc/IMG-20250116-WA0003.webp", alt: "img 1" },
  { id: 9, src: "https://i.postimg.cc/NFTTVvnF/IMG-20250116-WA0004.webp", alt: "img 1" },
  { id: 10, src: "https://i.postimg.cc/CLTb8dZq/IMG-20250116-WA0005.webp", alt: "img 1" },
  { id: 11, src: "https://i.postimg.cc/mDh6Cgg0/Whats-App-Image-2025-01-16-at-01-40-28-289d56a0.webp", alt: "img 1" },
  { id: 12, src: "https://i.postimg.cc/c1wBjn2F/Whats-App-Image-2025-01-16-at-01-40-31-ff2285fd.webp", alt: "img 1" },
  { id: 13, src: "https://i.postimg.cc/nr89qwLJ/IMG-20250116-WA0008.webp", alt: "img 1" },
  { id: 14, src: "https://i.postimg.cc/J4KZVcq5/Whats-App-Image-2025-01-16-at-01-40-33-ac3795c1.webp", alt: "img 1" },
  { id: 15, src: "https://i.postimg.cc/t4RZd69Y/IMG-20250116-WA0010.webp", alt: "img 1" },
  { id: 16, src: "https://i.postimg.cc/jSGPTXqL/1.webp", alt: "img 1" },
  { id: 17, src: "https://i.postimg.cc/rpZmcyNg/2.webp", alt: "img 2" },
  { id: 18, src: "https://i.postimg.cc/xCHS79xX/3.webp", alt: "img 3" },
  { id: 19, src: "https://i.postimg.cc/zvmWWJXj/4.webp", alt: "img 4" },
  { id: 20, src: "https://i.postimg.cc/FsVcdXty/5.webp", alt: "img 5" },
  { id: 21, src: "https://i.postimg.cc/g0n6Twxs/6.webp", alt: "img 6" },
  { id: 22, src: "https://i.postimg.cc/5tTFdxvV/7.webp", alt: "img 7" },
  { id: 23, src: "https://i.postimg.cc/9M9wcvtq/8.webp", alt: "img 8" },
  { id: 24, src: "https://i.postimg.cc/4yT7yBB2/9.webp", alt: "img 9" },
  { id: 25, src: "https://i.postimg.cc/fyj39mPz/10.webp", alt: "img 10" },
  { id: 26, src: "https://i.postimg.cc/SQWY3bxB/11.webp", alt: "img 11" },
  { id: 27, src: "https://i.postimg.cc/J4MHWYyG/12.webp", alt: "img 12" },
  { id: 28, src: "https://i.postimg.cc/6pQ787fK/13.webp", alt: "img 13" },
  { id: 29, src: "https://i.postimg.cc/sg0M7VTh/14.webp", alt: "img 14" },
  { id: 30, src: "https://i.postimg.cc/tgK7vBQR/15.webp", alt: "img 15" },
  { id: 31, src: "https://i.postimg.cc/qR2qP24s/16.webp", alt: "img 16" },
  { id: 32, src: "https://i.postimg.cc/qq0q4gv9/17.webp", alt: "img 17" },
  { id: 33, src: "https://i.postimg.cc/59qjp5P1/18.webp", alt: "img 18" },
  { id: 34, src: "https://i.postimg.cc/Hs6n1YDh/19.webp", alt: "img 19" },
  { id: 35, src: "https://i.postimg.cc/KvVDcLm3/20.webp", alt: "img 20" },
  { id: 36, src: "https://i.postimg.cc/pLZgyRLF/21.webp", alt: "img 21" },
  { id: 37, src: "https://i.postimg.cc/FKYDm4dm/22.webp", alt: "img 22" },
  { id: 38, src: "https://i.postimg.cc/yY7jx9gL/23.webp", alt: "img 23" },
  { id: 39, src: "https://i.postimg.cc/mDRzj3Gq/24.webp", alt: "img 24" },
  { id: 40, src: "https://i.postimg.cc/x8X03qqh/25.webp", alt: "img 25" },
  { id: 41, src: "https://i.postimg.cc/9Q96tbpY/26.webp", alt: "img 26" },
  { id: 42, src: "https://i.postimg.cc/nLt6dmSb/27.webp", alt: "img 27" },
  { id: 43, src: "https://i.postimg.cc/5tqDDtDH/28.webp", alt: "img 28" },
  { id: 44, src: "https://i.postimg.cc/rsbH5RRH/29.webp", alt: "img 29" },
  { id: 45, src: "https://i.postimg.cc/28bMrGPN/30.webp", alt: "img 30" },
];

const Gallery = () => {
  
  ReactGA.initialize("G-23Q6ZTSW0Q");
  ReactGA.send({ hitType: "pageview", page: "/gallery", title: "SF-2024 Memories Subheads" });

  const [imageList, setImageList] = useState(images);
  const [isPaused, setIsPaused] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomedIndex, setZoomedIndex] = useState(null);

  const nextSlide = () => {
    setIsPaused(true); 
    setImageList((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.push(updatedImages.shift());
      return updatedImages;
    });

    
    setTimeout(() => setIsPaused(false), 500);
  };

  const prevSlide = () => {
    setIsPaused(true); 
    setImageList((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.unshift(updatedImages.pop());
      return updatedImages;
    });

    setTimeout(() => setIsPaused(false), 500);
  };

  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(nextSlide, 2000);
      return () => clearInterval(interval);
    }
  }, [isPaused]);

  const handleImageClick = (index) => {
    if (isZoomed && zoomedIndex === index) {
      setIsZoomed(false);
      setIsPaused(false);
      setZoomedIndex(null);
    } else {
      setIsPaused(true);
      setIsZoomed(true);
      setZoomedIndex(index);
    }
  };

  return (
    <div className="gallery-container">
      <div className="gallery-heading">GALLERY</div>
      <div className="gallery-slide">
        {imageList.map((image, index) => (
          <div
            className={`gallery-items ${isZoomed && zoomedIndex === index ? "zoomed" : ""}`}
            key={image.id}
            style={{ backgroundImage: `url(${image.src})` }}
            onClick={() => handleImageClick(index)}
          ></div>
        ))}
      </div>
      <button className="gallery-prev-button" onClick={prevSlide}>
        <img
          src="https://www.reshot.com/preview-assets/icons/4NA6KJF8CU/arrow-left-4NA6KJF8CU.svg"
          alt="left-arrow"
        />
      </button>
      <button className="gallery-next-button" onClick={nextSlide}>
        <img
          src="https://www.reshot.com/preview-assets/icons/ZP2WDL9B8N/arrow-right-ZP2WDL9B8N.svg"
          alt="right-arrow"
        />
      </button>
    </div>
  );
};

export default Gallery;
