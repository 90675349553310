import React from "react";
import { Outlet } from "react-router-dom";
import LapNavbar from "./LaptopViewNavbar/LapNavbar";
import PhoneNav from "./PhoneViewNavbar/PhoneNav";
import ReactGA from 'react-ga';

const Navbar = ({ is600, preloader }) => {
  ReactGA.initialize("G-23Q6ZTSW0Q");
  ReactGA.send({ hitType: "pageview", page: "*", title: "Best part of the website Subheads" });

  return (
    <>
      {!preloader && <div className="">
        {is600 ? <LapNavbar /> : <PhoneNav />}
      </div>}
      <Outlet />
    </>
  );
};

export default Navbar;